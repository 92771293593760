import _ from 'lodash'
import isAscii from './is-ascii'

export default function getCharacterCounts(
  val = '',
  { asciiCharLength = 1, nonAsciiCharLength = 2 } = {},
) {
  // calculate total characters: ascii = 1, non-ascii = 2, return = 0
  const charsArr = (val || '').split('').map((c) => {
    if (c.charCodeAt(0) === 10) return 0
    return isAscii(c) ? asciiCharLength : nonAsciiCharLength
  })

  return {
    numberOfChars: _.sum(charsArr), // calculate mixed characters
    numberOfLines: charsArr.filter((n) => n === 0).length, // calculate number of lines
    numberOfNonAsciiChars: charsArr.filter(
      (n) => n === nonAsciiCharLength,
    ).length, // calculate non-ascii characters
    numberOfAsciiChars: charsArr.filter(
      (n) => n === asciiCharLength,
    ).length, // calculate ascii characters
  }
}
