/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'
import React from 'react'
import QRCode from 'qrcode.react'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import {
  useUser,
} from 'react-omnitech-api'
import Link from '../link'
import AvatarImage from '../avatar-image'
import combineClassNames from '../../helpers/combineClassNames'
import parseLoyaltyPoints from '../../helpers/parse-loyalty-points'
import useStyles from './profile-card-style'

const ProfileCardView = ({
  onEditAvatar,
  uploadAvatarInProgress,
  avatarPreview,
  handleOpenMemberCode,
  hideLoyaltyPoints,
}) => {
  const { t } = useTranslation()
  const {
    user,
    loyaltyInformation,
  } = useUser()
  const {
    name,
    alias,
    avatarImage,
    qrCodeValue,
  } = user
  const {
    currentCustomerRank,
    currentCustomerRankExpiresAt,
    totalLoyaltyPoints,
  } = loyaltyInformation

  const styles = useStyles({
    rankHexValue: _.get(currentCustomerRank, 'hexValue'),
    rankBgHexValue: _.get(currentCustomerRank, 'bgHexValue'),
  })
  const theme = useTheme()
  const thumbnail = _.get(avatarImage, 'thumbnail', undefined)
  const large = _.get(avatarImage, 'large', undefined)
  const avatar = avatarPreview || thumbnail || large

  return (
    <div className={styles.container}>
      <div
        className={styles.avatarContainer}
      >
        <div
          className={
            combineClassNames([styles.avatar, uploadAvatarInProgress && styles.avatarUploading])
          }
        >
          <label htmlFor="file">
            <AvatarImage
              src={avatar}
              alt={alias}
            />
            <input
              id="file"
              name="file"
              type="file"
              accept="image/*"
              onChange={onEditAvatar}
              style={{ visibility: 'hidden', position: 'absolute' }}
              disabled={uploadAvatarInProgress}
            />
          </label>
        </div>
      </div>
      <div className={styles.info}>
        <p className={styles.name}>
          {name}
        </p>
        <p className={styles.alias}>{`@${alias}`}</p>
        <p>
          {
            !_.isEmpty(currentCustomerRank) && (
              <span className={styles.rank}>{_.get(currentCustomerRank, 'name')}</span>
            )
          }
        </p>
        {
          moment(currentCustomerRankExpiresAt || '/////').isValid()
          && (
            <p className={styles.rankExpiryDate}>
              {t('screens.account.currentCustomerRankExpiryNote', { expiryDate: moment(currentCustomerRankExpiresAt).format('LL') })}
            </p>
          )
        }
        {
          !hideLoyaltyPoints
          && totalLoyaltyPoints
          && (
            <p className={styles.points}>
              <Link
                className={styles.pointsLink}
                gatsbyLink
                to="/account/my-coupons/"
              >
                {t('screens.account.pointsProfile', { points: parseLoyaltyPoints(totalLoyaltyPoints) })}
              </Link>
            </p>
          )
        }
      </div>
      {
        !_.isEmpty(qrCodeValue) && (
        <div className={styles.qrContainer}>
          <span onClick={handleOpenMemberCode}>
            <QRCode
              value={qrCodeValue}
              size={34}
              fgColor={_.get(theme, 'colors.secondary')}
            />
          </span>
        </div>

        )
      }
    </div>
  )
}

export default ProfileCardView
