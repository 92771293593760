/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  isAndroid,
  isSafari,
  isChrome,
  isIOS,
} from 'react-device-detect';
import { useCouponMarketplace, useThemeConfig } from '../../../../hook'
import useStyles from './coupon-marketplace-detail-style'
import combineClassNames from '../../../../helpers/combineClassNames'
import CouponMarketplaceHeader from '../coupon-marketplace-header'
import CouponMarketplaceCouponInfo from '../coupon-marketplace-coupon-info'
import Button from '../../../button'
import Spinner from '../../../spinner'

const CouponMarketplaceCouponDetailView = ({
  coupon,
  isCouponRedeemed,
  isDetailOpen,
  isDetailReady,
  isLoading,
  userCurrentPoints,
  onCouponDetailClose,
  onRedeemCoupon,
  onTransferIn,
}) => {
  // hook
  const { t } = useTranslation()
  const { onCouponMarketplaceClose, detailType } = useCouponMarketplace()
  const { getConfig } = useThemeConfig()
  const hideLoyaltyPoints = getConfig('config.hideLoyaltyPoints', false)

  // local variable
  const { terms, name, redeemableAvailableForUserRedeem } = coupon || {}
  const isSubmitDisabled = isLoading || isCouponRedeemed || !redeemableAvailableForUserRedeem
  const buttonText = isCouponRedeemed
    ? t('ui.minicartCoupons.detail.buttons.redeemed')
    : t('ui.minicartCoupons.detail.buttons.redeemCoupon')

  const handleSubmit = () => {
    onRedeemCoupon(coupon)
  }

  const styles = useStyles({
    isSubmitDisabled, isAndroid, isSafari, isChrome, isIOS,
  })

  return (
    <div className={
      combineClassNames([styles.containerDetail,
        isDetailOpen && styles.containerDetailOpen])
      }
    >
      <CouponMarketplaceHeader
        title={name}
        withBackButton
        onBack={onCouponDetailClose}
        onCloseDetail={onCouponMarketplaceClose}
      />
      {
        isDetailReady
          ? (
            <div className={styles.bodyDetail}>
              {
                detailType !== 'couponTransferIn'
                && !hideLoyaltyPoints
                && (
                  <div className={styles.messsages}>
                    <p>
                      <Trans i18nKey="ui.minicartCoupons.coupons.points">
                        My Points
                        <span>{{ points: userCurrentPoints }}</span>
                      </Trans>
                    </p>
                  </div>
                )
              }
              <div className={styles.content}>
                <CouponMarketplaceCouponInfo
                  coupon={coupon}
                  isRedeem={detailType !== 'couponTransferIn'}
                  transferState={detailType === 'couponTransferIn' ? 'transfer_in' : undefined}
                  startAt={coupon.startAt}
                  endAt={coupon.endAt}
                  hideValidPeriodDates={detailType === 'couponTransferIn'}
                  onTransferIn={onTransferIn}
                />
                {/* Coupon Terms */}
                <div
                  className={styles.termsStyle}
                  dangerouslySetInnerHTML={{ __html: terms }}
                />
              </div>
            </div>
          )
          : (
            <Spinner />
          )
      }
      {
        isDetailReady
        && detailType !== 'couponTransferIn'
        && (
          <div className={styles.footerDetail}>
            <Button
              dark
              className={styles.buttonDetail}
              disabled={isSubmitDisabled}
              text={buttonText}
              onClick={handleSubmit}
            />
          </div>
        )
      }
    </div>
  )
}

export default CouponMarketplaceCouponDetailView
