/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-catch */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import flow from 'lodash/fp/flow'
import first from 'lodash/fp/first'
import get from 'lodash/fp/get'
import { Formik } from 'formik'
import {
  cancelRequest,
  useAddresses,
  useDeliveryAddresses,
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import {
  getAddressFormInitialValues,
  isNotNullOrUndefined,
  isSelectable,
  transformAddressFormValidationSchema,
} from '../../helpers'
import {
  useGoogleMaps,
} from '../../hook'
import AddressFormForm from './address-form-form'
import AddressFormView from './address-form-view'

// default form options
const initFormOptions = {
  countryOptions: [],
  stateOptions: [],
  cityOptions: [],
  districtOptions: [],
  streetOptions: [],
}

function AddressFormController(props) {
  const {
    addressType,
    disableCancel,
    forceDefaultDelivery,
    isEdit,
    onCancel,
    onSubmitSuccess,
    selectedAddress,
  } = props

  // Ref
  const formRef = useRef(null)
  const mapTimer = useRef(null)

  // prepare hook
  const { user } = useUser()
  const { getSystemSetting } = useSystemSettings()
  const fieldsMaxLength = getSystemSetting('address.max_length', '')
  const availableCountryCodes = getSystemSetting('address.delivery_address.available_country_codes', '')
  const preferredCountryCodes = getSystemSetting('country.preferred_country_codes')
  const latLongAreMandatory = getSystemSetting('address.delivery_address.lat_long_are_mandatory', false)
  const addressFieldKeys = getSystemSetting('address_format', 'street_line_1 street_line_2 district city state zip country').split(' ')
  const {
    fetchCountries,
    fetchStatesCitiesDistricts,
    fetchStreet,
    fetchZip,
  } = useAddresses()
  const { createDeliveryAddresses, updateDeliveryAddresses } = useDeliveryAddresses()
  const GoogleMapsService = useGoogleMaps()

  // internal state
  const [formConfig, setFormConfig] = useState({})
  const [formOptions, setFormOptions] = useState(initFormOptions)
  const [isLoading, setIsLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const [isMapProccessing, setIsMapProccessing] = useState(false)
  const [isMapTouched, setIsMapTouched] = useState(false)
  const [countries, setCountries] = useState([])

  // useMemo
  const defaultCountryCode = useMemo(() => {
    const countriesCodes = _.split(preferredCountryCodes, ' ')
    const firstCountryCode = _.head(countriesCodes)
    return firstCountryCode || ''
  }, [preferredCountryCodes])
  const defaultDeliveryCountry = useMemo(() => (
    _.find(countries, { alpha2: defaultCountryCode }) || _.first(countries)
  ),
  [defaultCountryCode, countries])
  const formInitialValues = useMemo(() => (
    getAddressFormInitialValues({
      isEdit,
      defaultCountryCode,
      address: selectedAddress,
      user,
      formConfig,
    })
  ), [isEdit, defaultCountryCode, selectedAddress, user, formConfig])
  const formValidationSchema = useMemo(() => (
    transformAddressFormValidationSchema({
      ...formConfig,
      fieldsMaxLength,
    })
  ), [formConfig, fieldsMaxLength])

  const isPrimaryEditable = !(forceDefaultDelivery || formInitialValues.isPrimary)

  async function handleFetchStatesCitiesDistricts(country) {
    try {
      const { states } = await fetchStatesCitiesDistricts({ country })
      return states
    } catch (error) {
      throw new Error(error)
    }
  }

  async function handleFetchStreet({
    country,
    state,
    city,
    district,
  }) {
    try {
      const { streetLine1 } = await fetchStreet({
        country,
        state,
        city,
        district,
      })
      return streetLine1
    } catch (error) {
      throw new Error(error)
    }
  }

  function getCountry(_countries, countryCode) {
    return _.find(_countries, { alpha2: countryCode })
  }

  function getCities(states, stateCode) {
    const stateObj = _.find(states, (state) => {
      const optionKey = _.head(_.keys(state))
      return optionKey === stateCode
    })
    const cities = _.get(stateObj[stateCode], 'cities', [])
    return cities
  }

  function getDistricts(cities, cityCode) {
    const key = cityCode || 'none'
    const cityObj = _.find(cities, (city) => {
      const optionKey = _.head(_.keys(city))
      return optionKey === key
    })
    const districts = _.get(cityObj[key], 'districts', [])
    return districts
  }

  // Handle Map actions
  const mapSearch = useCallback((params) => {
    GoogleMapsService.cleanup()
    setIsMapProccessing(true)
    const countryCode = _.get(formInitialValues, 'country', defaultCountryCode)
    return GoogleMapsService.search({
      params: {
        ...params,
        region: countryCode,
      },
      cancelKey: 'mapSearch',
    })
      .then(({ results, status }) => {
        if (!_.isEmpty(results) && status === 'OK') {
          return GoogleMapsService.getPreferedAddressFromSearch({
            results,
            countryCode,
            defaultDeliveryCountry,
          })
        }
      })
      .finally(() => {
        setIsMapProccessing(false)
      })
  }, [defaultDeliveryCountry, formInitialValues])

  const handleMapDragStart = () => {
    setIsMapTouched(true)
  }
  const handleMapPlaceSelect = () => {
    setIsMapTouched(true)
  }

  const handleMapChanged = ({ lat, lng, zoom = 18 } = {}) => {
    if (isEdit && !isMapTouched) return
    clearTimeout(mapTimer.current)
    mapTimer.current = setTimeout(() => {
      // eslint-disable-next-line semi-style
      // eslint-disable-next-line no-extra-semi
      ;(async () => {
        try {
          const { preferedAddressFields } = await mapSearch({
            latlng: `${lat},${lng}`,
          })
          _.each([
            // FL: reverse the result of mapSearch to prevent district field reset
            //     by state/city field update
            ..._.reverse(preferedAddressFields),
            {
              name: 'lat',
              value: _.toString(lat),
            },
            {
              name: 'long',
              value: _.toString(lng),
            },
          ], handleFieldChange)
          // End Drag proccess
        } catch (error) {
          // do nothing
        }
      })()
    }, 1000);
  }

  // depend on field change, update form options
  async function handleAddressFormFieldChange({ fieldName, formValue }) {
    const currentCountry = getCountry(formOptions.countryOptions, _.get(formRef.current, 'values.country', defaultCountryCode))
    try {
      switch (fieldName) {
        case 'country': {
          const newFormConfig = getCountry(formOptions.countryOptions, formValue)
          setFormConfig(newFormConfig)
          await resetFormOptions(['state', 'city', 'district', 'street'])
          if (isSelectable(newFormConfig.selectableFields, 'state')) {
            const stateOptions = await handleFetchStatesCitiesDistricts(formValue)
            await updateFormOption({ stateOptions })
          }
          break
        }
        case 'state': {
          await resetFormOptions(['city', 'district', 'street'])
          const cityOptions = getCities(formOptions.stateOptions, formValue)
          await updateFormOption({ cityOptions })
          break
        }
        case 'city': {
          await resetFormOptions(['district', 'street'])
          const districtOptions = getDistricts(formOptions.cityOptions, formValue)
          await updateFormOption({ districtOptions })
          break
        }
        case 'district': {
          await resetFormOptions(['street'])
          if (isSelectable(formConfig.selectableFields, 'streetLine1')) {
            const streetOptions = await handleFetchStreet({
              district: formValue,
              ..._.pick(formRef.current.values, ['country', 'state', 'city']),
            })
            await updateFormOption({ streetOptions })
          }
          break
        }
        default:
          break
      }
    } catch (error) {
      console.warn('handleAddressFormFieldChange error: ', error)
    }
  }

  // for field with `none` type, need to determine there is options for the form as special handling
  function hasAvailableOptions(options, key) {
    const optionObj = _.head(_.values(_.head(options)))
    const availableOptions = _.get(optionObj, key, [])
    return !_.isEmpty(availableOptions)
  }

  // when edit address, need to set form config and form options base on the selected address
  async function handleSelectAddress(address) {
    const {
      countryCode: country,
      state,
      city,
      district,
    } = address
    // update form config
    const newFormConfig = getCountry(formOptions.countryOptions, country)
    setFormConfig(newFormConfig)

    // prepare form options base on form config,
    // check city or district is selectable but the field is `none` type,
    // which need special handling
    const { selectableFields } = newFormConfig
    const newFormOption = {
      stateOptions: [],
      cityOptions: [],
      districtOptions: [],
      streetOptions: [],
    }

    if (!_.isUndefined(country) && isSelectable(selectableFields, 'state')) {
      newFormOption.stateOptions = await handleFetchStatesCitiesDistricts(country)
    }

    if (
      !_.isUndefined(state)
      && (
        isSelectable(selectableFields, 'city')
        || hasAvailableOptions(newFormOption.stateOptions, 'cities')
      )
    ) {
      newFormOption.cityOptions = getCities(newFormOption.stateOptions, state)
    }

    if (
      !_.isUndefined(city)
      && (
        isSelectable(selectableFields, 'district')
        || hasAvailableOptions(newFormOption.cityOptions, 'district')
      )
    ) {
      newFormOption.districtOptions = getDistricts(newFormOption.cityOptions, city)
    }

    if (!_.isUndefined(district) && isSelectable(selectableFields, 'streetLine1')) {
      newFormOption.streetOptions = await handleFetchStreet({
        country,
        state,
        city,
        district,
      })
    }
    updateFormOption({ ...newFormOption })
  }

  // call the onSubmit which passing as props and handle error display
  const handleSubmit = async (values, actions) => {
    try {
      setIsLoading(true)
      await handleSubmitDeliveryAddress(values)
      onSubmitSuccess()
    } catch (error) {
      const validationError = _.get(error, 'validationError', {})
      if (!_.isEmpty(validationError)) {
        const deliveryAddressErrors = _.get(validationError, 'data.deliveryAddress.errors', {})
        const formattedError = _.keys(deliveryAddressErrors).reduce((result, key) => ({
          ...result,
          [key]: _.get(deliveryAddressErrors[key], 'message', []).join(', '),
        }), {})
        actions.setErrors(formattedError)
      }
    } finally {
      actions.setSubmitting(false)
      setIsLoading(false)
    }
  }

  // depend on is edit or not, process create or update address
  function handleSubmitDeliveryAddress(values) {
    const options = {}
    const addressValues = {
      ...values,
      city: values.city === 'none' ? '' : values.city,
    }
    return isEdit
      ? updateDeliveryAddresses({
        address: {
          id: selectedAddress.id,
          ...addressValues,
        },
        ...options,
      })
      : createDeliveryAddresses({
        address: {
          ...addressValues,
          isPrimary: isPrimaryEditable
            ? addressValues.isPrimary
            : forceDefaultDelivery || addressValues.isPrimary,
        },
        ...options,
      })
  }

  // reset specific field if they are selectable field
  async function resetFormOptions(formFieldKeys) {
    const resetFields = _.reduce(
      formFieldKeys,
      (result, key) => (
        {
          ...result,
          [`${key}Options`]: [],
        }
      ),
      {},
    )
    await setFormOptions((prevFormOptions) => ({
      ...prevFormOptions,
      ...resetFields,
    }))
  }

  async function updateFormOption(newFormOptions) {
    await setFormOptions((prevFormOptions) => ({
      ...prevFormOptions,
      ...newFormOptions,
    }))
  }

  async function handleFetchCountries() {
    try {
      const { countries: countryOptions } = await fetchCountries({
        params: {
          iso_3166_eq: availableCountryCodes,
          pageSize: 999,
        },
        arrayFormat: 'brackets',
      })
      setCountries(countryOptions)
      await updateFormOption({ countryOptions })
    } catch (error) {
      // TODO: handle error
      console.warn('fetchCountries when page load error: ', error)
    }
  }

  async function handleFieldChange({ name, value }) {
    if (_.isNull(formRef)) return
    // skip when value is not changed
    if (formRef.current.values[name] === value) return
    // Set field to touched but do not trigger validation
    if (latLongAreMandatory) {
      await formRef.current.setFieldValue(name, value)
      formRef.current.setFieldTouched(name, true)
    } else {
      formRef.current.setFieldValue(name, value, true)
    }
    handleAddressFieldChange({
      fieldName: name,
      formValue: value,
    })
    if (latLongAreMandatory) {
      formRef.current.setFieldValue(name, value)
    }
  }

  async function initAddressForm() {
    await handleFetchCountries()
    setReady(true)
  }

  // fetch countries when component is mounted
  useEffect(() => {
    initAddressForm()
  }, [])

  // set form config and options when is open
  useEffect(() => {
    // if countries is not ready yet, skip the logic
    if (_.isEmpty(formOptions.countryOptions)) return

    if (isEdit) {
      handleSelectAddress(selectedAddress)
    } else {
      // set form config with default country for create new address
      const newFormConfig = getCountry(formOptions.countryOptions, defaultCountryCode)
      if (_.isEmpty(newFormConfig)) return
      setFormConfig(newFormConfig)
      // when country is selected, get related options
      handleSelectAddress({
        countryCode: formInitialValues.country,
      })
    }
  }, [formOptions.countryOptions])

  function handleAddressFieldChange({ fieldName, formValue }) {
    // trigger address field change in controller
    handleAddressFormFieldChange({
      fieldName,
      formValue,
    })

    // reset form value depend on field change
    const fieldKeys = ['country', 'state', 'city', 'district']
    let resetFromKeyIndex = 0
    switch (fieldName) {
      case 'country':
        resetFromKeyIndex = 1
        break
      case 'state':
        resetFromKeyIndex = 2
        break
      case 'city':
        resetFromKeyIndex = 3
        break
      case 'district':
        resetFromKeyIndex = 4
        break
      default:
        break
    }
    if (resetFromKeyIndex > 0) {
      resetValues(fieldKeys.splice(resetFromKeyIndex))
    }

    const formValues = {
      ..._.get(formRef.current, 'values', {}),
      [fieldName]: formValue,
    }
    if (_.includes(['state', 'city', 'country'], fieldName)) {
      autoFillZip(formValues)
    }
  }

  async function autoFillZip(values = {}) {
    const {
      state,
      city,
      country: countryCode,
    } = values
    // auto fill zip
    if (
      isNotNullOrUndefined(state) && !_.isEmpty(state)
      && isNotNullOrUndefined(city) && !_.isEmpty(city)
      && isNotNullOrUndefined(countryCode) && !_.isEmpty(countryCode)
      && _.has(values, 'zip')
    ) {
      // cancel previous api call
      cancelRequest.cancelAll([
        'fetchZip',
      ])
      // get zip from api
      try {
        const data = await fetchZip({
          state,
          city,
          countryCode,
        })
        const zip = _.toString(_.get(data, 'zip.zip'))
        if (_.isEmpty(zip)) return
        formRef.current.setFieldValue('zip', zip)
      } catch (error) {
        // do nothing
      }
    }
  }

  function preSelectSingleOption(fieldName, options) {
    if (_.size(options) === 1) {
      const option = fieldName === 'country'
        ? flow(
          first,
          get('alpha2'),
        )(options)
        : _.first(_.keys(_.first(options)))
      if (formRef.current) formRef.current.setFieldValue(fieldName, option)
      handleAddressFormFieldChange({
        fieldName,
        formValue: option,
      })
    }
  }

  // reset value
  function resetValues(fieldKeys = []) {
    fieldKeys.forEach((key) => {
      formRef.current.setFieldValue(key, '')
    })
  }

  function transformOptions(fieldName, options) {
    let transformedOptions = options
    switch (fieldName) {
      case 'countryCallingCode':
        transformedOptions = _.map(options, (option) => ({
          label: option.callingCode,
          value: option.callingCode,
        }))
        break
      case 'country':
        transformedOptions = _.map(options, (option) => ({
          label: option.name,
          value: option.alpha2,
        }))
        break
      case 'state':
      case 'city':
      case 'district':
      case 'street':
        transformedOptions = _.map(options, (option) => {
          const optionKey = _.head(_.keys(option))
          const optionObj = _.head(_.values(option))
          return {
            label: optionObj.name,
            value: optionKey,
          }
        })
        break
      default:
        break
    }
    return transformedOptions
  }

  // // if a fields only has single option, pre-select the option
  useEffect(() => {
    preSelectSingleOption('country', formOptions.countryOptions)
  }, [formOptions.countryOptions])
  useEffect(() => {
    preSelectSingleOption('state', formOptions.stateOptions)
  }, [formOptions.stateOptions])
  useEffect(() => {
    preSelectSingleOption('city', formOptions.cityOptions)
  }, [formOptions.cityOptions])
  useEffect(() => {
    preSelectSingleOption('district', formOptions.districtOptions)
  }, [formOptions.districtOptions])
  useEffect(() => {
    preSelectSingleOption('streetLine1', formOptions.streetOptions)
  }, [formOptions.streetOptions])

  // // transform options to a suitable form for select element
  // const countryOptions = transformOptions('country', formOptions.countries)
  const countryCallingCodeOptions = useMemo(() => transformOptions('countryCallingCode', formOptions.countryOptions), [formOptions.countryOptions])
  const countryOptions = useMemo(() => transformOptions('country', formOptions.countryOptions), [formOptions.countryOptions])
  const stateOptions = useMemo(() => transformOptions('state', formOptions.stateOptions), [formOptions.stateOptions])
  const cityOptions = useMemo(() => transformOptions('city', formOptions.cityOptions), [formOptions.cityOptions])
  const districtOptions = useMemo(() => transformOptions('district', formOptions.districtOptions), [formOptions.districtOptions])
  const streetOptions = useMemo(() => transformOptions('street', formOptions.streetOptions), [formOptions.streetOptions])

  const viewProps = {
    ready,
  }

  const formPorps = {
    addressFieldKeys,
    addressType,
    cityOptions,
    countryCallingCodeOptions,
    countryOptions,
    defaultDeliveryCountry,
    disableCancel,
    districtOptions,
    isPrimaryEditable,
    isLoading,
    formConfig,
    formOptions,
    isEdit,
    latLongAreMandatory,
    stateOptions,
    streetOptions,
    onFieldChange: handleFieldChange,
    // onMapCenterChanged,
    onMapDragStart: handleMapDragStart,
    onMapDragEnd: handleMapChanged,
    onMapPlaceSelect: handleMapPlaceSelect,
    onMapUserCenterChanged: handleMapChanged,
    onMapZoomChanged: handleMapChanged,
    onCancel,
  }

  return (
    <AddressFormView {...viewProps}>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formInitialValues}
        validateOnChange={false}
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        <AddressFormForm {...formPorps} />
      </Formik>
    </AddressFormView>
  )
}

export default AddressFormController
