import _ from 'lodash'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    'body.ReactModal__Body--open': {
      overflow: 'hidden',
      height: '100vh',
      width: '100%',
      position: 'fixed',
    },
  },
  overlay: ({ orderMethodBackgroundUrls }) => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${_.get(orderMethodBackgroundUrls, 'mobile', '')}")`,
    ..._.has(orderMethodBackgroundUrls, 'tablet') && {
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        backgroundImage: `url("${orderMethodBackgroundUrls.tablet}")`,
      },
    },
    ..._.has(orderMethodBackgroundUrls, 'tabletLandscape') && {
      [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
        backgroundImage: `url("${orderMethodBackgroundUrls.tabletLandscape}")`,
      },
    },
    ..._.has(orderMethodBackgroundUrls, 'desktop') && {
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        backgroundImage: `url("${orderMethodBackgroundUrls.desktop}")`,
      },
    },
    ..._.has(orderMethodBackgroundUrls, 'large') && {
      [`@media (min-width: ${theme.mediaQueries.large}px)`]: {
        backgroundImage: `url("${orderMethodBackgroundUrls.large}")`,
      },
    },
  }),
  dialogContainer: {
    maxWidth: '57rem',
    margin: '0 auto',
    padding: '0.8rem 0',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '0 1.6rem',
    },
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  closeButton: {
    position: 'absolute',
    top: '0.9rem',
    right: '0.8rem',
    zIndex: 10,
    padding: '1rem',
  },
  closeButtonIcon: {
    width: '1.4rem',
    height: '1.4rem',
    margin: 0,
    display: 'block',
  },
  inputContainer: {
    position: 'relative',
    marginBottom: '1.6rem',
  },
  dropdownStyle: {
    width: '100%',
    minHeight: '4.2rem',
    '& label': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '2.4rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  radioGroupShort: {
    maxWidth: '60%',
  },
  radioGroupItem: {
    alignItems: 'center',
  },
  radioGroupItemRadio: {
    marginRight: '1rem',
  },
  addressSelectorTrigger: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    display: 'block',
    width: '100%',
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '3rem',
  },
  dialogDescription: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '2rem',
    '&:empty': {
      display: 'none',
    },
  },
  dialogButtons: {
    maxWidth: '33.8rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '-1.6rem',
  },
  button: () => ({
    marginBottom: '1.6rem',
  }),
}))

export default useStyles
