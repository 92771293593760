import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    fontSize: '3rem',
    lineHeight: '3rem',
  },
  containerInline: {
    display: 'inline-flex',
    fontSize: '2rem',
    lineHeight: '2rem',
  },
  button: {
    color: theme.colors.text,
    appearance: 'none',
    border: 'none',
    background: 'none',
    padding: '1em',
    margin: '-1em',
    lineHeight: '1em',
    cursor: 'pointer',
    '&:disabled': {
      color: theme.colors.disabled,
      opacity: 0.3,
      cursor: 'not-allowed',
    },
  },
  buttonValueZeroView: {
    color: theme.colors.bgMediumDark,
  },
  buttonIcon: {
    width: 'calc(1em - 0.2rem)',
    height: 'calc(1em - 0.2rem)',
    border: '0.1rem solid currentColor',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    padding: 0,
    display: 'block',
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      pointerEvents: 'none',
      display: 'block',
      width: '60%',
      height: '0.2rem',
      backgroundColor: 'currentColor',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  buttonIconDecrease: {
    '&::after': {
      display: 'none',
    },
  },
  buttonIconIncrease: {
    '&::after': {
      transform: 'translate(-50%, -50%) rotate(90deg)',
      transformOrigin: 'center',
    },
  },
  value: {
    fontSize: '1.6rem',
    lineHeight: '1em',
    textAlign: 'center',
    color: 'currentColor',
    minWidth: '6rem',
    padding: '0 0.3rem',
    pointerEvents: 'none',
  },
  valueInline: {
    minWidth: '3.4rem',
  },
  quantityDropdown: {
    width: 'auto',
    margin: '0 1em',
    cursor: 'pointer',
  },
  quantityInput: {
    zIndex: 10,
    display: 'inline-flex',
    flexShrink: 1,
    flexGrow: 0,
    maxWidth: '16rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    appearance: 'textfield',
    padding: [['1rem', '0.5em']],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.bgDark,
    borderRadius: '0',
    backgroundColor: 'white',
    color: theme.colors.text,
    fontSize: '1em',
    textAlign: 'center',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      display: 'none',
    },
    '&:invalid': {
      borderColor: theme.colors.alert,
    },
  },
}))

export default useStyles
