import React from 'react'
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import CouponMonthBlock from '../../../coupon-month-block'
import CouponCard from '../../../coupon-card'
import Spinner from '../../../spinner'

export default function CouponsTabPanel({
  groups,
  hasMore,
  loadMore,
  getItemData,
  type,
  onSelect,
}) {
  // hook
  const { t } = useTranslation()

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={<Spinner key="loding-spinner" />}
      useWindow={false}
    >
      {
        groups.map((group) => {
          const dateFormat = t('ui.dateFormat.month', { defaultValue: 'MMMM YYYY' })
          const sectionHeaderText = _.includes(['expired', 'expireSoon'], group.month)
            ? t('ui.minicartCoupons.expire', { context: group.month })
            : t('ui.minicartCoupons.expire', {
              month: moment(group.month).format(dateFormat),
            })
          return (
            <CouponMonthBlock
              miniCartCoupon
              key={`coupon-${type}-${group.id}`}
              sectionHeaderText={sectionHeaderText}
            >
              {
                group.options.map((data) => {
                  const {
                    title,
                    discountType,
                    discountValue,
                    description,
                    displayTime,
                    displayTimeLabel,
                    imageUrl,
                    status,
                    disabled,
                    requiredPoints,
                    isExpired,
                    isTransferable,
                    isTransferInProgress,
                  } = getItemData(data)
                  return (
                    <CouponCard
                      miniCartCoupon
                      key={`coupon-${data.id}`}
                      type={type}
                      id={data.id}
                      displayImage
                      imageUrl={imageUrl}
                      title={title}
                      discountType={discountType}
                      discountValue={discountValue}
                      description={description}
                      displayTimeLabel={displayTimeLabel}
                      displayTime={displayTime}
                      status={status}
                      disabled={disabled}
                      requiredPoints={requiredPoints}
                      isExpired={isExpired}
                      isTransferable={isTransferable}
                      isTransferInProgress={isTransferInProgress}
                      onSelect={onSelect}
                    />
                  )
                })
              }
            </CouponMonthBlock>
          )
        })
      }
    </InfiniteScroll>
  )
}
