/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Select, { components } from 'react-select'
import { useFormikContext } from 'formik'
import { isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next'
import useStyles from './birthday-input-style'
import combineClassNames from '../../helpers/combineClassNames';
import {
  useThemeConfig,
} from '../../hook'
import arrowDownIcon from '../../assets/icons/icon_dropdown_arrow.svg'
// import Input from '../input'

/**
 * BirthdayInputController
 * customised input for birthday
 * @param {*} param0
 */
function BirthdayInputController({
  className,
  labelClassName,
  label,
  name,
  defaultValue,
  required,
  uppercase,
  onChange,
  disabled,
  inputFormat, // e.g. ['Do', 'MMMM', 'YYYY'],
  maximumDate = new Date(),
  minimumDate,
}) {
  const {
    errors, values,
  } = useFormikContext()
  const { getConfig } = useThemeConfig()
  const { i18n } = useTranslation()
  const currentLocale = i18n.language

  const supportedFormats = {
    year: ['YY', 'YYYY'],
    month: ['M', 'Mo', 'MM', 'MMM', 'MMMM'],
    day: ['D', 'Do', 'DD'],
  }

  const INPUT_FIELD_SIZE = {
    'zh-HK': {
      large: [],
      medium: ['YYYY', 'YY', 'MMMM', 'MMM', 'Mo', 'Do'],
      small: ['M', 'MM', 'D', 'DD'],
    },
    'zh-TW': {
      large: [],
      medium: ['YYYY', 'YY', 'MMMM', 'MMM', 'Mo', 'Do'],
      small: ['M', 'MM', 'D', 'DD'],
    },
    'zh-CN': {
      large: [],
      medium: ['YYYY', 'YY', 'MMMM', 'MMM', 'Mo', 'Do'],
      small: ['M', 'MM', 'D', 'DD'],
    },
    default: {
      large: ['MMMM'],
      medium: ['YYYY', 'Mo', 'Do'],
      small: ['YY', 'M', 'MM', 'MMM', 'D', 'DD'],
    },
  }

  const inputFieldSize = _.get(INPUT_FIELD_SIZE, currentLocale)
    || _.get(INPUT_FIELD_SIZE, 'default', {})

  const [birthday, setBirthday] = useState({
    year: defaultValue ? moment(defaultValue).format('YYYY') : '',
    month: defaultValue ? moment(defaultValue).format('MM') : '',
    day: defaultValue ? moment(defaultValue).format('DD') : '',
  })
  /* eslint-disable-next-line no-unused-vars */
  const [invalidAt, setInvalidAt] = useState(null)

  const dateFormat = useMemo(() => (
    inputFormat
    || getConfig('config.ui.birthdayInput.format')
    || ['YYYY', 'M', 'D']
  ), [inputFormat])

  const daysInMonth = useMemo(() => {
    const dateForCalc = `${birthday.year || '1900'}-${birthday.month || '01'}`
    // return 29 for Feb if year is not selected yet
    if (dateForCalc === '1900-02') return 29
    return moment(dateForCalc, 'YYYY-MM').daysInMonth()
  }, [birthday.year, birthday.month])

  const getOptions = useCallback(({
    type,
    format,
  }) => {
    const numberOfYears = _.isDate(minimumDate)
      ? moment(maximumDate).diff(minimumDate, 'years')
      : 120
    switch (type) {
      case 'day':
        return _.times(daysInMonth, (i) => ({
          label: moment('1900-01-01').add(i, 'days').format(format),
          value: moment('1900-01-01').add(i, 'days').format('DD'),
          key: type,
        }))
      case 'month':
        return _.times(12, (i) => ({
          label: moment('1900-01-01').add(i, 'months').format(format),
          value: moment('1900-01-01').add(i, 'months').format('MM'),
          key: type,
        }))
      case 'year':
        return _.times(numberOfYears, (i) => ({
          label: moment(maximumDate).subtract(i, 'years').format(format),
          value: moment(maximumDate).subtract(i, 'years').format('YYYY'),
          key: type,
        }))
      default:
    }
  }, [daysInMonth, maximumDate, minimumDate])

  const inputFields = useMemo(() => (
    _.compact(
      _.map(dateFormat, (field) => (
        _.reduce(supportedFormats, (result, value, key) => {
          if (!_.isEmpty(result)) return result
          if (_.includes(value, field)) {
            result = {
              type: key,
              format: field,
              options: getOptions({
                type: key,
                format: field,
              }),
            }
          }
          return result
        }, null)
      )),
    )
  ), [dateFormat, getOptions])

  const requiredFields = useMemo(() => (
    _.map(inputFields, 'type')
  ), [inputFields])

  const styles = useStyles({
    required,
    isSafari,
  })

  function handleChange({ value, key }) {
    setInvalidAt(null)
    setBirthday((prevDate) => ({
      ...prevDate,
      [key]: value,
    }))
  }

  useEffect(() => {
    const value = [
      birthday.year || (_.includes(requiredFields, 'year') ? '0000' : '1900'),
      birthday.month || (_.includes(requiredFields, 'month') ? '13' : '01'),
      birthday.day || (_.includes(requiredFields, 'day') ? '32' : '01'),
    ].join('-')

    const invalidAtInt = moment(value).invalidAt()

    if (
      // check if all fields are selected
      _.size(
        _.difference(
          requiredFields,
          _.keys(
            _.omitBy(birthday, _.isEmpty),
          ),
        ),
      ) > 0
      || moment(value).isAfter(maximumDate)
      || moment(value).isBefore(minimumDate || '1900-01-01')
      || !moment(value).isValid()
    ) {
      if (invalidAtInt >= 0) {
        switch (invalidAtInt) {
          case 0:
            setInvalidAt('year')
            break;
          case 1:
            setInvalidAt('month')
            break;
          case 2:
            setInvalidAt('day')
            break;
          default:
            setInvalidAt(null)
        }
      }
      onChange('')
      return
    }
    onChange(value)
  }, [birthday])

  const commonSelectProps = {
    className: styles.select,
    classNamePrefix: styles.select,
    placeholder: '',
    onChange: handleChange,
    required,
    disabled,
    components: {
      DropdownIndicator: (componentProps) => (
        <components.DropdownIndicator {...componentProps}>
          <img src={arrowDownIcon} alt="" />
        </components.DropdownIndicator>
      ),
    },
  }

  return (
    <div className={combineClassNames([styles.container, className])}>
      {
        label && (
          <label
            htmlFor="birthdayYear"
            className={combineClassNames([
              styles.label,
              uppercase && styles.labelUpperCase,
              labelClassName,
            ])}
          >
            {label}
          </label>
        )
      }
      <div className={styles.dateInputs}>
        {
          _.map(inputFields, (field) => (
            <div
              key={`birthday_${field.type}`}
              className={combineClassNames([
                styles.dateInput,
                styles[_.camelCase(`dateInput-${field.type}`)],
                styles[_.camelCase(`dateInput-${
                  _.reduce(inputFieldSize, (result, formats, size) => {
                    if (_.includes(formats, field.format)) {
                      result = size
                    }
                    return result
                  }, 'small')
                }`)],
              ])}
            >
              <Select
                name={field.type}
                options={field.options}
                {...commonSelectProps}
                value={_.find(field.options, { value: _.get(birthday, field.type) }) || ''}
              />
            </div>
          ))
        }
      </div>
      {errors[name] && values[name] ? <p className={styles.errorStyle}>{errors[name]}</p> : null}
    </div>
  )
}

export default BirthdayInputController
