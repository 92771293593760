import React, { useMemo } from 'react'
import ContentGroup from '../content-group'
import {
  useThemeConfig,
} from '../../hook'
import useStyles from './payment-logos-style'

const PaymentLogosView = () => {
  const { paymentLogos, boxLogos } = useStyles()
  const { getConfig, getContentGroup } = useThemeConfig()

  // local variable
  const paymentLogosContentGroupProps = useMemo(() => (
    getContentGroup('config.ui.paymentLogos', {
      code: 'ecom_payment_logos',
      template: 'List',
    })
  ), [getContentGroup])
  const isAvailable = getConfig('config.ui.paymentLogos.available', false)
  if (!isAvailable) return null
  return (
    <div className={paymentLogos}>
      <div className={boxLogos}>
        <ContentGroup {...paymentLogosContentGroupProps} />
      </div>
    </div>
  )
}

export default PaymentLogosView
