/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import PriceTag from '../price-tag'
import useStyles from './price-list-style'
import { combineClassNames } from '../../helpers';

const PriceView = ({
  items = [],
  align = 'left',
  currency,
  className,
  itemClassName,
  dividerClassName,
}) => {
  const {
    addEventListener,
    removeEventListener,
  } = window || {}
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [itemHeight, setItemHeight] = useState(0)
  const [resizeCount, setResizeCount] = useState(0)
  const wrapperRef = useRef(null)
  const itemRef = useRef(null)
  const isMultiLine = useMemo(() => (
    itemHeight > 0 && wrapperHeight > 0
    && itemHeight * 1.9 < wrapperHeight
  ), [wrapperHeight, itemHeight, resizeCount])
  const styles = useStyles()

  useEffect(() => {
    setWrapperHeight(wrapperRef.current.clientHeight)
    setItemHeight(itemRef.current.clientHeight)
  })

  const handleResize = () => {
    setResizeCount((prevCount) => (prevCount + 1))
  }

  useEffect(() => {
    addEventListener('resize', _.throttle(handleResize, 1000))
    return () => {
      removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      ref={wrapperRef}
      className={
        combineClassNames([
          styles.container,
          align === 'left' && styles.containerAlignLeft,
          align === 'right' && styles.containerAlignRight,
          align === 'center' && styles.containerAlignCenter,
          className,
        ])
      }
    >
      {
        _.map(_.compact(items), (item, index) => (
          <React.Fragment key={`price-${index}`}>
            {
              index !== 0
              && (
                <i
                  className={combineClassNames([
                    styles.divider,
                    isMultiLine && styles.dividerForMultiLine,
                    dividerClassName,
                  ])}
                />
              )
            }
            <PriceTag
              ref={index === 0 ? itemRef : null}
              className={
                combineClassNames([
                  styles.item,
                  ...(isMultiLine ? [
                    align === 'left' && styles.itemAlignLeft,
                    align === 'right' && styles.itemAlignRight,
                    align === 'center' && styles.itemAlignCenter,
                  ] : []),
                  itemClassName,
                ])
              }
              {...{
                align,
                currency,
                ...item,
              }}
            />
          </React.Fragment>
        ))
      }
    </div>
  )
}

export default PriceView
