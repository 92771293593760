import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    marginTop: '1.6rem',
  },
  label: (props) => ({
    textAlign: 'left',
    textTransform: 'none',
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: '-0.03em',
    paddingBottom: '0.9rem',
    display: 'block',
    position: 'relative',
    '&:after': {
      content: props.required ? '"*"' : '',
      color: theme.colors.alert,
      marginLeft: '0.3rem',
    },
  }),
  labelUpperCase: {
    textTransform: 'uppercase',
  },
  dateInputs: {
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: '-2rem',
  },
  dateInput: {
    marginRight: '2rem',
    width: '6rem',
    position: 'relative',
    '&:before': {
      content: '" - "',
      fontSize: '2rem',
      fontWeight: 600,
      position: 'absolute',
      color: theme.colors.bgDark,
      left: '-1.3rem',
      top: '50%',
      transform: ' translateY(-50%)',
    },
    '&:first-child': {
      '&:before': {
        content: '""',
      },
    },
  },
  dateInputLarge: {
    width: '15rem',
  },
  dateInputMedium: {
    width: '10rem',
  },
  dateInputSmall: {
    width: '6rem',
  },
  errorStyle: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '.8rem 0',
  },
  select: (props) => ({
    width: '100%',
    marginRight: '1rem',
    '& &__control': {
      borderColor: theme.colors.disabled,
      borderRadius: 0,
      fontSize: props.isSafari ? '1.6rem' : '1.4rem',
      color: theme.colors.text,
      height: '100%',
      '&--is-focused': {
        borderColor: theme.colors.disabled,
        boxShadow: 'none',
      },
    },
    '& &__indicator-separator': {
      display: 'none',
    },
    '& &__dropdown-indicator img': {
      margin: 0,
    },
    '& &__menu': {
      fontSize: props.isSafari ? '1.6rem' : '1.4rem',
      borderRadius: 0,
    },
    '& &__option--is-focused': {
      backgroundColor: theme.colors.bgMedium,
    },
    '& &__option--is-selected': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.bgLight,
    },
  }),
}))

export default useStyles
