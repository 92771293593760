import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

const AddonCustomerRemarkDisplay = ({ message }) => {
  const { t } = useTranslation()
  if (_.isEmpty(message)) return null
  return (
    <div>
      {t('ui.addonCustomerRemarkDisplay.message')}
      :
      <br />
      <span
        dangerouslySetInnerHTML={{ __html: message.replace(/\n+/g, '<br />') }}
      />
    </div>
  )
}

export default AddonCustomerRemarkDisplay
