/* eslint-disable react/no-danger */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import getCharacterCounts from '../../helpers/get-character-counts'
import useStyles from './input-customer-remarks-style'

let editingTimer = null

const InputCustomerRemarks = (props = {}, ref) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const {
    value: customerRemarkProp = '',
    maxNoOfLines = 3,
    maxNonEnglishCharacters = 12,
    maxCharacters = 27,
    minCharacters = 0,
    englishCharacterLength = 1,
    nonEnglishCharacterLength = 2,
    onChange,
    onEndEditing,
  } = props

  // states
  const [customerRemark, setCustomerRemark] = React.useState(customerRemarkProp)
  const [internalErrors, setInternalErrors] = React.useState(null)

  const checkErrors = (val) => {
    let errors = null
    const charCounts = getCharacterCounts(val, {
      asciiCharLength: englishCharacterLength,
      nonAsciiCharLength: nonEnglishCharacterLength,
    })
    if (charCounts.numberOfLines > maxNoOfLines - 1) {
      errors = t('ui.inputCustomerRemarks.maxNoOfLines', { num: maxNoOfLines })
    } else if (charCounts.numberOfNonAsciiChars > maxNonEnglishCharacters) {
      errors = t('ui.inputCustomerRemarks.maxNonEnglishCharacters', {
        num: maxNonEnglishCharacters,
      })
    } else if (charCounts.numberOfChars > maxCharacters) {
      errors = t('ui.inputCustomerRemarks.maxCharacters', { num: maxCharacters })
    } else if (charCounts.numberOfChars < minCharacters) {
      errors = t('ui.inputCustomerRemarks.minCharacters', {
        count: minCharacters,
        context: getContext(minCharacters),
      })
    }
    return errors
  }

  // side effects
  React.useEffect(() => {
    setCustomerRemark(customerRemarkProp || '')

    if (!_.isEmpty(customerRemarkProp)) {
      setInternalErrors(checkErrors(customerRemarkProp))
    }
  }, [customerRemarkProp])

  const getContext = (count) => {
    let context = null
    switch (true) {
      case count <= 0:
        context = 'zero'
        break
      case count === 1:
        context = 'one'
        break
      default:
        context = 'other'
    }
    return context
  }

  return (
    <div className={styles.container}>
      <textarea
        ref={ref}
        className={styles.textarea}
        placeholder={t('ui.inputCustomerRemarks.placeholder')}
        value={customerRemark}
        rows={maxNoOfLines}
        onKeyDown={() => {
          // clear timer
          clearTimeout(editingTimer)
          editingTimer = null
        }}
        onKeyUp={() => {
          // waiting 0.5s before executing change
          // editingTimer = setTimeout(() => {
          //   if (_.isFunction(onEndEditing)) onEndEditing(customerRemark)
          // }, 5000)
        }}
        onBlur={() => {
          if (_.isFunction(onEndEditing)) onEndEditing(customerRemark)
        }}
        onChange={(event) => {
          setInternalErrors(checkErrors(event.target.value))
          setCustomerRemark(event.target.value)
          if (_.isFunction(onChange)) onChange(event.target.value)
        }}
      />
      <div className={styles.errorStyle}>{internalErrors}</div>
      <div
        className={styles.notes}
        dangerouslySetInnerHTML={{
          __html: t('ui.inputCustomerRemarks.notes', {
            maxNoOfLines,
            maxNonEnglishCharacters,
            maxCharacters,
            englishCharacterLength,
            nonEnglishCharacterLength,
          }),
        }}
      />
    </div>
  )
}

export default React.forwardRef(InputCustomerRemarks)
