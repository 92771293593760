/* eslint-disable no-unused-vars */
import React from 'react'
import useStyles from './search-style'
import InputSearchView from '../input-search'
import combineClassNames from '../../helpers/combineClassNames';

const SearchView = ({ isOpenSearch, onSearch, className }) => {
  const {
    containerSeach,
    containerFluidSeach,
    containerFluidSeachOpen,
  } = useStyles({ isOpenSearch })
  return (
    <div
      className={
        combineClassNames([
          containerFluidSeach,
          isOpenSearch && containerFluidSeachOpen,
          className,
        ])
      }
    >
      <div className={containerSeach}>
        <InputSearchView onSearch={onSearch} isOpenSearch={isOpenSearch} />
      </div>
    </div>
  )
}

export default SearchView
