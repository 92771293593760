import _ from 'lodash'
import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    ':root': {
      '--swiper-theme-color': theme.colors.secondary,
      '--swiper-navigation-color': theme.colors.primary,
    },
  },
  swiper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    overflow: 'hidden',
    listStyle: 'none',
    zIndex: 1,
    display: 'block',
  },
  swiperActive: {
    padding: '0 2.5rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginLeft: '-2.5rem',
    },
    // list edges gradient
    '&:before, &:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 2,
      top: 0,
      bottom: 0,
      width: '4rem',
      pointerEvents: 'none',
    },
    '&:before': {
      left: 0,
      background: `linear-gradient(90deg, ${hexToRgba(_.get(theme, 'colors.background', '#fff'), 1)} 0%, ${hexToRgba(_.get(theme, 'colors.background', '#fff'), 0)} 100%)`,
    },
    '&:after': {
      right: 0,
      background: `linear-gradient(90deg, ${hexToRgba(_.get(theme, 'colors.background', '#fff'), 0)} 0%, ${hexToRgba(_.get(theme, 'colors.background', '#fff'), 1)} 100%)`,
    },
  },
}))

export default useStyles
