/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './applied-coupon-style'
import IconClose from '../../assets/icons/icon_cross.svg'

const AppliedCouponView = ({
  couponToken = {},
  onRemove,
  cartValidationError,
}) => {
  const { t } = useTranslation()
  const {
    coupon,
    isValid,
    noApplicableSku,
    notFulfilledCartTotalAmount,
    reachApplyLimitPerOrder,
  } = couponToken
  const isCouponError = !isValid
    || noApplicableSku
    || reachApplyLimitPerOrder
    || notFulfilledCartTotalAmount

  const errorMessage = useMemo(() => {
    if (!isCouponError) return ''

    let errorType = ''
    switch (true) {
      case noApplicableSku:
        errorType = 'noApplicableSku'
        break
      case notFulfilledCartTotalAmount:
        errorType = 'notFulfilledCartTotalAmount'
        break
      case reachApplyLimitPerOrder:
        errorType = 'reachApplyLimitPerOrder'
        break
      default:
        errorType = cartValidationError || 'invalid'
        break
    }
    return t('screens.checkout.checkout.coupon.error', { context: errorType })
  }, [isCouponError, noApplicableSku, notFulfilledCartTotalAmount, reachApplyLimitPerOrder])

  function handleRemove() {
    onRemove(couponToken.token)
  }

  const styles = useStyles()

  return (
    <>
      <div className={combineClassNames([styles.couponCard, isCouponError ? styles.couponCardError : ''])}>
        <span onClick={handleRemove}>
          <img src={IconClose} alt="" />
        </span>
        <p>{coupon.name}</p>
        <div dangerouslySetInnerHTML={{ __html: coupon.description }} />
      </div>
      { isCouponError
        && (
        <p className={styles.errorMessage}>
          {errorMessage}
        </p>
        )}
    </>
  )
}

export default AppliedCouponView
