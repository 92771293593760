/**
 * Convert an array of items into a grouped listing ready for use by SectionList.
 *
 * Input Data Structure:
 *
 * [
 *  { id, name, code, ... },
 *  { id, name, code, ... },
 *  { id, name, code, ... },
 * ]
 *
 * Output Data Structure:
 *
 * [
 *  {
 *    title: 'Section Title 1',
 *    data: [
 *      { id, name, code, ... },
 *      { id, name, code, ... },
 *      { id, name, code, ... },
 *    ]
 *  },
 *  {
 *    title: 'Section Title 2',
 *    data: [
 *      { id, name, code, ... },
 *      { id, name, code, ... },
 *      { id, name, code, ... },
 *    ]
 *  }
 * ];
 */
import _ from 'lodash';

export default function convertItemsToSectionList({ items, sectionTitle = null } = {}) {
  const data = [];
  _.forEach(items, (item) => {
    const title = _.isFunction(sectionTitle) ? sectionTitle(item) : 'Section';
    let section = _.find(data, ['title', title]);
    if (!section) {
      section = { title, data: [], index: data.length }
      data.push(section);
    }
    section.data = [
      ...section.data,
      item,
    ];
    const sectionIndex = _.findIndex(data, ['title', title]);
    // replace original section with the new one
    data.splice(sectionIndex, 1, section);
  });

  return data;
}
