/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'react-jss'
import CurrencyFormat from '../currency-format'
import Link from '../link'
import {
  combineClassNames,
  groupCartLineAddonsWithQuantity,
} from '../../helpers'
import useStyles from './product-mini-cart-style'
import ClearIcon from '../../assets/icons/icon_clear.svg'
import placeholderImage from '../../assets/images/icon_image_placeholder.png'
import AddonCustomerRemarkDisplay from '../addon-customer-remark-display'

const ProductMiniCartView = ({
  cartLine,
  currency,
  dark,
  onRemoveCartLine,
}) => {
  // prepare hook
  const { t } = useTranslation()
  const theme = useTheme()
  // local state
  // local variable

  const {
    addons,
    inlineError,
    priceDetails,
    quantity,
    sku,
  } = cartLine
  const {
    mainPropertyUnitPrice,
    mainPropertyTotalPrice,
    mainPropertySubtotalPrice,
  } = priceDetails
  const productTitle = _.get(sku, 'product.title')
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  const cartLineImage = dark
    ? _.get(sku, 'colorOption.defaultImage.versions.webThumb', placeholder)
    : _.get(sku, 'colorOption.defaultImage.versions.webSmallThumb', placeholder)
  const [noImage] = dark
    ? useState(_.isEmpty(_.get(sku, 'colorOption.defaultImage.versions.webThumb')))
    : useState(_.isEmpty(_.get(sku, 'colorOption.defaultImage.versions.webSmallThumb')))
  const discountGroup = _.get(priceDetails, 'mainPropertyDiscountGroup')
  // useMemo
  const variantDescription = useMemo(() => {
    const colorOptionName = _.get(sku, 'colorOption.name')
    const sizeOptionName = _.get(sku, 'sizeOption.name')
    const colorOptionVariantType = _.get(sku, 'product.colorOptionVariantType')
    const sizeOptionVariantType = _.get(sku, 'product.sizeOptionVariantType')
    const variants = []
    if (!_.get(sizeOptionVariantType, 'hideVariant', true)) {
      variants.push(`${sizeOptionVariantType.name}: ${sizeOptionName}`)
    }
    if (!_.get(colorOptionVariantType, 'hideVariant', true)) {
      variants.push(colorOptionName)
    }
    return variants.join(' / ')
  }, [sku])

  function handleRemove() {
    onRemoveCartLine({
      item: cartLine,
    })
  }

  const productUrl = `/product/${_.get(sku, 'product.id')}?color=${_.get(sku, 'colorOption.id')}`

  // style
  const styles = useStyles({ dark })
  const groupedAddons = groupCartLineAddonsWithQuantity(addons)

  return (
    <div className={styles.productCardStyle}>
      <button
        className={styles.clearIconButtonStyle}
        type="button"
        onClick={handleRemove}
      >
        <img className={styles.clearIconStyle} src={ClearIcon} alt="delete" />
      </button>
      <div className={styles.boxProductStyle}>
        <div className={combineClassNames([styles.imageProductStyle, noImage && styles.noImage])}>
          <div>
            <Link to={productUrl}>
              <img src={cartLineImage} alt="" />
            </Link>
          </div>
        </div>
        <div className={
          combineClassNames([
            styles.contentProductStyle,
            !dark && styles.contentMaxWidth,
          ])
        }
        >
          <p className={styles.textMinicart}>
            <Link to={productUrl} className={styles.productTitleLink}>
              {`${quantity} x ${productTitle}`}
            </Link>
          </p>
          {
            dark
              ? (
                <p className={styles.textMinicart}>
                  {variantDescription}
                </p>
              )
              : (
                <p className={
                  combineClassNames([
                    styles.textMinicart,
                    styles.contentMaxWidth,
                    styles.nameUnitProductColorStyle,
                  ])
                }
                >
                  {`${variantDescription}`}
                </p>
              )
          }
          {
            !_.isEmpty(groupedAddons)
            && (
              <div className={styles.addons}>
                {
                  _.map(groupedAddons, (addon) => {
                    const name = _.get(addon, 'colorOption.name', '')
                    const Name = () => (
                      <>
                        {'- '}
                        {name}
                        <AddonCustomerRemarkDisplay message={_.get(addon, 'customerRemark')} />
                      </>
                    )
                    const price = _.get(addon, 'priceDetails.propertyUnitPrice', '0.0')
                    const Price = () => (
                      _.toNumber(price) > 0 ? (
                        <>
                          {' (+'}
                          <CurrencyFormat
                            value={price}
                            currency={currency}
                            className={styles.originalPrice}
                          />
                          )
                        </>
                      ) : null
                    )
                    const qty = _.get(addon, 'quantity', '1')
                    const Qty = () => (
                      qty > 1 ? (
                        <>
                          {' x'}
                          {qty}
                        </>
                      ) : null
                    )
                    return (
                      <p className={
                        combineClassNames([
                          styles.textMinicart,
                          styles.textAddons,
                        ])
                      }
                      >
                        <Name />
                        <Price />
                        <Qty />
                      </p>
                    )
                  })
                }
              </div>
            )
          }
          <div className={styles.linePrice}>
            <p className={
              combineClassNames([
                styles.textMinicart,
                styles.priceUnitProductStyle,
              ])
            }
            >
              <CurrencyFormat value={mainPropertyUnitPrice} />
              {` x ${quantity}`}
            </p>
            <p className={
              combineClassNames([
                styles.textMinicart,
                styles.priceUnitProductStyle,
              ])
              }
            >
              <CurrencyFormat value={mainPropertyTotalPrice} />
            </p>
          </div>

          {
            _.map(discountGroup, (discount) => (
              <div
                key={`discount-line-${_.get(discount, 'uuid')}`}
                className={styles.linePrice}
              >
                <p className={
                  combineClassNames([
                    styles.textMinicart,
                    styles.priceUnitProductStyle,
                    !dark && styles.nameDiscountProductColorStyle,
                  ])
                }
                >
                  {_.get(discount, 'title', '')}
                </p>
                <p className={
                  combineClassNames([
                    styles.textMinicart,
                    styles.priceUnitProductStyle,
                    !dark && styles.priceUnitProductColorStyle,
                  ])
                }
                >
                  <CurrencyFormat value={_.get(discount, 'totalAmount', '')} />
                </p>
              </div>
            ))

          }
          <div className={styles.linePrice}>
            <p className={styles.textMinicart}>
              {t('ui.ProductMiniCart.subTotal')}
            </p>
            <p className={
              combineClassNames([
                styles.textMinicart,
                !dark && styles.priceUnitProductStyle,
              ])
              }
            >
              <CurrencyFormat value={mainPropertySubtotalPrice} />
            </p>
          </div>
        </div>
      </div>
      {
        !_.isEmpty(inlineError) && (
          <p className={styles.itemError}>{inlineError}</p>
        )
      }
    </div>
  )
}

export default ProductMiniCartView
