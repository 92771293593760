import _ from 'lodash';
import React from 'react'
import { combineClassNames } from '../../../helpers';
import Link from '../../link'

export default function Menu({ name = '', url, configOptions }) {
  return (
    <Link to={url} className={combineClassNames([_.get(configOptions, 'htmlClass')])}>
      {name}
    </Link>
  )
}
