import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  placeholderDiv: {
    minHeight: 400,
  },
  row: {
    margin: '0 -1.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '1.5rem',
    minHeight: '1rem',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      paddingBottom: '1.5rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      margin: '0 -1.5rem',
    },
  },
  row50: {
    maxWidth: `${theme.mediaQueries.desktop}px`,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      margin: '0 auto',
    },
  },
  col: {
    padding: '0 1.5rem',
    flexBasis: 'auto',
    flexGrow: 1,
    minWidth: 0,
    maxWidth: '100%',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      padding: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flexBasis: '0',
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
    },
    '& img': {
      transform: 'scale(1.01)',
      width: '100%',
      margin: 0,
    },
  },
  col50: {
    padding: '1.5rem',
    maxWidth: '100%',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      padding: '1.5rem',
      maxWidth: '50%',
      width: '50%',
    },
    '&:first-child': {
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        paddingLeft: '0',
      },
    },
    '&:last-child': {
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        paddingRight: '0',
      },
    },
  },

  banner: {
    margin: 0,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    '& img': {
      transform: 'scale(1)',
    },
  },
  wrapImage: {
    overflow: 'hidden',
  },
  imageBoxOneImage: {
    padding: 0,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      margin: '0 0 1.5rem',
    },
  },
  imageBoxThreeImage: {
    padding: 0,
    margin: 0,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      margin: '0 0 1.5rem',
      padding: '0 1.5rem',
    },
  },
  imageBoxFourImage: {
    padding: 0,
    margin: 0,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      margin: '0 0 1.5rem',
      padding: '0 1.5rem',
    },
  },
  buttonMoreStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      margin: 0,
      padding: 0,
    },
  },
  productGroupStyle: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginBottom: '2.5rem',
    },
  },
  productCardItem: {
    width: '50%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      width: '25%',
    },
  },
  moreButton: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
  },
  moreButtonIcon: {
    marginLeft: '0.8rem',
  },
  text: {
    color: theme.colors.primary,
    fontSize: 14,
    textAlign: 'center',
  },
  title: {
    fontSize: '2.4rem',
    fontWeight: 600,
    margin: '3rem 0 1rem',
    color: theme.colors.primary,
    lineHeight: '2.8rem',
    position: 'relative',
    width: 'auto',
    textAlign: 'left',
    textTransform: 'uppercase',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      fontSize: '3.6rem',
      lineHeight: 1.16,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      height: 3,
      width: 50,
      backgroundColor: theme.colors.secondary,
      left: 0,
      bottom: '-12px',
    },
  },
  richText: {
    color: theme.colors.text,
    textAlign: 'left',
    fontSize: 14,
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  link: {
    all: 'unset',
    cursor: 'pointer',
  },
}))

export default useStyles
