import _ from 'lodash'
import { createUseStyles } from 'react-jss'
import Color from 'color'
import getStyleFromTheme from '../../../helpers/get-style-from-theme'

const useStyles = createUseStyles((theme) => {
  const menuDefaultValue = {
    mobile: ['cart'],
    tabletLandscape: ['language', 'account', 'cart', 'search'],
  }
  const getMenuValue = ({ menu, item, styleProperty }) => {
    if (styleProperty === 'display') {
      return _.includes(menu, item) ? 'inline-flex' : 'none'
    }
    if (styleProperty === 'order') {
      return _.indexOf(menu, item)
    }
  }
  return ({
    toolsMenu: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    item: {
      position: 'relative',
      display: 'inline-flex',
      margin: '0 0 0 1.7rem',
      padding: '0.5rem 0',
      textTransform: 'uppercase',
      fontSize: '1.4rem',
      textDecoration: 'none',
      color: theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.primary,
      '& a': {
        color: 'currentColor',
        textDecoration: 'none',
      },
      '& button, & img': {
        outline: 'none',
      },
    },
    iconButton: {
      background: 'transparent',
      position: 'relative',
      border: 0,
      lineHeight: 1,
      padding: 0,
      width: '1.8rem',
      height: '1.8rem',
    },
    iconButtonDisabled: {
      '&:after': {
        display: 'none',
      },
    },
    icon: {
      objectFit: 'contain',
      marginLeft: 0,
      opacity: 0.8,
      display: 'block',
      width: '100%',
      height: '100%',
      color: theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.primary,
    },

    cartIconButton: {
      width: '2rem',
      height: '2rem',
      '&:after': {
        position: 'absolute',
        display: 'block',
        content: 'attr(data-count)',
        backgroundColor: Color(theme.colors.secondary).alpha(0.5).string(),
        borderRadius: '0.9rem',
        padding: '0.2rem 0.5rem',
        top: 0,
        right: 0,
        minWidth: '1.6rem',
        zIndex: 10,
        fontSize: '1rem',
        lineHeight: '1.2rem',
        fontWeight: '600',
        color: theme.colors.primary,
        transform: 'translate(50%, -65%)',
      },
      '&:disabled': {
        '&:after': {
          display: 'none',
        },
      },
    },
    cartIcon: {
      marginLeft: 0,
      opacity: 0.8,
      color: theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.primary,
    },
    cartIconDisabled: {
      opacity: 0.4,
    },
    languageList: {
      display: 'none',
      position: 'absolute',
      zIndex: 100,
      top: '100%',
      left: 0,
      border: '0.2rem solid currentColor',
      width: '13rem',
      backgroundColor: theme.colors.background,
    },
    languageListItem: {
      appearance: 'none',
      border: 'none',
      background: 'transparent',
      display: 'block',
      width: '100%',
      minHeight: '4.8rem',
      cursor: 'pointer',
      textAlign: 'left',
      paddingLeft: '1.6rem',
      color: theme.colors.text,
      '&:hover': {
        backgroundColor: theme.colors.bgLight,
      },
    },
    languageMemuAvailable: {
      paddingRight: '2rem',
      position: 'relative',
      '&:hover $languageList': {
        display: 'block',
      },
    },
    languageMemuAvailableIcon: {
      display: 'block',
      width: '1.2rem',
      height: '0.8rem',
      position: 'absolute',
      right: '0.2rem',
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.primary,
    },
    containerDropdown: {
      display: 'none',
      position: 'absolute',
      border: '2px solid currentColor',
      backgroundColor: theme.colors.background,
      listStyle: 'none',
      left: '50%',
      top: '100%',
      transform: 'translateX(-70%)',
      zIndex: 2,
      '& ul': {
        listStyle: 'none',
        margin: 0,
      },
    },
    dropdownMenu: {
      '&:hover $containerDropdown': {
        display: 'block',
      },
    },
    cart: _.merge(
      {},
      ..._.map(['display', 'order'], (styleProperty) => (
        getStyleFromTheme({
          theme,
          key: 'config.headerToolsMenu',
          getValueFn: (menu) => getMenuValue({ menu, item: 'cart', styleProperty }),
          styleProperty,
          defaultValue: menuDefaultValue,
        })
      )),
    ),
    language: _.merge(
      {},
      ..._.map(['display', 'order'], (styleProperty) => (
        getStyleFromTheme({
          theme,
          key: 'config.headerToolsMenu',
          getValueFn: (menu) => getMenuValue({ menu, item: 'language', styleProperty }),
          styleProperty,
          defaultValue: menuDefaultValue,
        })
      )),
    ),
    account: _.merge(
      {},
      ..._.map(['display', 'order'], (styleProperty) => (
        getStyleFromTheme({
          theme,
          key: 'config.headerToolsMenu',
          getValueFn: (menu) => getMenuValue({ menu, item: 'account', styleProperty }),
          styleProperty,
          defaultValue: menuDefaultValue,
        })
      )),
    ),
    accountDropdown: {
      ...getStyleFromTheme({
        theme,
        key: 'config.headerToolsMenu',
        getValueFn: (menu) => {
          if (!_.includes(menu, 'account')) return 'none'
          const rightSideItemCount = _.size(menu) - _.indexOf(menu, 'account') - 1
          if (rightSideItemCount > 1) {
            return 'translateX(-20%)'
          }
          return 'translateX(-70%)'
        },
        styleProperty: 'transform',
        defaultValue: menuDefaultValue,
      }),
    },
    search: _.merge(
      {},
      ..._.map(['display', 'order'], (styleProperty) => (
        getStyleFromTheme({
          theme,
          key: 'config.headerToolsMenu',
          getValueFn: (menu) => getMenuValue({ menu, item: 'search', styleProperty }),
          styleProperty,
          defaultValue: menuDefaultValue,
        })
      )),
    ),
    stores: _.merge(
      {},
      ..._.map(['display', 'order'], (styleProperty) => (
        getStyleFromTheme({
          theme,
          key: 'config.headerToolsMenu',
          getValueFn: (menu) => getMenuValue({ menu, item: 'stores', styleProperty }),
          styleProperty,
          defaultValue: menuDefaultValue,
        })
      )),
    ),
  })
})

export default useStyles
