import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ContentGroup from '../content-group'
import {
  useThemeConfig,
} from '../../hook'
import useStyles from './footer-checkout-style'

const FooterCheckoutView = () => {
  const { container, containerFluid, checkoutLinks } = useStyles()
  const { t } = useTranslation()
  const { getConfig, getContentGroup } = useThemeConfig()

  const footerCheckoutLinkContentGroupProps = useMemo(() => ({
    footer: getConfig('config.ui.footerCheckoutLink.footer', true),
    ...getContentGroup('config.ui.footerCheckoutLink', {
      code: 'ecom_footer_checkout_link',
      template: 'List',
    }),
  }), [getContentGroup])

  return (
    <div className={containerFluid}>
      <div className={container}>
        <div className={checkoutLinks}>
          <ContentGroup {...footerCheckoutLinkContentGroupProps} />
        </div>
        <p>{t('ui.footerCheckout.copy')}</p>
      </div>
    </div>
  )
}

export default FooterCheckoutView
