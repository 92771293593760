/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { createUseStyles } from 'react-jss'
import Color from 'color'
import getStyleFromTheme from '../../helpers/get-style-from-theme'

const parseConfigUnit = (value) => {
  if (_.isNumber(value)) return `${value / 10}rem`
  return value
}

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    padding: [['0.5rem', 0]],
    width: '100%',
    margin: '0 auto',
    backgroundColor: theme.config.headerBackgroundColor
      ? theme.config.headerBackgroundColor
      : theme.colors.background,
    ...getStyleFromTheme({
      theme,
      key: 'config.headerMinHeight',
      styleProperty: 'minHeight',
      getValueFn: parseConfigUnit,
      defaultValue: {
        mobile: 0,
        tabletLandscape: '17.1rem',
      },
    }),
  },
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'stretch',
    margin: [['0', 'auto']],
    minHeight: '5rem',
    ..._.merge(
      getStyleFromTheme({
        theme,
        key: 'config.headerTopSpacing',
        styleProperty: 'marginTop',
        getValueFn: parseConfigUnit,
        defaultValue: {
          mobile: 0,
          tabletLandscape: '2.8rem',
        },
      }),
      getStyleFromTheme({
        theme,
        key: 'config.headerBottomSpacing',
        styleProperty: 'marginBottom',
        getValueFn: parseConfigUnit,
        defaultValue: {
          mobile: 0,
          tabletLandscape: '4.2rem',
        },
      }),
    ),
  },
  topBar: {
    marginTop: '-0.5rem',
    marginBottom: '0.5rem',
    backgroundColor: _.get(theme, 'config.ui.header.topBar.backgroundColor', '#000000'),
    color: _.get(theme, 'config.ui.header.topBar.textColor', '#ffffff'),
    textAlign: 'center',
    padding: '0 1rem',
  },
  topBarContent: {
    color: 'currentColor',
    lineHeight: '1.2em',
    padding: '0.4em 0',
  },
  logo: {
    width: '50%',
    margin: 0,
    padding: [['0.2rem', 0]],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: 0,
    },
    ...getStyleFromTheme({
      theme,
      key: 'config.headerLogoWidth',
      getValueFn: parseConfigUnit,
      styleProperty: 'maxWidth',
      defaultValue: {
        mobile: '10rem',
        tabletLandscape: '20rem',
      },
    }),
  },
  logoImage: {
    display: 'block',
    width: '100%',
    margin: 0,
  },
  boxLogin: {
    position: 'absolute',
    right: '2rem',
    top: 0,
    bottom: 0,
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      top: 'auto',
    },
  },
  boxLoginList: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-0.9rem',
    marginBottom: 0,
    height: '100%',
  },
  boxLoginItem: {
    display: 'none',
    margin: '0 0 0 1.7rem',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    textDecoration: 'none',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      display: 'inline-block',
    },
    '& a': {
      color: 'currentColor',
      textDecoration: 'none',
    },
    '& button, & img': {
      outline: 'none',
    },
  },
  cart: {
    display: 'inline-block',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      marginLeft: '0.7rem',
    },
  },
  cartIconButton: {
    background: 'transparent',
    position: 'relative',
    border: 0,
    padding: '1.2rem 2.2rem',
    lineHeight: 1,
    transform: 'translateY(0.5rem)',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      padding: '0.5rem 1rem',
      marginRight: '-1rem',
      transform: 'none',
    },
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: 'attr(data-count)',
      backgroundColor: Color(theme.colors.secondary).alpha(0.8).string(),
      borderRadius: '0.9rem',
      padding: '0.2rem 0.5rem',
      top: '0.3rem',
      left: '2.9rem',
      minWidth: '1.6rem',
      zIndex: 10,
      fontSize: '1rem',
      lineHeight: '1.2rem',
      fontWeight: '600',
      color: Color(theme.colors.primary).alpha(0.8).string(),
      [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
        top: '-0.2rem',
        left: '1.7rem',
      },
    },
  },
  cartIconButtonDisabled: {
    '&:after': {
      display: 'none',
    },
  },
  cartIcon: {
    marginLeft: 0,
    opacity: 0.8,
    width: '1.6rem',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      transform: 'translate(0.1rem, 0.15rem) scale(1.25)',
    },
  },
  cartIconDisabled: {
    opacity: 0.4,
  },
  languageList: {
    display: 'none',
    position: 'absolute',
    zIndex: 100,
    top: '100%',
    left: 0,
    border: '0.2rem solid currentColor',
    width: '13rem',
    backgroundColor: theme.colors.background,
  },
  languageListItem: {
    appearance: 'none',
    border: 'none',
    background: 'transparent',
    display: 'block',
    width: '100%',
    minHeight: '4.8rem',
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: '1.6rem',
    color: theme.colors.text,
    '&:hover': {
      backgroundColor: theme.colors.bgLight,
    },
  },
  languageMemuAvailable: {
    paddingRight: '2rem',
    position: 'relative',
    '&:hover $languageList': {
      display: 'block',
    },
  },
  languageMemuAvailableIcon: {
    display: 'block',
    width: '1.2rem',
    height: '0.8rem',
    position: 'absolute',
    right: '0.2rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
  },
  containerDropdown: {
    display: 'none',
    position: 'absolute',
    border: '2px solid currentColor',
    backgroundColor: theme.colors.background,
    listStyle: 'none',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2,
    '& ul': {
      listStyle: 'none',
      margin: 0,
    },
  },
  dropdownMenu: {
    '&:hover $containerDropdown': {
      display: 'block',
    },
  },
  burgerMenu: {
    position: 'absolute',
    left: 12,
    margin: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.config.headerBackgroundColor,
    width: '4rem',
    ...getStyleFromTheme({
      theme,
      key: 'config.headerBurgerMenuEnabled',
      getValueFn: (enabled) => (enabled ? 'flex' : 'none'),
      styleProperty: 'display',
      defaultValue: {
        mobile: true,
        tabletLandscape: false,
      },
    }),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  burgerMenuLabel: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    ...getStyleFromTheme({
      theme,
      key: 'config.headerBurgerMenuLabelEnabled',
      getValueFn: (enabled) => (enabled ? 'block' : 'none'),
      styleProperty: 'display',
      defaultValue: {
        mobile: false,
        tabletLandscape: true,
      },
    }),
  },
  menuIcon: {
    width: '1.6rem',
    // transform: 'translateY(0.5rem)',
    color: theme.config.headerColorText,
    marginLeft: 0,
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
  },
  search: {
    cursor: 'pointer',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      marginLeft: '0.7rem',
    },
  },
  searchIconButton: {
    padding: '0.65rem 1rem 0.5rem 0.9rem',
    marginRight: '-1rem',
    lineHeight: 1,
    backgroundColor: 'transparent',
  },
  searchIcon: {
    width: '1.6rem',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
  },
  searchBar: {
    display: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      // margin: '0 auto 15px',
      maxWidth: theme.mediaQueries.large,
      display: 'block',
    },
  },
  megaMenu: {
    ...getStyleFromTheme({
      theme,
      key: 'config.headerMegaMenuEnabled',
      getValueFn: (enabled) => (enabled ? 'block' : 'none'),
      styleProperty: 'display',
      defaultValue: {
        mobile: false,
        tabletLandscape: true,
      },
    }),
  },
}))

export default useStyles
