import _ from 'lodash'
import React, { useMemo } from 'react'
import CurrencyFormat from '../currency-format'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './price-tag-style'

const PriceTagView = ({
  label,
  originalPrice,
  sellPrice,
  currency,
  align = 'left',
  prefix,
  suffix,
  className,
}, ref) => {
  const isPrice = (value) => _.isNumber(value) || _.isString(value)
  const withLabel = useMemo(() => !_.isEmpty(label), [label])
  const onSale = useMemo(
    () => _.toNumber(originalPrice) !== _.toNumber(sellPrice)
          && isPrice(originalPrice)
          && isPrice(sellPrice),
    [originalPrice, sellPrice],
  )
  const styles = useStyles({
    align,
    prefix,
    suffix,
  })
  const sellPriceClassName = combineClassNames([
    styles.sellPrice,
    onSale && styles.sellPriceOnSale,
  ])
  return (
    <div className={className} ref={ref}>
      <div
        className={combineClassNames([
          styles.container,
          withLabel && styles.containerWithLabel,
          _.get(styles, _.camelCase(`containerAlign_${align}`)),
        ])}
      >
        {withLabel && <div className={styles.label}>{label}</div>}
        <div className={styles.price}>
          {onSale && (
            <CurrencyFormat
              value={_.toString(originalPrice)}
              currency={currency}
              className={styles.originalPrice}
            />
          )}
          {!_.isEmpty(prefix) && <span className={sellPriceClassName}>{prefix}</span>}
          <CurrencyFormat
            value={_.toString(sellPrice)}
            currency={currency}
            className={sellPriceClassName}
          />
          {!_.isEmpty(suffix) && <span className={sellPriceClassName}>{suffix}</span>}
        </div>
      </div>
    </div>
  )
}

export default React.forwardRef(PriceTagView)
