/* eslint-disable react-hooks/exhaustive-deps */
/**
 * MiniCartProvider
 * Contain most logic mini cart
 */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useStores,
  // useSystemSettings,
} from 'react-omnitech-api'
import { useAlert } from '../use-alert'
import useCart from '../use-cart'
import useSku from '../use-sku'
import useOrderMethod from '../use-order-method'
import MiniCartContext from './mini-cart-context'
import useAnalytics from '../use-analytics'

export default function MiniCartProvider({ children }) {
  // prepare hook
  const alert = useAlert()
  const { t } = useTranslation()
  const { trackEvent } = useAnalytics()
  const {
    fetchCart,
    getParams,
    totalItems,
    updateCart,
  } = useCart()
  const { isAllowToCheckout } = useSku()
  const {
    fetchStoreDateTimeInformation,
  } = useStores()
  const {
    availableOrderMethod,
    orderMethod,
  } = useOrderMethod()
  // const { getSystemSetting } = useSystemSettings()
  // const fnbEnabled = getSystemSetting('features.fnb.enable')
  // internal state
  const [cart, setCart] = useState({})
  const [storeMenuCodes, setStoreMenuCodes] = useState([])
  const [loading, setLoading] = useState(false)
  const [miniCartOpen, setMiniCartOpen] = useState(false)
  const [skusUnavailableToCheckout, setSkusUnavailableToCheckout] = useState([])

  /**
   * apiFetchCart
   * get cart data from API
   */
  function apiFetchCart() {
    const options = {
      params: getParams({
        includeGroups: ['miniCart'],
      }),
    }
    return fetchCart(options)
  }

  /**
   * apiUpdateCart
   * call API to update cart
   * @param {*} actions
   */
  function apiUpdateCart(actions) {
    const option = {
      payload: {
        data: {
          actions,
        },
        batchUpdateMode: 2,
      },
      params: getParams({
        includeGroups: ['miniCart'],
      }),
    }
    return updateCart(option)
  }

  /**
   * handleError
   * @param {*} error
   */
  async function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    let errorMessage = ''
    if (generalError.code === 404) {
      errorMessage = t('screens.cart.errors.cartNotFound')
    } else {
      const batchActionErrors = _.get(error, 'batchActionErrors', [])
      const batchActionError = _.find(batchActionErrors, 'message') || {}
      // if batch action is not provided, use the general error message
      errorMessage = _.isEmpty(batchActionError.message)
        ? generalError.message
        : batchActionError.message
    }
    alert.show(errorMessage)
  }

  /**
   * function handleFetchCart() {
   */
  async function handleFetchCart() {
    try {
      // call api
      const { cart: data } = await apiFetchCart()
      // get store dateTimeInformation before valdation
      const { dateTimeInformation } = await fetchStoreDateTimeInformation({ id: _.get(data, 'cartShipments.0.inventoryStore.id') })
      setCart(data)
      setStoreMenuCodes(_.get(dateTimeInformation, 'menuCodes', []))
      return data
    } catch (error) {
      await handleError(error)
    } finally {
      setLoading(false)
    }
  }

  function onTrackRemoveFromCart(cartLineId) {
    const { cartLineProperties } = cart
    const cartLineProperty = _.find(cartLineProperties, {
      id: cartLineId,
    })
    const name = _.get(cartLineProperty, 'sku.product.title')
    const skuCode = _.get(cartLineProperty, 'sku.code')
    const price = _.get(cartLineProperty, 'priceDetails.propertyUnitPrice')
    const quantity = _.get(cartLineProperty, 'quantity')
    const title = 'Page Mini-Cart'

    trackEvent('customerRemoveFromCart',
      {},
      {
        name,
        price,
        quantity,
        skuCode,
        title,
      })
  }

  /**
   * function handleCloseMiniCart() {
   * close mini cart
   */
  function handleCloseMiniCart() {
    setMiniCartOpen(false)
  }

  /**
   * function handleOpenMiniCart() {
   * open mini cart
   */
  function handleOpenMiniCart() {
    setLoading(true)
    setMiniCartOpen(true)
  }

  /**
   * handleRemoveCartLine
   */
  function handleRemoveCartLine({
    item,
  }) {
    const actions = [
      {
        actionType: 'update_cart_line_property',
        skuId: item.sku.id,
        quantity: 0,
        quantityMode: 'fixed',
        productAddonId: null,
        groupUuid: item.groupUuid,
        identifierUuid: item.identifierUuid,
        parentIdentifierUuid: null,
      },
    ]
    handleUpdateCart(actions)
    onTrackRemoveFromCart(item.id)
  }

  /**
   * handleUpdateCart
   * handle all update cart actions
   * @param {*} actions
   */
  async function handleUpdateCart(actions) {
    alert.remove()
    setLoading(true)
    try {
      const { cart: data } = await apiUpdateCart(actions)
      setCart(data)
      // close mini cart when cart is empty
      if (_.isEmpty(data.cartLineProperties)) {
        handleCloseMiniCart()
      }
    } catch (error) {
      await handleError(error)
    } finally {
      setLoading(false)
    }
  }

  function checkSkuAllowToCheckout(skus) {
    // pass-in cart exclusions
    const exclusions = _.get(orderMethod, 'cartSkuCheckExclusions', {})
    setSkusUnavailableToCheckout(
      _.reject(skus, (s) => isAllowToCheckout({ sku: s, storeMenuCodes, exclusions })),
    )
  }

  /**
   * check once mini cart is open, fetch cart data
   */
  useEffect(() => {
    if (miniCartOpen && loading) {
      handleFetchCart()
    }
  }, [miniCartOpen, loading])

  /**
   * check skus are allow to checkout for fnb
   */
  useEffect(() => {
    const skus = _.uniqBy(_.map(_.get(cart, 'cartLineProperties', []), 'sku'), 'id')
    if (!_.isEmpty(skus) && !_.isEmpty(availableOrderMethod)) {
      checkSkuAllowToCheckout(skus)
    }
  }, [cart, availableOrderMethod])

  const state = {
    cart,
    loading,
    miniCartOpen,
    totalItems,
    skusUnavailableToCheckout,
    openMiniCart: handleOpenMiniCart,
    closeMiniCart: handleCloseMiniCart,
    onRemoveCartLine: handleRemoveCartLine,
  }

  return (
    <MiniCartContext.Provider value={state}>
      {children}
    </MiniCartContext.Provider>
  )
}
