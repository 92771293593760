/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useStyles from './product-card-style'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductCardView = () => {
  const styles = useStyles()

  return (
    <div className={styles.productCard}>
      <div className={styles.figure}>
        <div className={styles.image} />
      </div>
      <div className={styles.content}>
        <div className={styles.title} />
        <div className={styles.variant} />
        <div className={styles.price} />
      </div>
    </div>
  )
}

export default ProductCardView
