/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import InputRadio from '../input-radio'
import useStyles from './input-radio-group-style'
import InputCustomerRemarks from '../input-customer-remarks'

const InputRadioGroupView = ({
  groupId,
  className,
  itemClassName,
  labelClassName,
  radioClassName,
  textClassName,
  separatorClassName,
  options,
  formik,
  name,
  disabled,
  value,
  onChange,
  onUpdate,
  addonCustomerRemarks,
}) => {
  const styles = useStyles()

  const onItemChange = (data) => {
    if (_.isFunction(onChange)) {
      const { id, customerRemark } = data
      onChange({
        value: id,
        customerRemark,
      })
    }
  }

  const onItemRemarkChange = (data) => {
    if (_.isFunction(onUpdate)) {
      const { id, customerRemark } = data
      onUpdate({
        value: id,
        customerRemark,
      })
    }
  }

  return (
    <div className={combineClassNames([styles.group, className])}>
      {_.map(options, (item, index) => {
        const itemId = _.get(item, 'id')
        const checked = value === itemId
        let showAddonRemarksField = false
        let customerRemarkProps = {
          onEndEditing: (val) => onItemChange({ id: itemId, customerRemark: val }),
          onChange: (val) => onItemRemarkChange({ id: itemId, customerRemark: val }),
          value: item.customerRemark,
        }
        if (_.findIndex(addonCustomerRemarks, { code: item.skuCode }) !== -1) {
          showAddonRemarksField = true
          customerRemarkProps = {
            ...customerRemarkProps,
            ..._.omit(_.find(addonCustomerRemarks, { code: item.skuCode }), ['code']),
          }
        }
        const itemProps = {
          ...item,
          className: itemClassName,
          labelClassName: combineClassNames([styles.label, labelClassName]),
          radioClassName: combineClassNames([styles.radio, radioClassName]),
          textClassName: combineClassNames([styles.text, textClassName]),
          formik,
          disabled: disabled || _.get(item, 'disabled', false),
          name,
          value: itemId,
          checked,
          onChange: () => onItemChange({ id: itemId }),
        }
        return (
          <React.Fragment key={`InputRadio_${groupId}_${itemId}`}>
            {index !== 0 ? (
              <hr className={combineClassNames([styles.separator, separatorClassName])} />
            ) : (
              <></>
            )}
            <InputRadio
              key={`InputRadio_input_${groupId}_${itemId}`}
              {...itemProps}
              // make sure label htmlFor/input id have same uniq id
              id={`InputRadio_input_${groupId}_${itemId}`}
            >
              <div>
                <p className={combineClassNames([styles.text, textClassName])}>
                  {_.get(item, 'title', '')}
                </p>
                {_.get(item, 'content')}
              </div>
            </InputRadio>
            {checked && showAddonRemarksField && (
              <InputCustomerRemarks {...customerRemarkProps} />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default InputRadioGroupView
