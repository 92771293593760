/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-return */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import moment from 'moment'
import {
  useMetaDefinitions,
  useStores,
} from 'react-omnitech-api'
import useCurrentOrderingPeriod from '../use-current-ordering-period'
import useCart from '../use-cart'
import { useCommerceChannel } from '../use-commerce-channel'
import useOrderMethod from '../use-order-method'

export default function useSku() {
  const {
    commerceChannels,
  } = useCommerceChannel()
  const {
    orderMethod,
    store: orderMethodStore,
  } = useOrderMethod()
  const {
    fetchStoreDateTimeInformation,
    stores,
  } = useStores()
  const {
    inventoryStoreCode: cartInventoryStoreCode,
    inventoryStoreId: cartInventoryStoreId,
    initCart,
  } = useCart()
  const { metaDefinitions } = useMetaDefinitions()
  const currentSlotCode = useCurrentOrderingPeriod()
  const commerceChannelPublicConfigSchemaVersion = '20221219'

  // PRIVATE METHODS
  function getMenuFilters({ commerceChannelConfig }) {
    const commerceChannelConfigMenuFilters = _.get(commerceChannelConfig, 'menuFilters')
    const commerceChannelConfigMenuNotFilters = _.get(commerceChannelConfig, 'menuNotFilters')
    const commerceChannelConfigMenuCodeFilter = _.get(commerceChannelConfig, 'useMenuCodeFilter')
    const orderMethodMenuFilters = _.get(orderMethod, 'menuFilters')
    const orderMethodMenuNotFilters = _.get(orderMethod, 'menuNotFilters')
    const orderMethodMenuCodeFilter = _.get(orderMethod, 'useMenuCodeFilter')

    const menuFilters = orderMethodMenuFilters || commerceChannelConfigMenuFilters || []
    const menuNotFilters = orderMethodMenuNotFilters || commerceChannelConfigMenuNotFilters || []
    // if key exists whether true/false we should use that as priority
    let useMenuCodeFilter = false
    if (_.has(commerceChannelConfig, 'useMenuCodeFilter')) {
      useMenuCodeFilter = commerceChannelConfigMenuCodeFilter
    }
    if (_.has(orderMethod, 'useMenuCodeFilter')) {
      useMenuCodeFilter = orderMethodMenuCodeFilter
    }

    const parseKey = (key) => {
      switch (_.camelCase(key)) {
        case 'store':
          return 'storeCode'
        case 'commerceChannel':
          return 'commerceChannelCode'
        case 'timeSlot':
        case 'timeslot':
          return 'timeslotCode'
        case 'date':
          return 'today'
        default:
          return key
      }
    }

    return {
      menuFilters: _.map(menuFilters, parseKey),
      menuNotFilters: _.map(menuNotFilters, parseKey),
      useMenuCodeFilter,
    }
  }

  const isSoldOut = (sku) => {
    if (!_.has(sku, 'stockLevel')) return false
    return (
      _.get(sku, 'notAllowedToCheckout', false)
      || _.isNumber(_.get(sku, 'stockLevel', null)) ? _.get(sku, 'stockLevel', 0) === 0 : false
    )
  }

  const validateSku = ({ sku, storeMenuCodes = [], exclusions = {} }) => {
    const {
      commerceChannel,
    } = orderMethod
    const supportMenuFilters = !_.isEmpty(
      _.find(metaDefinitions, {
        metaModelName: 'sku',
        fieldKey: 'menu_filter',
      }),
    )
    const supportMenuNotFilters = !_.isEmpty(
      _.find(metaDefinitions, {
        metaModelName: 'sku',
        fieldKey: 'menu_not_filter',
      }),
    )
    const {
      // an array of strings where sku is available.
      // i.e. "Sunday" means sku is available if dayOfWeek is Sunday
      menuFilter = [],
      // an array of strings where sku is NOT avialble.
      // i.e. "Sunday" means sku is NOT available if dayOfWeek is Sunday
      menuNotFilter = [],
      // an array of strings where sku is available (match determined by api).
      // i.e. any strings that match with those in `storeMenuCodes` means the sku is available.
      menuCodeFilter = [],
    } = _.get(sku, 'meta', {})

    const store = _.find(stores, { code: _.get(orderMethodStore, 'code') })
    const dineInPhysicalStore = _.find(stores, { id: _.get(initCart, 'physicalStoreId') })
    // stores code to check with menu filter priority:
    // - dineInPhysicalStore > cartInventoryStore > orderMethod store
    const storeCode = _.get(dineInPhysicalStore, 'code', cartInventoryStoreCode) || _.get(orderMethodStore, 'code')
    const dayOfWeek = moment().format('dddd')

    // if any values in storeMenuCodes matches those in menuCodeFilter then
    // set isMenuCodeFilter to true
    let isMenuCodeFilter = false
    const commerceChannelConfig = _.get(commerceChannels, `${commerceChannel}.publicConfig.${commerceChannelPublicConfigSchemaVersion}`, {})
    const useMenuCodeFilter = _.get(getMenuFilters({ commerceChannelConfig }), 'useMenuCodeFilter', false)
    if (useMenuCodeFilter) {
      _.forEach(storeMenuCodes, (code) => {
        if (_.indexOf(menuCodeFilter, code) !== -1) {
          // menuCode match found
          isMenuCodeFilter = true
          // skip dayOfWeek checking when there is menuCodeFilter checking
          exclusions = {
            ...exclusions,
            dayOfWeek: true,
          }
          return // exit forEach loop
        }
      })
    }
    if (
      // if storeMenuCodes is empty then skip menuCodeFilter checking
      _.isEmpty(storeMenuCodes)
      // skip menuCodeFilter checking if useMenuCodes is false
      || !useMenuCodeFilter
    ) {
      isMenuCodeFilter = true
    }

    // check store open time slot
    const todayOpenTimeSlots = _.get(store, 'todayOpenTimeSlots', [])
    const disableTakeAwayOrdering = _.get(store, 'meta.disableTakeAwayOrdering', false)
    const isWithinOpenTimeSlots = !_.isEmpty(
      _.find(todayOpenTimeSlots, ({ start, end }) => moment().isBetween(start, end)),
    // Empty time slot means store always available e.g. warehouse
    ) || _.isEmpty(todayOpenTimeSlots)

    const valueToCompare = (key) => {
      // To support multi-factor key. e.g. `store|timeSlot`
      if (_.includes(key, '|')) {
        return _.join(
          _.map(
            _.split(key, '|'),
            valueToCompare,
          ),
          '|',
        )
      }
      switch (key) {
        case 'commerceChannel':
          return commerceChannel
        case 'store':
          return storeCode
        case 'timeSlot':
          return currentSlotCode
        case 'dayOfWeek':
          return dayOfWeek
        default:
          return key
      }
    }
    // whitelist filters - an array of strings that represent the current state of the users
    // demographics: commerceChannel, store, timeslot, and dayOfWeek
    const whitelist = [
      'commerceChannel',
      'store',
      'timeSlot',
      'dayOfWeek',
    ]
    // blacklist filters - an array of strings that represent the current state of the users
    // demographics: whitelistFilters + store|timeslot and store|dayofweek
    const blacklist = [
      ...whitelist,
      'store|timeSlot',
      'store|dayOfWeek',
      'store|dayOfWeek|timeSlot',
      'dayOfWeek|timeSlot',
    ]
    const isFilterKeyExcluded = (key) => (
      _.some(
        _.split(key, '|'),
        (_key) => _.get(exclusions, _key, false),
      )
    )
    const menuFilterResult = (
      _.transform(whitelist, (result, key) => {
        _.set(
          result,
          _.camelCase(`menuFilter_${key}`),
          (
            isFilterKeyExcluded(key)
            || !supportMenuFilters
            || _.includes(menuFilter, valueToCompare(key))
          ),
        )
      }, {})
    )
    const menuNotFilterResult = (
      _.transform(blacklist, (result, key) => {
        _.set(
          result,
          _.camelCase(`menuNotFilter_${key}`),
          (
            isFilterKeyExcluded(key)
            || !supportMenuNotFilters
            || !_.includes(menuNotFilter, valueToCompare(key))
          ),
        )
      }, {})
    )

    const validateResult = {
      stockLevel: (!isSoldOut(sku) || _.get(exclusions, 'stockLevel', false)),
      storeOpeningHours: (isWithinOpenTimeSlots || _.get(exclusions, 'storeOpeningHours', false)),
      storeSettings: !disableTakeAwayOrdering,
      menuCodeFilter: isMenuCodeFilter || _.get(exclusions, 'menuCodeFilter', false),
      ...menuFilterResult,
      ...menuNotFilterResult,
    }
    const invalidResult = _.omitBy(validateResult)
    if (!_.isEmpty(invalidResult)) {
      console.log(`////////////////////////\n//////// CheckoutService.validateSku() ////////\n//////// ${_.get(sku, 'code')} ////////\n//////// ${_.join(_.keys(invalidResult), ', ')} ////////\n////////////////////////`)
    }
    return validateResult
  }

  const isAllowToCheckout = ({ sku, storeMenuCodes = [], exclusions = {} }) => (
    _.isEmpty(
      _.omitBy(validateSku({ sku, storeMenuCodes, exclusions })),
    )
  )

  // check an array of skus to determine if all are allowed to checkout
  // true - all are available for checkout
  // false - 1 or more is not allowed to checkout
  const isAllowToCheckoutAll = ({ skus, storeMenuCodes = [], exclusions = {} }) => {
    const checklist = _.map(skus, (sku) => isAllowToCheckout({ sku, storeMenuCodes, exclusions }))
    // return true if all checklist items are true
    return checklist.every((v) => v === true)
  }

  function getMetaMenuFilterParams(options = {}) {
    const {
      commerceChannel: commerceChannelOverride,
      prefix,
      storeCode = _.get(orderMethodStore, 'code'),
    } = options

    const isMenuFilterSupported = !_.isEmpty(
      _.find(metaDefinitions, { metaModelName: 'sku', fieldKey: 'menu_filter' }),
    )
    const isMenuNotFilterSupported = !_.isEmpty(
      _.find(metaDefinitions, { metaModelName: 'sku', fieldKey: 'menu_not_filter' }),
    )

    // Exit if no menu filter support
    if (!isMenuFilterSupported && !isMenuNotFilterSupported) return {}

    const commerceChannel = _.get(orderMethod, 'commerceChannel')
    const commerceChannelConfig = _.get(commerceChannels, `${commerceChannelOverride || commerceChannel}.publicConfig.${commerceChannelPublicConfigSchemaVersion}`, {})
    const menuFilters = _.get(getMenuFilters({ commerceChannelConfig }), 'menuFilters', [])
    const menuNotFilters = _.get(getMenuFilters({ commerceChannelConfig }), 'menuNotFilters', [])

    const parseMenuFilterValue = (key) => {
      switch (_.camelCase(key)) {
        case 'storeCode':
          return storeCode
        case 'dayOfWeek':
          return moment().format('dddd')
        case 'commerceChannelCode':
          return commerceChannel
        case 'timeslotCode':
          return currentSlotCode
        case 'today':
          return moment().format('YYYYMMDD')
        default:
          return key
      }
    }
    const menuFiltersParam = {
      [_.join(_.compact([prefix, 'meta_menu_filter_exist_all']), '|')]: _.compact(_.map(menuFilters, parseMenuFilterValue)),
    }
    const menuNotFiltersParam = {
      [_.join(_.compact([prefix, 'meta_menu_not_filter_not_exist_any']), '|')]: _.compact(_.map(menuNotFilters, parseMenuFilterValue)),
    }
    return {
      ...(isMenuFilterSupported ? menuFiltersParam : {}),
      ...(isMenuNotFilterSupported ? menuNotFiltersParam : {}),
    }
  }

  async function getMetaMenuCodeFilterParamsAsync(options = {}) {
    const {
      commerceChannel: commerceChannelOverride,
      prefix,
      storeId: storeIdOverride,
    } = options
    const commerceChannel = _.get(orderMethod, 'commerceChannel')
    const commerceChannelConfig = _.get(commerceChannels, `${commerceChannelOverride || commerceChannel}.publicConfig.${commerceChannelPublicConfigSchemaVersion}`, {})
    const storeId = storeIdOverride || cartInventoryStoreId || _.get(orderMethodStore, 'id')
    const isMenuCodeFilterSupported = !_.isEmpty(
      _.find(
        metaDefinitions,
        {
          metaModelName: 'sku',
          fieldKey: 'menu_code_filter',
        },
      ),
    )

    // Exit if no menu code filter support
    if (!isMenuCodeFilterSupported) return {}

    // exit if useMenuCodeFilter is false
    if (!_.get(getMenuFilters({ commerceChannelConfig }), 'useMenuCodeFilter', false)) return {}

    // find inventory store id from cart or order method

    // get store dateTimeInformation before valdation
    const data = await fetchStoreDateTimeInformation({ id: storeId })

    const storeMenuCodes = _.get(data, 'dateTimeInformation.menuCodes', [])

    return !_.isEmpty(storeMenuCodes)
      ? { [_.join(_.compact([prefix, 'meta_menu_code_filter_exist_any']), '|')]: storeMenuCodes }
      : {}
  }

  return {
    getMetaMenuCodeFilterParamsAsync,
    getMetaMenuFilterParams,
    isAllowToCheckout,
    isAllowToCheckoutAll,
    isSoldOut,
    validateSku,
  }
}
