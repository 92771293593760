import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.config.couponTransferableLabelBackgroundColor || theme.colors.secondary,
    padding: 6,
    borderBottomRightRadius: 6,
    display: 'block',
  },
  labelText: {
    display: 'block',
    fontSize: '1.2rem',
    lineHeight: '1.2em',
    color: theme.config.couponTransferableLabelTextColor || theme.colors.text,
  },
  labelInactive: {
    backgroundColor: theme.config.couponExpiredLabelBackgroundColor || theme.colors.disabled,
  },
  labelTextInactive: {
    color: theme.config.couponExpiredLabelTextColor || '#f7f7f7',
  },
}))

export default useStyles
