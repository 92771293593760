/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './coupon-state-label-style'

import combineClassNames from '../../helpers/combineClassNames'

const CouponStateLabel = (props) => {
  const {
    isExpired,
    isTransferable,
    isTransferInProgress,
    className,
    textClassName,
    inactiveClassName,
    textInactiveClassName,
  } = props
  const { t } = useTranslation()
  const styles = useStyles()
  let labelType = 'active'
  let labelText = ''
  if (isExpired) {
    labelText = t('ui.minicartCoupons.couponExpiredLabel')
    labelType = 'inactive'
  } else if (isTransferInProgress) {
    labelText = t('ui.minicartCoupons.couponTransferInProgressLabel')
    labelType = 'inactive'
  } else if (isTransferable) {
    labelText = t('ui.minicartCoupons.couponTransferableLabel')
  } else {
    return null
  }
  return (
    <span
      className={
        combineClassNames([
          styles.label,
          ..._.castArray(className),
          labelType === 'inactive' && styles.labelInactive,
          ..._.castArray(inactiveClassName),
        ])
      }
    >
      <span
        className={
          combineClassNames([
            styles.labelText,
            ..._.castArray(textClassName),
            labelType === 'inactive' && styles.labelTextInactive,
            ..._.castArray(textInactiveClassName),
          ])
        }
      >
        {labelText}
      </span>
    </span>
  )
}

export default CouponStateLabel
