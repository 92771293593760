/* eslint-disable react-hooks/exhaustive-deps */
import {
  useSystemSettings,
} from 'react-omnitech-api'

export default function useCustomerRankSettings() {
  const { getSystemSetting } = useSystemSettings()
  const vendorSpecificSettings = getSystemSetting('vendor_specific_system_setting_key')
  const customerRankSettings = getSystemSetting(`vendor_specific_settings.${vendorSpecificSettings}.frontend.customer_rank_settings`)

  return customerRankSettings || {}
}
