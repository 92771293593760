import _ from 'lodash'
import React from 'react'
import {
  combineClassNames,
} from '../../../helpers'
import Link from '../../link'
import useStyles from './content-group-style'

export default function Text(props) {
  const styles = useStyles()
  const {
    configOptions = {},
    contentGroup,
    name,
    style,
    url,
  } = props
  const { display = '', htmlClass } = configOptions
  const htmlContent = _.get(contentGroup, 'body')
  // TODO RamonJulia 2020/02 tal with Fung(designer) to create
  // in the future more stylies to display diferent
  // types of text, as text left, text higlights, etc
  const Content = ({ classText }) => {
    if (display === 'richText' && !_.isEmpty(htmlContent)) {
      return (
        <div className={styles.row}>
          <article
            className={combineClassNames([styles.col, styles.richText, htmlClass])}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        </div>
      )
    }
    return (
      <p className={combineClassNames([classText, htmlClass])}>
        {name}
      </p>
    )
  }

  let classText = ''
  switch (display) {
    case 'title':
      classText = styles.title
      break;
    default:
      classText = styles.text
      break;
  }

  if (url) {
    return (
      <Link to={url} className={styles.link}>
        <Content classText={combineClassNames([classText, style])} />
      </Link>
    )
  }

  return <Content classText={combineClassNames([classText, style])} />
}
