/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import React, {
  useEffect,
  useMemo,
} from 'react'
import {
  useCart,
  useDineInCart,
  useStores,
} from 'react-omnitech-api'
import {
  useOrderMethod,
  useOrderMethodModal,
} from '../../hook'
import OrderMethodView from './order-method-view'

export default function OrderMethodController({
  onContinue,
}) {
  const { t } = useTranslation()
  const location = useLocation()
  const {
    orderMethod,
    deliveryAddress,
    selectableOrderMethod,
    store,
  } = useOrderMethod()
  const { stores } = useStores()
  const {
    open: openOrderMethodModal,
  } = useOrderMethodModal()
  const {
    initCart: initDineInCart,
  } = useDineInCart()
  const {
    initCart,
  } = useCart()

  // const [modalOpen, setModalOpen] = useState(false)
  const pickupStore = useMemo(() => (_.get(initCart, 'cartShipments.0.pickupStore', {})), [initCart])
  const storeCode = _.get(store, 'code')
  const storeName = useMemo(() => (
    // Use pickupStore name from cart shipment
    _.get(pickupStore, 'name')
    // Use translation if warehouse selected
    || (_.get(store, 'id') === 'warehouse' && t('ui.orderMethodModal.storeOptions', { context: 'warehouse' }))
    // fallback to use store name in localStorage
    || _.get(_.find(stores, { code: storeCode }), 'name')
    || t('ui.orderMethodModal.storeOptions')
  ), [pickupStore, store])
  const dineInCartMeta = useMemo(() => (_.get(initDineInCart, 'meta', {})), [initDineInCart])
  const orderMethodValue = useMemo(() => {
    const display = _.get(orderMethod, 'display', {})
    const dineInTableNumber = display.dineInTableNumber
      ? _.get(dineInCartMeta, 'dineInTableNumber', '')
      : ''
    const dineInTableSubNumber = display.dineInTableSubNumber
      ? _.get(dineInCartMeta, 'dineInTableSubNumber', '')
      : ''
    const table = `${dineInTableNumber}${dineInTableSubNumber}`
    const storeLabel = display.storeName ? storeName : ''
    if (_.get(orderMethod, 'selectStoreAppoach') === 'deliveryAddress') {
      return _.get(deliveryAddress, 'name')
              || _.join(_.get(deliveryAddress, 'formattedAddressLines', []), ', ')
    }
    return (
      !_.isEmpty(table) && _.get(orderMethod, 'code') === 'dineIn'
        ? t('ui.orderMethod.table', { table })
        : storeLabel
    )
  }, [deliveryAddress, dineInCartMeta, orderMethod, storeName, t])

  const onClick = () => {
    openOrderMethodModal({
      onContinue,
    })
  }

  useEffect(() => {
    if (
      _.has(location, 'state.preSelectedOrderMethod')
      && !_.get(location, 'state.isBlocker', true)
    ) {
      openOrderMethodModal()
    }
  }, [location.state])

  const viewProps = {
    onClick,
    orderMethod,
    orderMethodValue,
    selectableOrderMethod,
  }

  return (
    <OrderMethodView {...viewProps} />
  )
}
