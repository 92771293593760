import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerWithLabel: {
    fontSize: '0.6667em',
  },
  containerAlignLeft: {
    textAlign: 'left',
  },
  containerAlignRight: {
    textAlign: 'right',
  },
  containerAlignCenter: {
    textAlign: 'center',
  },
  label: {
    fontSize: 'max(0.6em, 1.1rem)',
    fallbacks: {
      fontSize: '0.6em',
    },
    lineHeight: 1.2,
    color: theme.colors.bgDark,
    margin: 0,
    textAlign: 'left',
  },
  price: {
    fontSize: '1em',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  originalPrice: {
    fontSize: 'max(0.5em, 1rem)',
    fallbacks: {
      fontSize: '0.5em',
    },
    fontWeight: 500,
    textDecoration: 'line-through',
    color: theme.colors.bgDark,
    marginRight: '0.2rem',
  },
  sellPriceOnSale: {
    color: theme.colors.alert,
  },
}))

export default useStyles
