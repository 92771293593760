// import _ from 'lodash'
import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSkus } from 'react-omnitech-api'
import RecentlyViewedProductsView from './recently-viewed-products-view'
import useAnalytics from '../../hook/use-analytics'
import useOrderMethod from '../../hook/use-order-method';
import useCart from '../../hook/use-cart';
import useSku from '../../hook/use-sku';

const RecentlyViewedProductsController = (props) => {
  const {
    excludedProducts = [], // array of product ids
  } = props
  const {
    fetchSkus,
  } = useSkus()
  const {
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()
  const { getProductParams, trackEvent } = useAnalytics()
  const { orderMethod, store } = useOrderMethod()
  const { inventoryStoreCode } = useCart()

  const [pageLoading, setPageLoading] = useState(false)
  const [initReady, setInitReady] = useState(false)
  const [skus, setSkus] = useState([])
  const [pagination, setPagination] = useState(null)
  const [siblings, setSiblings] = useState([])
  const [siblingsLoading, setSiblingsLoading] = useState(false)
  const [skusPerPage, setSkusPerPage] = useState(1)
  const [activeGroupIndex, setActiveGroupIndex] = useState(0)
  const [trackedSkuIds, setTrackedSkuIds] = useState([])
  const [clearCount, setClearCount] = useState(0)

  const next = useRef(null)

  const menuFiltersParam = getMetaMenuFilterParams()

  const commerceChannel = useMemo(() => _.get(orderMethod, 'commerceChannel'), [orderMethod])
  const cachedInventoryStoreCode = useMemo(() => {
    if (_.get(orderMethod, 'code', '') === 'dineInMenu') {
      return _.get(store, 'code')
    }
    return inventoryStoreCode
  }, [inventoryStoreCode, orderMethod, store])
  const data = useMemo(() => {
    try {
      return _.get(
        JSON.parse(window.localStorage.getItem('recentlyViewedProducts')),
        commerceChannel,
        [],
      )
    } catch (error) {
      // fail silently
    }
  }, [commerceChannel, clearCount])
  const productIds = useMemo(() => (
    _.difference(_.map(data, 'productId'), excludedProducts)
  ), [data])
  const siblingCodes = useMemo(() => (
    _.uniq(
      _.reject(
        _.compact(_.flatMap(skus, 'product.meta.siblingsProductCodes')),
        (code) => _.includes(_.map(siblings, 'product.code'), code),
      ),
    )
  ), [skus, siblings])

  useEffect(() => {
    const skusDisplayed = _.nth(_.chunk(skus, skusPerPage), activeGroupIndex)
    const skusToTrack = _.reject(skusDisplayed, ({ id }) => _.includes(trackedSkuIds, id))
    if (_.isEmpty(skusToTrack)) return
    const products = _.map(skusToTrack, (sku, skuIdx) => ({
      ...getProductParams(sku),
      list: 'Recently Reviewed',
      position: (_.size(trackedSkuIds) + skuIdx + 1),
    }))
    trackEvent('viewProductImpression', {}, { products })
    setTrackedSkuIds(
      (prev) => _.uniq([
        ...prev,
        ..._.map(skusToTrack, 'id'),
      ]),
    )
  }, [activeGroupIndex, skusPerPage, skus, trackedSkuIds])
  const onBreakpoint = (swiper) => {
    setSkusPerPage(_.get(swiper, 'params.slidesPerView', 1))
  }
  const onPageChange = (swiper) => {
    const activeIndex = _.get(swiper, 'isEnd', false)
      ? _.size(_.get(swiper, 'slides', [])) - 1
      : _.get(swiper, 'activeIndex', 0)
    setActiveGroupIndex(_.floor(activeIndex / skusPerPage))
  }
  function onClickTrackEvent(eventName, product) {
    trackEvent(eventName, {}, { product })
  }

  const onClear = () => {
    try {
      // clear all record in all commerce channels
      window.localStorage.removeItem('recentlyViewedProducts')
      setClearCount((prev) => prev + 1)
    } catch (error) {
      // fail silently
    }
  }

  /**
   * fetchSkusByIdApi
   * get sku by ids for non cached data
   */
  const fetchSkusByIdApi = useCallback(async () => {
    if (pageLoading) return
    if (initReady) return
    if (_.isEmpty(productIds)) return
    setPageLoading(true)
    try {
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      const option = {
        productIds,
        includes: [
          'color_option',
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
          'product',
          'products.stock_level',
          'products.category_ids',
          'products.brand_ids',
          'products.color_option_variant_type',
          'products.meta',
          'meta',
        ].join(','),
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        priceStoreCodeEq: cachedInventoryStoreCode,
        productTypes: 'product',
        pageSize: 8,
        pageCountless: true,
        distinct: 'p',
        sortBy: 'product_id',
        // menu filters
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }

      // call api
      const {
        skus: skusData,
        pagination: skusPagination,
        next: nextPageApi,
      } = await fetchSkus(option)
      // store data
      setSkus(skusData)
      setPagination(skusPagination)
      next.current = nextPageApi
    } catch (error) {
      // TODO: handle error
    } finally {
      setInitReady(true)
      setPageLoading(false)
    }
  }, [productIds, initReady])

  const onNextLoad = async () => {
    if (pageLoading) return
    if (_.isNil(next) && !_.isFunction(next.current)) return
    setPageLoading(true)
    try {
      // call api
      const {
        skus: skusData,
        pagination: skusPagination,
        next: nextPageApi,
      } = await next.current()
      setSkus((prevSkus) => ([
        ...prevSkus,
        ...skusData,
      ]))
      setPagination(skusPagination)
      next.current = nextPageApi
    } catch (error) {
      // TODO: handle error
    } finally {
      setPageLoading(false)
    }
  }

  const fetchSkusByProductCodeApi = useCallback(async (code) => {
    try {
      // api call option
      setSiblingsLoading(true)
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      const option = {
        productCodeEq: code,
        includes: [
          'skus.meta',
          'skus.product',
          'skus.price_details',
        ].join(','),
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        priceStoreCodeEq: cachedInventoryStoreCode,
        productTypes: 'product',
        pageSize: 999,
        pageCountless: true,
        // menu filters
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { skus: _siblings } = await fetchSkus(option)
      setSiblings((prevSiblings) => _.unionBy(_siblings, prevSiblings, 'id'))
    } catch (error) {
      // fail silently
    } finally {
      setSiblingsLoading(false)
    }
  }, [fetchSkus])

  useEffect(() => {
    fetchSkusByIdApi()
  }, [fetchSkusByIdApi])

  useEffect(() => {
    if (!_.isEmpty(siblingCodes)) {
      fetchSkusByProductCodeApi(siblingCodes)
    }
  }, [siblingCodes])

  const viewProps = {
    onAfterInit: onBreakpoint,
    onBreakpoint,
    onClear,
    onClickTrackEvent,
    onNextLoad,
    onPageChange,
    skus,
    siblings,
    siblingsLoading,
    pagination,
    ...props,
  }

  if (_.isEmpty(productIds)) return null

  return (
    <RecentlyViewedProductsView
      {...viewProps}
    />
  )
}

export default RecentlyViewedProductsController
