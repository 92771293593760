import _ from 'lodash'
import { createUseStyles } from 'react-jss'
import IconArrow from '../../../../assets/icons/icon_solid_arrow.svg'

const useStyles = createUseStyles(() => ({
  container: ({ node }) => ({
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: '-5px',
    height: '24px',
    width: '24px',
    transform: _.get(node, 'toggled') ? 'rotate(90deg)' : 'rotate(0)',
  }),
  iconStyle: {
    backgroundImage: `url(${IconArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    backgroundSize: '0.75rem',
    position: 'absolute',
    width: '1rem',
    height: '1rem',
    right: '2rem',
    top: '50%',
    left: '50%',
    zIndex: '10',
    transform: 'translate(-50% , -50%)',
  },
}))

export default useStyles
