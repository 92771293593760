import _ from 'lodash'

export default function getNestedAddonSkus(pas = []) {
  const arr = _.uniqBy(
    _.flatMapDeep(
      pas,
      ({ addons }) => (
        _.map(addons, ({ skus, productAddons: _productAddons }) => ([
          ...skus,
          ...(
            _.isEmpty(_productAddons) ? [] : getNestedAddonSkus(_productAddons)
          ),
        ]))
      ),
    ),
    'id',
  )
  return arr
}
