import _ from 'lodash'
import t from './t'

function moveOthersToLast(obj, othersOptionKey) {
  const postionOthers = _.findIndex(obj, ['id', othersOptionKey])
  if (obj.length === postionOthers + 1 || postionOthers === -1) return obj
  const objOrdered = obj.filter((d) => d.id !== othersOptionKey);
  objOrdered.push({
    ...obj[postionOthers],
    label: t('ui.filters.others'),
  })

  return objOrdered
}

function groupBrands(brands) {
  const collection = {}
  for (let i = 0; i < brands.length; i += 1) {
    const firstLetter = brands[i].label.charAt(0);
    if (collection[firstLetter] === undefined) {
      collection[firstLetter] = [];
    }
    collection[firstLetter].push(brands[i]);
  }
  if (_.keys(collection).length > 1) {
    return Object.keys(collection).map((key) => ({
      id: key,
      label: key,
      options: collection[key],
    }))
  }
  return brands
}

function groupColorOptions(colors, othersOptionKey) {
  const collection = {}
  for (let i = 0; i < colors.length; i += 1) {
    const { subType } = colors[i];
    if (collection[subType] === undefined) {
      collection[subType] = [];
    }
    collection[subType].push(colors[i]);
  }
  if (_.keys(collection).length > 0) {
    const colorsObj = Object.keys(collection).map((key) => ({
      id: key,
      label: t(`screens.products.filter.colorOption.subType.${_.camelCase(key)}`, { defaultValue: othersOptionKey }),
      options: collection[key],
    }))
    return moveOthersToLast(colorsObj, othersOptionKey)
  }
  return colors
}

function groupSizeOptions(sizes, othersOptionKey) {
  const collection = {}
  for (let i = 0; i < sizes.length; i += 1) {
    const { subType } = sizes[i];
    if (collection[subType] === undefined) {
      collection[subType] = [];
    }
    collection[subType].push(sizes[i]);
  }
  if (_.keys(collection).length > 0) {
    const sizeObj = Object.keys(collection).map((key) => ({
      id: key,
      label: t(`screens.products.filter.sizeOption.subType.${_.camelCase(key)}`),
      options: collection[key],
    }))
    return moveOthersToLast(sizeObj, othersOptionKey)
  }
  return sizes
}

function transformFilterBrands(brands) {
  const objBrands = brands.map((brand) => ({
    logo: brand.logo,
    value: brand.code,
    code: brand.code,
    label: brand.name,
    name: brand.name,
    id: brand.id,
    details: brand.details,
  }))

  return groupBrands(objBrands)
}

function transformFilterColorOptions(colors, othersOptionKey = 'others') {
  const objColors = colors.map((color) => ({
    value: color.code,
    label: color.name,
    id: color.id,
    hex_value: color.hexValue,
    is_active: color.isActive,
    image: color.image,
    subType: color.subType ? color.subType : othersOptionKey,
  }))
  return groupColorOptions(objColors, othersOptionKey)
}

function transformFilterCategories(categories) {
  return _.map(categories, (category) => ({
    value: category.code,
    label: category.name,
    id: category.id,
  }))
  // return groupColorOptions(objColors, othersOptionKey)
}

function transformFilterPageSize(pageSizes = []) {
  const objSize = pageSizes.map((size) => ({
    value: size,
    label: size,
  }))
  return objSize
}

function transformFilterSizeOptions(sizes, othersOptionKey = 'others') {
  const objSize = sizes.map((size) => ({
    value: size.code,
    code: size.code,
    label: size.name,
    name: size.name,
    id: size.id,
    subType: size.subType ? size.subType : othersOptionKey,
  }))
  return groupSizeOptions(objSize, othersOptionKey)
}

function transformFilterSortBy(values, localeCode) {
  const objSortBy = values.map((value, index) => ({
    key: index,
    label: value.name[_.camelCase(localeCode)],
    value: value.query.sortBy,
  }))
  return objSortBy
}

export {
  transformFilterBrands,
  transformFilterCategories,
  transformFilterColorOptions,
  transformFilterPageSize,
  transformFilterSizeOptions,
  transformFilterSortBy,
}
