import _ from 'lodash';
import React from 'react'
import { combineClassNames } from '../../../helpers'
import ProductGroup from '../../product-group'

function TemplateProducGroup(props) {
  const { code, name } = props

  return (
    <ProductGroup
      code={code}
      title={name}
      className={combineClassNames([_.get(props, 'configOptions.className')])}
    />
  )
}

export default TemplateProducGroup
