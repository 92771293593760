/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import {
  useAttachment,
} from 'react-omnitech-api'
import { useAlert, useThemeConfig } from '../../hook'
import {
  fileHash,
  fileUrl,
} from '../../helpers'
import ProfileCardView from './profile-card-view'

function ProfileCardController(props) {
  const { createAttachment, createS3DirectUploadImage, updateAttachment } = useAttachment()
  const alert = useAlert()
  const { getConfig } = useThemeConfig()
  const hideLoyaltyPoints = getConfig('config.hideLoyaltyPoints', false)

  // Hooks
  const [uploadAvatarInProgress, setUploadAvatarInProgress] = useState(false)
  const [uploadFileCompleted, setUploadFileCompleted] = useState(false)
  const [hash, setHash] = useState(undefined)
  const [storeFile, setStoreFile] = useState(undefined)
  const [storeAttachment, setStoreAttachment] = useState(undefined)
  const [avatarPreview, setAvatarPreview] = useState(undefined)
  const [prevAvatarPreview, setPrevAvatarPreview] = useState(undefined)

  const resetState = () => {
    setUploadAvatarInProgress(false)
    setHash(undefined)
    setStoreFile(undefined)
    setStoreAttachment(undefined)
    setAvatarPreview(prevAvatarPreview)
  }

  const handleError = (errors) => {
    const error = _.get(errors, 'validationError.data.attachment.errors.checksum.message.0')
        || _.get(errors, 'validationError.data.attachment.errors.checksum.fullMessage.0')
        || _.get(errors, 'validationError.message')
        || _.get(errors, 'generalError.message')
        || errors
    resetState()
    alert.show(error)
  }

  /**
   * upLoadFile
   * @param {*} evt, event contain value from input image
   *
   */
  const onEditAvatar = async (e) => {
    setUploadAvatarInProgress(true)
    if (window.FileReader) {
      const file = e.target.files[0]
      setStoreFile(file)
      setPrevAvatarPreview(avatarPreview)
      const hasher = await fileHash(file)
      const previewUrl = await fileUrl(file)
      setHash(hasher)
      setAvatarPreview(previewUrl)
    }
  }

  /**
   * Handle Create Attachment Image
   * @param {*} values, object contain all value from input
   *
   */

  const handleAttachment = useCallback(async () => {
    if (!storeFile || !hash) return
    const params = {
      attachmentableNamespace: 'avatar_image',
      attachmentableType: 'User',
      checksum: hash,
      contentType: storeFile.type,
      filename: storeFile.name,
    }
    try {
      const { attachment } = await createAttachment(params)
      setStoreAttachment(attachment)
    } catch (error) {
      handleError(error)
    }
  }, [storeFile, hash])

  useEffect(() => {
    handleAttachment()
  }, [storeFile, hash])

  useEffect(() => {
    const id = _.get(storeAttachment, 'id')
    if (id) {
      if (uploadFileCompleted) {
        updateAttachment(id)
          .then((result) => console.log('after fecth Attachment', result))
          .catch(handleError)
          .finally(() => {
            setUploadAvatarInProgress(false)
          })
      } else {
        // Upload Image
        createS3DirectUploadImage(storeAttachment, storeFile)
          .then(() => {
            setUploadFileCompleted(true)
          })
          .catch(handleError)
      }
    }
  }, [uploadFileCompleted, storeAttachment])

  const viewProps = {
    uploadAvatarInProgress,
    onEditAvatar,
    avatarPreview,
    hideLoyaltyPoints,
    ...props,
  }

  return <ProfileCardView {...viewProps} />
}

export default ProfileCardController
