/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import {
  useBrands,
  useCategories,
  useDepartments,
  useSystemSettings,
} from 'react-omnitech-api'
import {
  trackEventHook,
  trackEventGa,
  trackEventTenmax,
  trackEventFbp,
  parseStockLevel,
} from '../../helpers'

export default function useAnalytics() {
  const { getSystemSetting } = useSystemSettings()
  const { departments } = useDepartments()
  const { categories } = useCategories()
  const { brands } = useBrands()

  function trackEvent(eventName, options = {}, meta) {
    if (_.isEmpty(eventName)) {
      console.warn('trackEvent error: ', 'event name empty.')
      return
    }

    trackEventHook(eventName, options, meta)

    if (getSystemSetting('analytics.google.enable') === true) {
      trackEventGa(eventName, options, meta)
    }
    if (getSystemSetting('analytics.tenmax.enable')) {
      trackEventTenmax(eventName, options, meta)
    }
    if (getSystemSetting('analytics.facebook.enable')) {
      trackEventFbp(eventName, options, meta)
    }
  }

  function getProductParams(sku) {
    const categoryIds = _.get(sku, 'product.categoryIds', [])
    const brandIds = _.get(sku, 'product.brandIds', [])
    const category = _.join(
      _.compact(
        _.flatMap(categoryIds, (id) => (
          _.compact([
            _.get(
              _.find(
                departments,
                { id: _.get(_.find(categories, { id }), 'departmentId') },
              ),
              'name',
            ),
            _.get(_.find(categories, { id }), 'name'),
          ])
        )),
      ),
      ', ',
    )
    const brand = _.join(
      _.compact(
        _.map(brandIds, (id) => (
          _.get(_.find(brands, { id }), 'name')
        )),
      ),
      ', ',
    )
    const price = _.get(
      sku,
      'sellPrice',
      _.get(
        sku,
        'colorOption.sellPrice',
        _.get(sku, 'product.sellPrice'),
      ),
    )
    return {
      code: _.get(sku, 'product.code'),
      name: _.get(sku, 'product.title'),
      category,
      brand,
      variant: _.get(sku, 'colorOption.name'),
      url: _.get(sku, 'product.canonicalHref'),
      description: _.get(sku, 'product.detailsPlainText'),
      image: _.get(sku, 'colorOption.images.0.versions.webLarge')
        || _.get(sku, 'colorOption.defaultImage.versions.webLarge'),
      price,
      stockLevel: parseStockLevel(_.get(sku, 'colorOption.stockLevel', _.get(sku, 'product.stockLevel'))),
    }
  }

  return {
    trackEvent,
    getProductParams,
  }
}
