/* eslint-disable no-unused-vars */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSystemSettings } from 'react-omnitech-api'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
// import { setParamsToTrack } from '../../helpers'
import { useI18n } from '../../hook'

function SEO({
  author,
  description,
  lang,
  meta = [],
  img,
  link = [],
  title,
  titleTemplate,
  linkedData = [],
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            version
            googleSiteVerification
          }
        }
      }
    `,
  )
  const { getSystemSetting } = useSystemSettings()
  const themeConfig = getSystemSetting('theme.ecom.config', {})
  const htmlMetaTitle = getSystemSetting('html.meta.title', site.siteMetadata.title)
  const htmlMetaDescription = getSystemSetting('html.meta.description', site.siteMetadata.description)
  const siteName = getSystemSetting('frontend.site_name')
  const favIcon = _.get(themeConfig, 'config.favicon', '')
  const metaDescription = description || htmlMetaDescription
  const { currentLocale } = useI18n()
  const seoTitle = title || htmlMetaTitle
  const seoTitleTemplate = titleTemplate || (title
    ? `%s | ${siteName}`
    : '%s')
  const seoAuthor = author || site.siteMetadata.author || siteName
  // filter meta by only those with content that is not empty
  const cleanMeta = _.filter(meta, 'content')

  // meta tags using name attribute
  const nameMeta = _.uniqBy(_.filter(cleanMeta, 'name').concat(_.compact([
    {
      name: 'description',
      content: metaDescription,
    },
    {
      name: 'appVersion',
      content: site.siteMetadata.version,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: seoAuthor,
    },
    {
      name: 'twitter:title',
      content: seoTitle,
    },
    {
      name: 'twitter:image',
      content: img,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
    // add google-site-verification if exist in config
    !_.isEmpty(site.siteMetadata.googleSiteVerification) ? {
      name: 'google-site-verification',
      content: site.siteMetadata.googleSiteVerification,
    } : undefined,
  ])), 'name')

  // meta tags using property attribute
  const propertyMeta = _.uniqBy(_.filter(cleanMeta, 'property').concat(_.compact([
    {
      property: 'og:title',
      content: seoTitle,
    },
    {
      property: 'og:site_name',
      content: siteName,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: img,
    },
  ])), 'property')

  // merge meta tags
  const mergedMeta = [...nameMeta, ...propertyMeta]

  // merge link tags
  const mergedLink = _.uniqBy(link.concat(_.compact([
    !_.isEmpty(favIcon) ? {
      rel: 'icon',
      type: 'image/x-icon',
      href: favIcon,
    } : undefined,
  ])), 'rel')

  const lds = _.map(linkedData, (ld) => ({
    type: 'application/ld+json',
    content: JSON.stringify(ld),
  }))

  const mergedScripts = _.concat(lds)

  return (
    <Helmet
      htmlAttributes={{
        lang: currentLocale.locale,
      }}
      title={seoTitle}
      titleTemplate={seoTitleTemplate}
      meta={mergedMeta}
      link={mergedLink}
    >
      {/* inline script elements */}
      {_.map(mergedScripts, (scpt) => <script type={scpt.type}>{scpt.content}</script>)}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en-HK',
  meta: [],
  description: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
