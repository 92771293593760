// import _ from 'lodash'
import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  cancelRequest,
  useRecommendations,
  useSkus,
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import RecommendationProductsView from './recommendation-products-view'
import useAnalytics from '../../hook/use-analytics'
import useOrderMethod from '../../hook/use-order-method';
import useCart from '../../hook/use-cart';
import useSku from '../../hook/use-sku';

const RecommendationProductsController = (props) => {
  const {
    products,
    colorOptions,
    skus,
    trackEventOptions = {},
  } = props
  const {
    fetchSkus,
  } = useSkus()
  const { fetchRecommendations } = useRecommendations()
  const {
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()
  const { getProductParams, trackEvent } = useAnalytics()
  const { orderMethod, store } = useOrderMethod()
  const { inventoryStoreCode } = useCart()
  const {
    loyaltyInformation,
  } = useUser()
  const { getSystemSetting } = useSystemSettings()

  const [pageLoading, setPageLoading] = useState(false)
  const [initReady, setInitReady] = useState(false)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState(null)
  const [siblings, setSiblings] = useState([])
  const [siblingsLoading, setSiblingsLoading] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(1)
  const [activeGroupIndex, setActiveGroupIndex] = useState(0)
  const [trackedItemIds, setTrackedItemIds] = useState([])

  const next = useRef(null)
  const menuFiltersParam = getMetaMenuFilterParams()
  // const commerceChannel = useMemo(() => _.get(orderMethod, 'commerceChannel'), [orderMethod])
  const recommendationsEndPoint = getSystemSetting('frontend.product_detail.recommendations.model.ecom') || 'color_options'
  const recommendationsQuery = getSystemSetting(`api.v2.recommendations.${recommendationsEndPoint}.product_detail.ecom.query`)
  const currentRank = _.get(loyaltyInformation, 'currentCustomerRank.code', '')

  const cachedInventoryStoreCode = useMemo(() => {
    if (_.get(orderMethod, 'code', '') === 'dineInMenu') {
      return _.get(store, 'code')
    }
    return inventoryStoreCode
  }, [inventoryStoreCode, orderMethod, store])
  const siblingCodes = useMemo(() => {
    let siblingsProductCodes = []
    switch (recommendationsEndPoint) {
      case 'products':
        siblingsProductCodes = _.flatMap(items, 'meta.siblingsProductCodes')
        break;
      default:
        siblingsProductCodes = _.flatMap(items, 'product.meta.siblingsProductCodes')
        break;
    }
    return _.uniq(
      _.reject(
        _.compact(siblingsProductCodes),
        (code) => _.includes(_.map(siblings, 'product.code'), code),
      ),
    )
  }, [items, siblings])

  useEffect(() => {
    const itemsDisplayed = _.nth(_.chunk(items, itemsPerPage), activeGroupIndex)
    const itemsToTrack = _.reject(itemsDisplayed, ({ id }) => _.includes(trackedItemIds, id))
    if (_.isEmpty(itemsToTrack)) return
    const data = _.map(itemsToTrack, (item, skuIdx) => ({
      ...getProductParams(_.get(item, 'sku', item)),
      list: 'Related Products',
      position: (_.size(trackedItemIds) + skuIdx + 1),
      ...trackEventOptions,
    }))
    trackEvent('viewProductImpression', {}, { products: data })
    setTrackedItemIds(
      (prev) => _.uniq([
        ...prev,
        ..._.map(itemsToTrack, 'id'),
      ]),
    )
  }, [activeGroupIndex, itemsPerPage, items, trackedItemIds])
  const onBreakpoint = (swiper) => {
    setItemsPerPage(_.get(swiper, 'params.slidesPerView', 1))
  }
  const onPageChange = (swiper) => {
    const activeIndex = _.get(swiper, 'isEnd', false)
      ? _.size(_.get(swiper, 'slides', [])) - 1
      : _.get(swiper, 'activeIndex', 0)
    setActiveGroupIndex(_.floor(activeIndex / itemsPerPage))
  }
  function onClickTrackEvent(eventName, product) {
    trackEvent(eventName, {}, { product })
  }

  function getRecommendationData(item, index) {
    if (!_.has(item, 'product')) {
      // product level
      const colorOptionId = _.get(item, 'defaultColorOption.id')
      const defaultSku = _.find(_.get(item, 'skus', []), { colorOptionId })
      return {
        id: _.get(item, 'id'), // for trackedItemIds
        activeCustomLabels: _.get(item, 'defaultColorOption.activeCustomLabels'),
        colorOptionId,
        colorOptionVariantType: _.get(item, 'colorOptionVariantType'),
        colorName: _.get(item, 'defaultColorOption.name'),
        productId: _.get(item, 'id'),
        imageUrl: _.get(item, 'defaultColorOption.images.0.versions.webLarge')
          || _.get(item, 'defaultColorOption.defaultImage.versions.webLarge'),
        url: _.get(item, 'canonicalHref'),
        siblingsProductCodes: _.get(item, 'meta.siblingsProductCodes', []),
        memberPrice: _.get(defaultSku, `meta.memberPrice.${currentRank}`),
        favourite: _.get(item, 'defaultColorOption.favourite'),
        sku: {
          ...defaultSku,
          colorOption: _.get(item, 'defaultColorOption', {}),
          product: item,
        },
        ..._.pick(item, [
          'title',
          'sellPrice',
          'originalPrice',
          'stockLevel',
        ]),
      }
    }
    if (!_.has(item, 'colorOption')) {
      // colorOption level
      const colorOptionId = _.get(item, 'id')
      const defaultSku = _.find(_.get(item, 'skus', []), { colorOptionId })
      return {
        id: _.get(item, 'id'), // for trackedItemIds
        activeCustomLabels: _.get(item, 'activeCustomLabels'),
        productId: _.get(item, 'product.id'),
        imageUrl: _.get(item, 'images.0.versions.webLarge')
          || _.get(item, 'defaultImage.versions.webLarge'),
        title: _.get(item, 'product.title'),
        colorOptionId,
        colorOptionVariantType: _.get(item, 'product.colorOptionVariantType'),
        colorName: _.get(item, 'name'),
        url: _.get(item, 'product.canonicalHref'),
        siblingsProductCodes: _.get(item, 'product.meta.siblingsProductCodes', []),
        memberPrice: _.get(defaultSku, `meta.memberPrice.${currentRank}`),
        sku: {
          ...defaultSku,
          product: _.get(item, 'product', {}),
          colorOption: item,
        },
        ..._.pick(item, [
          'sellPrice',
          'originalPrice',
          'favourite',
          'stockLevel',
        ]),
        trackEventParams: {
          ...getProductParams({
            product: _.get(item, 'product'),
            colorOption: item,
          }),
          list: 'Related Products',
          position: index + 1,
          ...trackEventOptions,
        },
      }
    }
    return {
      // sku level
      id: _.get(item, 'id'), // for trackedItemIds
      activeCustomLabels: _.get(item, 'colorOption.activeCustomLabels'),
      productId: _.get(item, 'product.id'),
      imageUrl: _.get(item, 'colorOption.images.0.versions.galleryMedium'),
      title: _.get(item, 'product.title'),
      colorOptionId: _.get(item, 'colorOption.id'),
      colorOptionVariantType: _.get(item, 'product.colorOptionVariantType'),
      colorName: _.get(item, 'colorOption.name'),
      url: _.get(item, 'product.canonicalHref'),
      siblingsProductCodes: _.get(item, 'product.meta.siblingsProductCodes', []),
      memberPrice: _.get(item, `meta.memberPrice.${currentRank}`),
      favourite: _.get(item, 'colorOption.favourite'),
      sku: item,
      ..._.pick(item, [
        'sellPrice',
        'originalPrice',
        'stockLevel',
      ]),
      trackEventParams: {
        ...getProductParams(item),
        list: 'Related Products',
        position: index + 1,
        ...trackEventOptions,
      },
    }
  }

  /**
   * fetchReviewsApi
   * get reviews data from API
   */
  const fetchRecommendationsApi = useCallback(async () => {
    if (pageLoading) return
    if (initReady) return
    let optionsByObject = {}
    const object = recommendationsEndPoint
    const sharedIncludes = [
      'price_details',
      'stock_level',
      // 'products.category_ids',
      // 'products.brand_ids',
      'color_options.favourite',
      'color_options.active_custom_labels',
    ]
    switch (recommendationsEndPoint) {
      case 'products':
        optionsByObject = {
          items: _.join(_.castArray(products), ','),
          includes: [
            'brand_ids',
            'category_ids',
            'default_color_option',
            'color_option_variant_type',
            'skus',
            'skus.color_option_id',
            'skus.meta',
            'meta',
            ...sharedIncludes,
          ],
        }
        break;
      case 'skus':
        optionsByObject = {
          items: _.join(_.castArray(skus), ','),
          includes: [
            'product',
            'color_option',
            'meta',
            // 'color_options.images',
            'products.brand_ids',
            'products.category_ids',
            'products.color_option_variant_type',
            'products.meta',
            ...sharedIncludes,
          ],
        }
        break;
      default:
        // use selectedColorOptionId and fallback to defaultColorOptionId
        optionsByObject = {
          items: _.join(_.castArray(colorOptions), ','),
          includes: [
            'product',
            'products.brand_ids',
            'products.category_ids',
            'products.color_option_variant_type',
            'products.meta',
            'skus',
            'skus.meta',
            // 'images',
            ...sharedIncludes,
          ],
        }
        break;
    }
    // FL: prevent fetch api if not related items
    if (_.isEmpty(_.compact(_.castArray(_.get(optionsByObject, 'items'))))) return
    try {
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      // api call option
      const option = {
        object,
        ...optionsByObject,
        ...recommendationsQuery,
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        priceStoreCodeEq: cachedInventoryStoreCode,
        pageSize: 8,
        pageCountless: true,
        // menu filters
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const {
        [_.camelCase(object)]: data,
        pagination: paginationData,
        next: nextApi,
      } = await fetchRecommendations(option)
      setItems(_.map(data, getRecommendationData))
      setPagination(paginationData)
      next.current = nextApi
    } catch (error) {
      // const generalError = _.get(error, 'generalError', {})
      // TODO: send to rollbar
      // do nothing
      // alert.show(generalError.message)
    } finally {
      setInitReady(true)
      setPageLoading(false)
    }
  }, [fetchRecommendations, skus, colorOptions, products])

  const onNextLoad = async () => {
    if (pageLoading) return
    if (_.isNil(next) && !_.isFunction(next.current)) return
    setPageLoading(true)
    const object = recommendationsEndPoint
    try {
      // call api
      const {
        [_.camelCase(object)]: data,
        pagination: paginationData,
        next: nextApi,
      } = await next.current()
      setItems((prevItems) => ([
        ...prevItems,
        ..._.map(data, getRecommendationData),
      ]))
      setPagination(paginationData)
      next.current = nextApi
    } catch (error) {
      // TODO: handle error
    } finally {
      setPageLoading(false)
    }
  }

  const fetchSkusByProductCodeApi = useCallback(async (code) => {
    try {
      // api call option
      setSiblingsLoading(true)
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      const option = {
        productCodeEq: code,
        includes: [
          'skus.meta',
          'skus.product',
          'skus.price_details',
        ].join(','),
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        priceStoreCodeEq: cachedInventoryStoreCode,
        productTypes: 'product',
        pageSize: 999,
        pageCountless: true,
        // menu filters
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { skus: _siblings } = await fetchSkus(option)
      setSiblings((prevSiblings) => _.unionBy(_siblings, prevSiblings, 'id'))
    } catch (error) {
      // fail silently
    } finally {
      setSiblingsLoading(false)
    }
  }, [fetchSkus])

  /**
   * load Recommendations
   */
  useEffect(() => {
    fetchRecommendationsApi()

    return function fetchRecommendationsCleanUp() {
      cancelRequest.cancelAll([
        'fetchRecommendations',
      ])
    }
  }, [fetchRecommendationsApi])

  useEffect(() => {
    if (!_.isEmpty(siblingCodes)) {
      fetchSkusByProductCodeApi(siblingCodes)
    }
  }, [siblingCodes])

  const viewProps = {
    onAfterInit: onBreakpoint,
    onBreakpoint,
    onClickTrackEvent,
    onNextLoad,
    onPageChange,
    items,
    recommendationsEndPoint,
    siblings,
    siblingsLoading,
    pagination,
    ...props,
  }

  if (_.isEmpty(items)) return null

  return (
    <RecommendationProductsView
      {...viewProps}
    />
  )
}

export default RecommendationProductsController
