/* eslint-disable react/no-danger */
import React from 'react'
import _ from 'lodash'
import QRCode from 'qrcode.react'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSystemSettings } from 'react-omnitech-api'
import useStyles from './coupon-marketplace-coupon-info-style'
import { combineClassNames } from '../../../../helpers'
import { useThemeConfig } from '../../../../hook'
import Button from '../../../button'
import Clock from '../../../clock'
import CouponStateLabel from '../../../coupon-state-label'
import IconTime from '../../../../assets/icons/icon_time.svg'
import IconGift from '../../../../assets/icons/icon_gift.svg'
import IconLocked from '../../../../assets/icons/icon_locked.inline.svg'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'

const TransferSection = (props) => {
  const {
    loading,
    state,
    onResend,
    onTransferIn,
    onTransferOut,
    onTransferUndo,
  } = props
  const { t } = useTranslation()
  const styles = useStyles({})
  if (state === 'completed') return null
  const title = t('ui.minicartCoupons.couponDetail.transferSection.title', { context: _.camelCase(state) }) // transferable
  const subtitle = t('ui.minicartCoupons.couponDetail.transferSection.subtitle', { context: _.camelCase(state) })
  const description = t('ui.minicartCoupons.couponDetail.transferSection.description', { context: _.camelCase(state) })
  const buttonLabel = t('ui.minicartCoupons.couponDetail.transferSection.buttons', { context: _.camelCase(state) })
  const handleButtonClick = () => {
    switch (state) {
      case 'transferable':
        onTransferOut()
        break;
      case 'transfer_in_progress':
        onTransferUndo()
        break;
      case 'transfer_in':
        onTransferIn()
        break;
      default:
        // do nothing
        break;
    }
  }
  return (
    <div className={styles.transferSection}>
      {
        !_.isEmpty(title) && (
          <h3 className={styles.transferSectionTitle}>{title}</h3>
        )
      }
      {
        !_.isEmpty(subtitle) && (
          <h4 className={styles.transferSectionSubtitle}>{subtitle}</h4>
        )
      }
      <div className={styles.transferSectionVisual}>
        <img src={IconGift} className={styles.transferSectionVisualIcon} alt="transfer" />
      </div>
      {
        !_.isEmpty(description) && (
          <p className={styles.transferSectionDescription}>{description}</p>
        )
      }
      <div className={styles.transferSectionButtons}>
        <Button
          dark
          className={styles.transferSectionButton}
          onClick={handleButtonClick}
          text={buttonLabel}
          loading={loading}
          disabled={loading || _.camelCase(state) === 'nonTransferable'}
        />
        {
          state === 'transfer_in_progress' && (
            <Button
              className={
                combineClassNames([
                  styles.transferSectionLink,
                  styles.transferSectionLinkResend,
                ])
              }
              inline
              onClick={onResend}
              text={t('ui.minicartCoupons.couponDetail.transferSection.buttons', { context: 'resend' })}
            />
          )
        }
      </div>
    </div>
  )
}

const CouponMarketplaceCouponInfoView = ({
  canSelfUse,
  canActions,
  computedGracePeriodExpiresAt,
  coupon = {},
  couponUsage = {},
  endAt: _endAt,
  usageLeft,
  hideValidPeriodDates = false,
  isRedeem = false,
  isPublic = false,
  isValid,
  validPeriod = false,
  showClock = false,
  showQrCodeMask = false,
  showSecretQrCode,
  showStatusLabel = false,
  qrCodeValue,
  secretQrCodeValue,
  startAt: _startAt,
  status,
  statusLabel,
  transferState,
  transferLoading,
  onRequireCouponSecret,
  onShare,
  onTransferIn,
  onTransferOut,
  onTransferUndo,
}) => {
  // prepare hook
  const { t } = useTranslation()
  const { getSystemSetting } = useSystemSettings()
  const { getConfig } = useThemeConfig()
  // local variables
  const siteName = getSystemSetting('frontend.site_name')
  const placeholder = getConfig('config.placeholderImage', placeholderImage)
  const hideLoyaltyPoints = getConfig('config.hideLoyaltyPoints', false)
  const enableQrCodeClock = getConfig('config.ui.minicartCoupons.couponDetail.qrcode.enableClock', false)
  const imageUrl = _.get(coupon, 'defaultImage.versions.webLarge')
    || _.get(coupon, 'defaultImage.versions.galleryLarge')
    || _.get(coupon, 'images.0.versions.galleryLarge')
  const noImage = _.isEmpty(imageUrl)
  const couponImageUrl = imageUrl || placeholder
  const startAt = _startAt || _.get(coupon, 'startAt')
  const endAt = computedGracePeriodExpiresAt || _endAt || _.get(coupon, 'endAt')
  const usedAt = _.get(couponUsage, 'createdAt')
  // const terminatedAt = _.get(couponUsage, 'terminatedAt')

  // const requiredManualTrigger = _.get(coupon, 'cartDiscount.requiredManualTrigger', false)
  const isTransferable = (
    _.isArray(canActions)
      ? !_.isEmpty(
        _.intersection(
          canActions,
          ['transfer_out', 'transfer_in', 'transfer_undo'],
        ),
      )
      : _.get(coupon, 'isTransferable', false)
  ) && transferState !== 'completed'
  // const isTransferable = _.get(coupon, 'isTransferable', false) && transferState !== 'completed'
  const isTransferInProgress = transferState === 'transfer_in_progress'
  const isExpired = moment().isAfter(endAt)
  const isUsage = !_.isEmpty(couponUsage)

  const {
    name: title,
    redeemableRequiredLoyaltyPoints = '',
    description,
  } = coupon || {}

  const styles = useStyles({ status, isPublic })
  return (
    <div className={styles.containerCart}>

      {/* Coupon Image */}
      <figure className={combineClassNames([styles.photo, noImage && styles.noImage])}>
        <div>
          <img src={couponImageUrl} alt={title} />
        </div>
        <CouponStateLabel
          {...{
            isExpired,
            isTransferable,
            isTransferInProgress,
          }}
        />
      </figure>

      <div>
        <div className={styles.info}>
          {/* Coupon Name */}
          <h4>{title}</h4>

          {
            isUsage ? (
              _.get(couponUsage, 'state') === 'active' && (
                <>
                  <div className={styles.validPeriod}>
                    {t('ui.minicartCoupons.couponInfo.usedAt')}
                  </div>
                  <div className={styles.dateStyle}>
                    <img className={styles.dateIcon} src={IconTime} alt="icon time" />
                    <span>
                      {moment(usedAt).format('DD.MM.YYYY HH:mm')}
                    </span>
                  </div>
                </>
              )
            ) : (
              <>
                {/* Period */}
                {
                  validPeriod && (
                    <div className={styles.validPeriod}>
                      <Trans i18nKey="ui.minicartCoupons.couponInfo.validPPeriod" />
                    </div>
                  )
                }
                {
                  !hideValidPeriodDates && (
                    <div className={styles.dateStyle}>
                      <img className={styles.dateIcon} src={IconTime} alt="icon time" />
                      <span>
                        {moment(startAt).format('DD.MM.YYYY')}
                        {' '}
                        -
                        {' '}
                        {moment(endAt).format('DD.MM.YYYY')}
                      </span>
                    </div>
                  )
                }
                {
                  usageLeft > 1 && (
                    <p className={styles.infoUsageLeft}>
                      {t('ui.minicartCoupons.couponDetail.usageLeft', { count: usageLeft })}
                    </p>
                  )
                }
              </>
            )
          }
          {
            showStatusLabel && (
              <div
                className={
                  combineClassNames([
                    styles.statusLabel,
                    status === 'usable' && styles.statusLabelUsable,
                  ])
                }
              >
                {statusLabel}
              </div>
            )
          }
        </div>

        {/* Coupon required points for redeem */}
        {
          isRedeem
          && !hideLoyaltyPoints
          && (
            <div className={styles.pointsBox}>
              <p>
                <Trans i18nKey="ui.minicartCoupons.couponDetail.redeemFor" />
              </p>
              <p>
                <Trans
                  i18nKey="ui.minicartCoupons.couponDetail.redeemableRequiredLoyaltyPoints"
                  values={{
                    points: redeemableRequiredLoyaltyPoints,
                  }}
                >
                  {{ points: redeemableRequiredLoyaltyPoints }}
                  <span>pts</span>
                </Trans>
              </p>
            </div>
          )
        }

        {/* Coupon description */}
        <div
          className={styles.conditions}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {
          showClock && (
            <div className={styles.clock}>
              <Clock active />
            </div>
          )
        }
        {/* Coupon QR */}
        {
          (!_.includes(['transfer_in'], transferState)
          && (isValid || showSecretQrCode)
          && !isUsage)
            ? (
              <div className={styles.qrCouponStyle}>
                {
                  showQrCodeMask ? (
                    <>
                      <div className={styles.qrCodeMaskContainer}>
                        <div className={styles.qrCodeMask}>
                          <QRCode
                            value={siteName}
                            size={168}
                            className={styles.qrCodeMaskFakeCode}
                          />
                          <IconLocked className={styles.qrCodeMaskLockIcon} />
                        </div>
                      </div>
                      <p className={styles.qrInfo}>
                        {t('ui.minicartCoupons.couponDetail.qrCodeMask.reminder', {
                          context: isTransferable ? 'transferable' : '',
                        })}
                      </p>
                      <div className={styles.qrCodeMaskButton}>
                        <Button
                          dark
                          className={styles.buttonActivate}
                          text={t('ui.minicartCoupons.couponDetail.qrCodeMask.buttons.activateCode')}
                          onClick={onRequireCouponSecret}
                          disabled={_.includes(['transfer_in_progress'], transferState)}
                        />
                      </div>
                    </>
                  ) : (secretQrCodeValue || qrCodeValue) && (
                    <div className={styles.qrCodeContainer}>
                      <QRCode
                        value={secretQrCodeValue || qrCodeValue}
                        size={168}
                      />
                      {
                        canSelfUse && <p>★</p>
                      }
                      {
                        enableQrCodeClock && (
                          <p className={styles.clock}><Clock active /></p>
                        )
                      }
                      <p className={styles.qrInfo}>
                        {t(
                          'ui.minicartCoupons.couponDetail.textQR',
                          isPublic ? { context: 'publicCoupons' } : {},
                        )}
                      </p>
                    </div>
                  )
                }
              </div>
            ) : (
              <>
                {
                  isPublic && qrCodeValue && (
                    <div className={styles.qrCouponStyle}>
                      <div className={styles.qrCodeContainer}>
                        <QRCode
                          value={qrCodeValue}
                          size={168}
                        />
                        {
                          enableQrCodeClock && (
                            <p className={styles.clock}><Clock active /></p>
                          )
                        }
                        <p className={styles.qrInfo}>
                          {t(
                            'ui.minicartCoupons.couponDetail.textQR',
                            { context: 'publicCoupons' },
                          )}
                        </p>
                      </div>
                    </div>
                  )
                }
              </>
            )
        }
        {
          isUsage
          && showSecretQrCode
          && !_.isEmpty(secretQrCodeValue)
          && (
            <div className={styles.qrCouponStyle}>
              <QRCode
                value={secretQrCodeValue}
                size={168}
              />
              {
                canSelfUse && <p>★</p>
              }
              {
                enableQrCodeClock && (
                  <p className={styles.clock}><Clock active /></p>
                )
              }
              <p className={styles.qrInfo}>
                {t('ui.minicartCoupons.couponDetail.textQR')}
              </p>
            </div>
          )
        }
        {/* transfer eVoucher */}
        {
          isTransferable && (
            <TransferSection
              state={transferState}
              loading={transferLoading}
              onResend={onShare}
              onTransferIn={onTransferIn}
              onTransferOut={onTransferOut}
              onTransferUndo={onTransferUndo}
            />
          )
        }
      </div>
    </div>
  )
}

export default CouponMarketplaceCouponInfoView
