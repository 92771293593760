import _ from 'lodash'

/*
** Handle loyalty points value type is string or number
*/
export default function parseLoyaltyPoints(n) {
  // CP-20200415: Since API returns a string as "0.0" or "123.0" but we also want to support
  // values such as "1.23". This bit of code splits up the point by whole number and decimals
  // and ignores zero value decimals. Then combines it together again.
  // NOTE: THIS DOES RE-FORMATTING ONLY. THE POINTS VALUE SHOULD REMAIN UNCHANGED!
  const [num, dec] = _.toString(n).split('.');
  let points = _.toString(_.floor(_.toNumber(num)))
  if (parseInt(dec, 10) > 0) {
    points += `.${dec.toString()}`;
  }
  return points
}
