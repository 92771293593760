import _ from 'lodash'
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import {
  cancelRequest,
  useProducts,
} from 'react-omnitech-api'
import { useSku } from '../../../../hook'

import SwatchListView from './swatch-list-view'

const SwatchListController = (props) => {
  const {
    sku,
    inventoryStoreCode,
    ...rest
  } = props// states
  const { fetchProduct } = useProducts()
  const {
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()

  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [skus, setSkus] = useState([])

  const productId = useMemo(() => _.get(sku, 'product.id'), [sku])
  const itemCount = useMemo(() => _.get(sku, 'product.colorOptionCount', 0), [sku])

  function fetchProductApiCleanUp() {
    cancelRequest.cancelAll([
      'fetchProduct',
    ])
  }
  /**
   * fetchProductApi
   * get product data from API
   */
  const fetchProductApi = useCallback(async () => {
    if (
      !productId
    ) return
    const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
    const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
    try {
      // api call option
      const option = {
        id: productId,
        includes: [
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.stock_level',
          'color_options.price_details',
          'color_options',
          'skus',
          'skus.color_option_id',
        ].join(','),
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { product } = await fetchProduct(option)
      setList(_.get(product, 'colorOptions', []))
      setSkus(_.get(product, 'skus', []))
    } catch (error) {
      // Don't render
    } finally {
      setLoading(false)
    }
  }, [productId, fetchProduct])

  useEffect(() => {
    fetchProductApi()
    return fetchProductApiCleanUp
  }, [fetchProductApi])

  useEffect(() => (fetchProductApiCleanUp), [])

  const viewProps = {
    list,
    loading,
    skus,
    itemCount,
    ...rest,
  }
  return (
    <SwatchListView {...viewProps} />
  )
}

export default SwatchListController
