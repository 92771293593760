/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  containerAlignLeft: {
    justifyContent: 'flex-start',
  },
  containerAlignRight: {
    justifyContent: 'flex-end',
  },
  containerAlignCenter: {
    justifyContent: 'center',
  },

  divider: {
    display: 'block',
    width: '0.1rem',
    height: '1em',
    margin: '0 1rem 0.16667em',
    backgroundColor: theme.colors.bgDark,
  },
  dividerForMultiLine: {
    display: 'none',
  },
  item: {},
  itemAlignLeft: {
    marginRight: '2.1rem',
  },
  itemAlignRight: {
    marginLeft: '2.1rem',
  },
  itemAlignCenter: {
    marginLeft: '1.1rem',
    marginRight: '1rem',
  },
}))

export default useStyles
