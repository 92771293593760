import _ from 'lodash';
import React from 'react'
import { combineClassNames } from '../../../helpers';
import Carousel from '../../carousel'
import useStyles from './content-group-style'

function TemplateCarousel(props) {
  const {
    contentGroup,
    onClickTrackEvent,
    template,
    configOptions,
  } = props
  const { contentGroupLines } = contentGroup
  const { row, col, imageBoxOneImage } = useStyles()
  const onClick = (label) => {
    onClickTrackEvent(template, label)
  }
  return (
    <div className={combineClassNames([row, _.get(configOptions, 'htmlClass')])}>
      <div className={col}>
        <Carousel
          images={contentGroupLines}
          slidesToShowDesktop={1}
          carouselSettings={{
            responsive: [],
          }}
          imageBoxClassName={imageBoxOneImage}
          autoplay
          darkDots
          className="heroCarousel"
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default TemplateCarousel
