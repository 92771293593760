import _ from 'lodash'
// import moment from 'moment'
import * as Yup from 'yup'
import i18next from 'i18next'

/**
 * transformRegistrationFormValidationSchema
 * define validation schema base on form config
 *
 * @param {*} formConfig
 */

export default function transformRegistrationFormValidationSchema(formConfig = {}) {
  const yupShapeObj = {}

  _.each(formConfig, (fieldConfig = {}, key) => {
    yupShapeObj[key] = Yup.string().nullable()
    if (fieldConfig.required === true) {
      yupShapeObj[key] = yupShapeObj[key]
        .required(i18next.t(`screens.registration.errors.${key}`, { context: 'required' }))
    }
    if (key === 'email') {
      yupShapeObj[key] = yupShapeObj[key]
        .email(i18next.t(`screens.registration.errors.${key}`, { context: 'format' }))
    }
    if (key === 'email' && fieldConfig.requireConfirmation === true) {
      yupShapeObj.emailConfirmation = Yup.string()
        .oneOf(
          [Yup.ref('email'), null],
          i18next.t('screens.registration.errors.emailConfirmation', { context: 'notMatch' }),
        )
    }
    // Remove email / phone is not allowed when edit profile
    if (_.includes(['email', 'phone'], key) && !_.isEmpty(fieldConfig.initialValue) && fieldConfig.required !== true) {
      yupShapeObj[key] = yupShapeObj[key]
        .required(i18next.t(`screens.registration.errors.${key}`, { context: 'required' }))
    }
    if (key === 'password') {
      yupShapeObj[key] = yupShapeObj[key]
        .min(8, i18next.t(`screens.registration.errors.${key}`, { context: 'minimum' }))
    }
    if (key === 'password' && fieldConfig.requireConfirmation === true) {
      yupShapeObj.passwordConfirmation = Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          i18next.t('screens.registration.errors.passwordConfirmation', { context: 'notMatch' }),
        )
    }
    if (key === 'passwordConfirmation') {
      yupShapeObj.passwordConfirmation = Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          i18next.t('screens.registration.errors.passwordConfirmation', { context: 'notMatch' }),
        )
    }
    if (key === 'phone' && fieldConfig.required === true) {
      yupShapeObj[key] = yupShapeObj[key]
        .ensure()
        .test('isPhone', 'Phone is invalid date', function test(phone) {
          const { path, createError } = this;
          const phoneValid = /^\+?([0-9]{9,14})$/.test(phone)
          return phoneValid || createError({ path, message: i18next.t('screens.registration.errors.phone', { context: 'notMatch' }) });
        })
    }

    // handle either fields required case
    if (fieldConfig.required !== true && fieldConfig.requiredWhenFieldEmpty) {
      const targetKey = _.camelCase(fieldConfig.requiredWhenFieldEmpty === 'phone' ? 'localPhoneNumber' : fieldConfig.requiredWhenFieldEmpty)
      yupShapeObj[key] = yupShapeObj[key]
        .when(targetKey, {
          is: (val) => _.isEmpty(val),
          then: yupShapeObj[key]
            .required(i18next.t(`screens.registration.errors.${key}`, { context: 'required' })),
        })
    }
    // Make token field be requied when email or phone is changed/added
    if (key === 'phone' && fieldConfig.requireToken === true) {
      const initialValue = _.get(fieldConfig, 'initialValue', '')
      yupShapeObj.token = (yupShapeObj.token || Yup.string().nullable())
        .when(key, {
          is: (val) => (
            !_.isEqual(initialValue, val) && !_.isEmpty(val)
          ),
          then: Yup.string().nullable().required(i18next.t('screens.registration.errors.token', { context: 'required' })),
        })
    }
    if (key === 'email' && fieldConfig.requireToken === true) {
      const initialValue = _.get(fieldConfig, 'initialValue', '')
      yupShapeObj.emailToken = (yupShapeObj.emailToken || Yup.string().nullable())
        .when(key, {
          is: (val) => (
            !_.isEqual(initialValue, val) && !_.isEmpty(val)
          ),
          then: Yup.string().nullable().required(i18next.t('screens.registration.errors.token', { context: 'required' })),
        })
    }
    // if (key === 'dateOfBirth') {
    //   yupShapeObj[key] = yupShapeObj[key]
    //     .ensure()
    //     .test('isDate', 'Date is invalid date', function test(dateValue) {
    //       const { path, createError } = this;
    //       const date = moment(dateValue);
    //       return date.isValid() ||
    //          createError({ path, message: i18next.t('screens.registration.errors.date',
    //            { context: 'notMatch' }) });
    //     })
    // }
  })

  return Yup.object().shape(yupShapeObj, _.keys(formConfig))
}
