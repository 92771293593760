import { createUseStyles } from 'react-jss'
import getStyleFromTheme from '../../helpers/get-style-from-theme'
import ratioCalc from '../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  productCard: {
    padding: [[20, 10]],
    textAlign: 'left',
    flex: 1,
    position: 'relative',
    width: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  figure: {
    position: 'relative',
    margin: 0,
  },
  favouriteButton: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
  },
  icon: {
    position: 'absolute',
    right: '1.3rem',
    top: '1.3rem',
    cursor: 'pointer',
    maxWidth: '1.8rem',
  },
  image: {
    marginBottom: '1rem',
    height: 0,
    ...getStyleFromTheme({
      theme,
      key: 'config.ratioPlp',
      styleProperty: 'paddingBottom',
      getValueFn: ratioCalc,
    }),
    position: 'relative',
    overflowY: 'hidden',
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
      margin: 0,
      width: '100%',
    },
  },
  carouselImage: {
    ...getStyleFromTheme({
      theme,
      key: 'config.ratioMiniCarousel',
      styleProperty: 'paddingBottom',
      getValueFn: ratioCalc,
    }),
  },
  noImage: {
    backgroundColor: '#dddddd',
    '& img': {
      width: '40% !important',
      height: '40% !important',
    },
  },
  content: {
    textAlign: 'center',
    padding: '1rem 1rem 2rem',
    color: theme.colors.text,
  },
  contentText: {
    marginBottom: '0',
    fontSize: '1.4rem',
    lineHeight: 1.2,
  },
  customLabelsContainer: {
    minHeight: '2rem',
    marginBottom: '0.8rem',
    marginTop: '1rem',
    '&:empty': {
      display: 'none',
    },
  },
  customLabels: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  colorSelect: {
    color: theme.colors.disabled,
  },
  price: {
    fontSize: '1.8rem',
    padding: '0.5rem 0',
  },
  checkbox: {
    justifyContent: 'center',
    margin: '0',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    '& div:nth-child(3)': {
      marginLeft: '2.2rem',
    },
  },
  front: {
    '&::before': {
      width: 16,
      height: 16,
    },
    '&::after': {
      left: '0.2rem',
    },
  },
  textCheckbox: {
    textTransform: 'uppercase',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    productCard: {
      padding: [[20, 15]],
    },
  },
}))

export default useStyles
