/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './social-media-style'
import iconBuilder from '../../helpers/iconBuilder'
import IconLink from '../../assets/icons/icon_link.svg'
import combineClassNames from '../../helpers/combineClassNames'

const SocialMediaView = (props) => {
  const { t } = useTranslation()
  const {
    onShare,
    classN,
    socialMediaActive,
    hasAnySocialMediaActive,
    copyToClipboard,
    show,
  } = props
  const styles = useStyles()
  return (
    hasAnySocialMediaActive ? (
      <div className={combineClassNames([styles.container, classN])}>

        <h6>{t('ui.socialmedia.share')}</h6>
        <div className={styles.boxLogos}>
          {
              Object.entries(socialMediaActive).map(([key, value]) => {
                if (!value.isActive) return null
                return (
                  <i key={key} onClick={onShare.bind(this, key)}>
                    <img src={iconBuilder(key)} alt={key} />
                  </i>
                )
              })
            }
          <i className={styles.link} onClick={copyToClipboard}>
            <div className={combineClassNames([styles.popup, show ? styles.show : ''])}><span>{t('ui.socialmedia.copied')}</span></div>
            <img src={IconLink} alt="link" />
          </i>
        </div>
      </div>
    ) : ''
  )
}

export default SocialMediaView
