import _ from 'lodash'

/*
** Handle different format of Aspect Ratio to `16 / 9` format
*/
export default function parseAspectRatio(n) {
  if (_.isNumber(n)) return n
  if (_.isEmpty(n) || !_.isString(n)) return 'auto'
  return _.join(_.map(_.split(n, /[:|/|,]/), _.trim), ' / ')
}
