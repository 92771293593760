import _ from 'lodash';

export default function isAscii(string) {
  const hexCodeList = [
    '\\u0000-\\u00FF', // Ascii Letter
    '\\u2010-\\u2027', // Punctuation
    '\\u2030-\\u205E', // Punctuation
    '\\u20A0-\\u20BD', // Currency Sign
    '\\u2100-\\u214E', // Symbol
    '\\u2190-\\u21FF', // Arrow
    '\\u2200-\\u22FF', // Math Sign
    '\\u2600-\\u26E2', // Symbol
    '\\u2700-\\u27BF', // Symbol
    '\\u27F0-\\u27FF', // Arrow
    '\\u2900-\\u297F', // Arrow
  ]
  const re = new RegExp(`^[${hexCodeList.join('')}]*$`)
  return (_.isString(string) || _.isNumber(string)) && re.test(string)
}
