import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme = {}) => ({
  container: {
    padding: '0 16px 0',
  },
  errorStyle: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '0 0 0.8rem',
  },
  notes: {
    '& ul': {
      listStyle: 'disc',
      paddingLeft: '2rem',
      paddingBottom: '1rem',
    },
    '& li': {
      margin: 0,
      fontSize: '1.4rem',
      lineHeight: 1.14,
      color: '#676767',
    },
  },
  textarea: {
    width: '100%',
  },
}))

export default useStyles
