import _ from 'lodash'

// return a unique string to use for FlatList / SectionLists.
// If no key provided, will use item.id
export default function keyExtractor(item, index = 0, key = 'id', namespace = 'keyExtractor') {
  let segment = null
  if (_.isArray(key)) {
    segment = _.reduce(key, (a, b) => `${a}_${item[b]}`, '')
  } else {
    segment = item[key]
  }

  // if segment is still empty here then randomise it
  if (_.isEmpty(_.toString(segment))) segment = _.random(new Date().getTime())
  const str = [namespace, segment, index].join('-')
  // console.log('%c keyExtractor', 'color:red', str)
  return str
}
