/* eslint-disable import/no-cycle */
import _ from 'lodash'
import URI from 'urijs'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { cancelRequest, useContentGroups, useI18n } from 'react-omnitech-api'
import ContentGroupView from './content-group-view'
import { useLocation, useOrderMethodModal } from '../../hook'

const ContentGroupController = (props) => {
  const {
    code,
    fallback = null,
    id,
    template,
  } = props

  // prepare omnitech api hook
  const { fetchContentGroupByCode, fetchContentGroup } = useContentGroups()

  const { location } = useLocation()
  // use `useI18n` from `react-omnitech-api` to ensure api calls are using correct locale
  const { currentLanguage } = useI18n()
  const { isOpen } = useOrderMethodModal()
  // internal states
  const [contentGroup, setContentGroup] = useState({})
  const [ready, setReady] = useState(false)
  const [contentGroupNotFound, setContentGroupNotFound] = useState(false)

  const commerceChannelFromUrl = useMemo(() => {
    const url = new URI(_.get(location, 'href'))
    const search = url.search(true)
    return _.get(search, 'cc')
  }, [location])
  /**
   * getContentGroup
   * get home page content group data from API
   */

  const getContentGroupByCode = useCallback(async () => {
    if (_.isEmpty(_.toString(code))) return
    const cancelKey = `fetchContentGroupByCode|${code}`
    cancelRequest.cancel([cancelKey])
    try {
      // api call option
      const option = {
        code,
        includes: [
          'content_group_lines',
        ],
        cancelKey,
      }
      const { contentGroup: data } = await fetchContentGroupByCode(option)
      setContentGroup(data)

      setReady(true)
    } catch (error) {
      // ignore error if api call is canceled
      if (error.isCancel) return
      // when fail to load content group, what should be shown?
      setContentGroupNotFound(true)
      console.warn('fetchContentGroupByCode error: ', error)
    }
  }, [fetchContentGroupByCode, code, currentLanguage])

  const getContentGroupById = useCallback(async () => {
    const cancelKey = `getContentGroupById|${id}`
    cancelRequest.cancel([cancelKey])
    try {
      // api call option
      const option = {
        id,
        includes: [
          'content_group_lines',
        ],
        cancelKey,
      }
      const { contentGroup: data } = await fetchContentGroup(option)
      setContentGroup(data)
      setReady(true)
    } catch (error) {
      // ignore error if api call is canceled
      if (error.isCancel) return
      // when fail to load content group, what should be shown?
      setContentGroupNotFound(true)
      console.warn('fetchContentGroup error: ', error)
    }
  }, [fetchContentGroup, id, currentLanguage])

  /**
   * load home page content group when page loaded
   */

  useEffect(() => {
    if (isOpen) return
    // Prevent Product group use content group endpoint
    if (template === 'ProductGroup') {
      setReady(true)
      return
    }
    if (_.isNumber(id)) {
      getContentGroupById()
    } else {
      getContentGroupByCode()
    }
    // TODO Ramon J 01/04/2021 - relate coda-1254
    // Can not clean as Main Menu and many on
    // the footer is call the same time, and cancel one of then
    // return function getContentGroupCleanUp() {
    //   cancelRequest.cancelAll([
    //     'fetchContentGroup',
    //     'fetchContentGroupByCode',
    //   ])
    // }
  }, [
    getContentGroupByCode,
    getContentGroupById,
    id,
    code,
    template,
    commerceChannelFromUrl,
    isOpen,
    currentLanguage,
  ])

  const viewProps = {
    ...props,
    contentGroup,
    ready,
  }

  return (
    contentGroupNotFound
      ? fallback
      : <ContentGroupView {...viewProps} />
  )
}

export default ContentGroupController
