/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, {
  forwardRef,
  useEffect,
  useMemo,
  useImperativeHandle,
} from 'react'
import { useSystemSettings, useSmsToken, useEmailToken } from 'react-omnitech-api'
import TokenButtonView from './token-button-view'

function TokenButtonController(props, ref) {
  const { getSystemSetting } = useSystemSettings()
  const smsResendTokenInterval = getSystemSetting('account.sms_token.resend_interval')
  const emailResendTokenInterval = getSystemSetting('account.email_token.resend_interval')
  const {
    sendSmsToken,
    smsTokenCountDown,
    smsTokenDisable,
    resetCounterSmsToken,
  } = useSmsToken(smsResendTokenInterval)
  const {
    sendEmailToken,
    emailTokenCountDown,
    emailTokenDisable,
    resetCounterEmailToken,
  } = useEmailToken(emailResendTokenInterval)

  const {
    phone,
    email,
    onSuccess,
    onError,
    ...rest
  } = props

  const type = useMemo(() => (
    !_.isEmpty(phone) ? 'phone' : 'email'
  ), [phone])

  useImperativeHandle(ref, () => ({
    resetCounter: !_.isEmpty(email) ? resetCounterEmailToken : resetCounterSmsToken,
  }))

  useEffect(() => (() => {
    resetCounterSmsToken()
    resetCounterEmailToken()
  }), [])

  const onClick = async () => {
    if (
      _.isEmpty(phone)
      && _.isEmpty(email)
    ) return
    try {
      if (!_.isEmpty(phone)) {
        await sendSmsToken(phone)
      } else if (!_.isEmpty(email)) {
        await sendEmailToken(email)
      }
      if (_.isFunction(onSuccess)) onSuccess()
    } catch (error) {
      if (_.isFunction(onError)) onError(error)
    }
  }

  const viewProps = {
    countDown: type === 'phone' ? smsTokenCountDown : emailTokenCountDown,
    countDownInProgress: type === 'phone' ? smsTokenDisable : emailTokenDisable,
    type,
    onClick,
    ...rest,
  }

  return (
    <TokenButtonView {...viewProps} />
  )
}

export default forwardRef(TokenButtonController)
