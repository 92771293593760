import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import Modal from '../modal'
import useStyles from './payment-provider-token-delete-modal-style'
import IconCross from '../../assets/icons/icon_modal_cross.svg'

export default function PaymentProviderTokenDeleteModalView({
  isOpen = false,
  detail = '',
  onRequestClose,
  onConfirm,
}) {
  const styles = useStyles()
  const { t } = useTranslation()

  const description = t('ui.paymentProviderTokenDeleteModal.description')

  return (
    <Modal
      dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Button
        className={styles.closeButton}
        inline
        onClick={onRequestClose}
        iconImage={IconCross}
        iconClassName={styles.closeButtonIcon}
      />
      <div className={styles.dialogContainer}>
        <div className={styles.dialogBody}>
          <h1 className={styles.dialogTitle}>{t('ui.paymentProviderTokenDeleteModal.title')}</h1>
          <p className={styles.dialogDescription}>{description}</p>
          <p className={styles.dialogDescription}>{detail}</p>
        </div>
        <div className={styles.dialogButtons}>
          <Button
            className={styles.button}
            dark
            text={t('ui.paymentProviderTokenDeleteModal.buttons.confirm')}
            onClick={onConfirm}
          />
          <Button
            className={styles.button}
            border
            text={t('ui.paymentProviderTokenDeleteModal.buttons.cancel')}
            onClick={onRequestClose}
          />
        </div>
      </div>
    </Modal>
  )
}
