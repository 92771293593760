/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */

import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import { Treebeard, decorators as defaultDecorators } from 'react-treebeard';
import defaultTheme from 'react-treebeard/dist/themes/default'
import { useMenu } from '../../hook'
import CustomContainer from './components/container'
import CustomHeader from './components/header'
import CustomToggle from './components/toggle'
import useStyles from './accordion-style'

const AccordionNested = (props = {}) => {
  const {
    onItemClick,
  } = props
  const theme = useTheme()
  const { menuData } = useMenu()

  // get first level for Styles
  const nodeParents = _.map(menuData, 'key')

  const dataReorganize = _.map(menuData, organizeData)
  defaultDecorators.Header = (headerProps) => (
    <CustomHeader onClick={onItemClick} {...headerProps} />
  )
  defaultDecorators.Toggle = CustomToggle
  defaultDecorators.Container = (containerProps) => (
    <CustomContainer nodeParents={nodeParents} {...containerProps} />
  )
  const styles = useStyles()
  const [data, setData] = useState(dataReorganize)
  const [cursor, setCursor] = useState(false)
  const level = 2

  function organizeData(obj) {
    return {
      id: obj.key,
      code: obj.key,
      name: obj.text,
      url: obj.url,
      children: obj.children,
    }
  }

  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false
    }
    node.active = true

    if (node.children && !_.isEmpty(node.children)) {
      node.toggled = toggled;
      node.children = _.map(node.children, (item) => ({
        ...item,
        code: item.key,
        name: item.text,
        parent: node.parent || node.code,
        level: node.level ? node.level + 1 : level,
      }))
    }
    setCursor(node);
    setData([...data])
  }

  defaultTheme.tree.base = {
    ...defaultTheme.tree.base,
    color: '#000',
    backgroundColor: theme.colors.bgLight,
  }

  useEffect(() => {
    setData(dataReorganize)
  }, [menuData])

  return (
    <div className={styles.container}>
      {
          data && (
          <Treebeard
            style={defaultTheme}
            data={data}
            onToggle={onToggle}
            decorators={defaultDecorators}
          />
          )
        }
    </div>
  )
}

export default AccordionNested
