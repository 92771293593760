// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-addresses-js": () => import("./../../../src/pages/account/addresses.js" /* webpackChunkName: "component---src-pages-account-addresses-js" */),
  "component---src-pages-account-coupons-js": () => import("./../../../src/pages/account/coupons.js" /* webpackChunkName: "component---src-pages-account-coupons-js" */),
  "component---src-pages-account-favourites-js": () => import("./../../../src/pages/account/favourites.js" /* webpackChunkName: "component---src-pages-account-favourites-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-loyalty-points-js": () => import("./../../../src/pages/account/loyalty-points.js" /* webpackChunkName: "component---src-pages-account-loyalty-points-js" */),
  "component---src-pages-account-my-coupons-js": () => import("./../../../src/pages/account/my-coupons.js" /* webpackChunkName: "component---src-pages-account-my-coupons-js" */),
  "component---src-pages-account-order-js": () => import("./../../../src/pages/account/order.js" /* webpackChunkName: "component---src-pages-account-order-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-payment-provider-tokens-js": () => import("./../../../src/pages/account/payment-provider-tokens.js" /* webpackChunkName: "component---src-pages-account-payment-provider-tokens-js" */),
  "component---src-pages-account-profile-js": () => import("./../../../src/pages/account/profile.js" /* webpackChunkName: "component---src-pages-account-profile-js" */),
  "component---src-pages-account-referral-js": () => import("./../../../src/pages/account/referral.js" /* webpackChunkName: "component---src-pages-account-referral-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-complete-js": () => import("./../../../src/pages/checkout-complete.js" /* webpackChunkName: "component---src-pages-checkout-complete-js" */),
  "component---src-pages-checkout-completed-js": () => import("./../../../src/pages/checkout/completed.js" /* webpackChunkName: "component---src-pages-checkout-completed-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-checkout-payment-processing-js": () => import("./../../../src/pages/checkout/payment-processing.js" /* webpackChunkName: "component---src-pages-checkout-payment-processing-js" */),
  "component---src-pages-content-group-js": () => import("./../../../src/pages/content-group.js" /* webpackChunkName: "component---src-pages-content-group-js" */),
  "component---src-pages-dine-in-js": () => import("./../../../src/pages/dine-in.js" /* webpackChunkName: "component---src-pages-dine-in-js" */),
  "component---src-pages-dine-in-order-placed-js": () => import("./../../../src/pages/dine-in-order-placed.js" /* webpackChunkName: "component---src-pages-dine-in-order-placed-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-health-check-js": () => import("./../../../src/pages/health-check.js" /* webpackChunkName: "component---src-pages-health-check-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-oauth-js": () => import("./../../../src/pages/oauth.js" /* webpackChunkName: "component---src-pages-oauth-js" */),
  "component---src-pages-pages-js": () => import("./../../../src/pages/pages.js" /* webpackChunkName: "component---src-pages-pages-js" */),
  "component---src-pages-processing-js": () => import("./../../../src/pages/processing.js" /* webpackChunkName: "component---src-pages-processing-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-products-all-js": () => import("./../../../src/pages/products-all.js" /* webpackChunkName: "component---src-pages-products-all-js" */),
  "component---src-pages-products-comparison-js": () => import("./../../../src/pages/products-comparison.js" /* webpackChunkName: "component---src-pages-products-comparison-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-public-coupons-js": () => import("./../../../src/pages/public-coupons.js" /* webpackChunkName: "component---src-pages-public-coupons-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-store-locations-js": () => import("./../../../src/pages/store-locations.js" /* webpackChunkName: "component---src-pages-store-locations-js" */),
  "component---src-pages-stores-js": () => import("./../../../src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */)
}

