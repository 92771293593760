import { useContext } from 'react'
import OrderMethodModalContext from './order-method-modal-context'

/**
 * useModal
 * The custom hook which provided to external use via context
 */
const useOrderMethodModal = () => {
  const context = useContext(OrderMethodModalContext)
  return context
};

export default useOrderMethodModal
