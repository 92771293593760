import { useContext } from 'react'
import CommerceChannelContext from './commerce-channel-context'

/**
 * useCommerceChannel
 * The custom hook which provided to external use via context
 */
const useCommerceChannel = () => {
  const context = useContext(CommerceChannelContext);
  return context
};

export default useCommerceChannel;
