/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'
import getStyleFromTheme from '../../../../helpers/get-style-from-theme'
import MaskTop from '../../../../assets/images/icon-coupon-mask-top.svg'
import MaskBotton from '../../../../assets/images/icon_coupon_mask_bottom.svg'

const useStyles = createUseStyles((theme) => ({
  containerCart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    background: theme.colors.background,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      background: '#fff',
      maskImage: `url(${MaskBotton}) `,
      maskType: 'alpha',
      width: '100%',
      height: 20,
      zIndex: 2,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      backgroundColor: 'transparent',
      backgroundImage: `url(${MaskBotton}) `,
      filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.2))',
      width: '100%',
      height: 20,
      zIndex: 1,
    },
  },
  photo: (props) => ({
    width: '100%',
    maxWidth: '50rem',
    overflow: 'hidden',
    background: theme.colors.bgLight,
    marginBottom: 0,
    position: 'relative',
    '& div': {
      height: 0,
      ...getStyleFromTheme({
        theme,
        key: 'config.ratioCouponDetailImg',
        styleProperty: 'paddingBottom',
        getValueFn: ratioCalc,
      }),
      position: 'relative',
      overflowY: 'hidden',
      maskImage: `url(${MaskTop})`,
      maskType: 'alpha, match-source',
      maskPosition: 'top',
      maskRepeat: 'no-repeat',
      maskSize: 'cover',
      boxShadow: '0px -15px 3px -3px rgba(0,0,0,0.2)',
    },
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      margin: 0,
      width: '100%',
      height: 'auto',
    },
  }),
  noImage: {
    '& div': {
      background: '#dddddd',
    },
    '& img': {
      width: '30% !important',
    },
  },
  info: {
    padding: [[12, 20, 12]],
    '& h4': {
      fontSize: '2.1rem',
      fontWeight: 600,
      marginBottom: '0.6rem',
      letterSpacing: 0.5,
      lineHeight: '2.5rem',
      color: theme.colors.text,
    },
  },
  infoUsageLeft: {
    margin: '1rem 0',
  },
  dateStyle: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: '.3rem',
    '& span': {
      fontSize: '1.5rem',
      lineHeight: '1.07',
      lineSpacing: '0.18px',
      color: theme.colors.text,
    },
    '& img': {
      margin: '0 0.8rem 0 0',
    },
  },
  dateIcon: {
    width: '1.6rem',
  },
  pointsBox: {
    alignItems: 'flex-end',
    borderTop: `2px solid ${theme.colors.bgLight}`,
    borderBottom: `2px solid ${theme.colors.bgLight}`,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '.9rem',
    marginBottom: '2.1rem',
    padding: [[14, 16, 12]],
    justifyContent: 'space-between',
    '& p:first-child': {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.33,
      margin: 0,
    },
    '& p:last-child': {
      fontSize: '2.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: 0.29,
      margin: 0,
      color: theme.colors.text,
      '& span': {
        display: 'inline-block',
        lineHeight: 'inherit',
        marginLeft: '.5rem',
        textTransform: 'uppercase',
      },
    },
  },
  conditions: {
    padding: [0, 16],
    color: theme.colors.bgMediumDark,
    fontSize: '1.5rem',
    lineHeight: 1.07,
    fontWeight: 400,
    paddingBottom: 20,
  },
  validPeriod: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    lineHeight: '1.33',
    letterSpacing: '0.14px',
    marginBottom: '.4rem',
  },
  statusLabel: {
    fontSize: '1.5rem',
    margin: '1.2rem 0',
    padding: '0.2rem 1.5rem',
    backgroundColor: theme.colors.alert,
    color: theme.colors.bgLight,
    display: 'inline-flex',
  },
  statusLabelUsable: {
    backgroundColor: theme.colors.bgMediumDark,
    color: theme.colors.bgLight,
  },
  qrCouponStyle: {
    padding: 20,
    textAlign: 'center',
    '&:empty': {
      display: 'none',
    },
  },
  qrInfo: {
    marginTop: '3rem',
    padding: '0 30px',
    textAlign: 'center',
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
  qrCodeMaskContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  qrCodeMask: {
    position: 'relative',
  },
  qrCodeMaskFakeCode: {
    display: 'block',
    opacity: 0.1,
  },
  qrCodeMaskLockIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '10rem',
    height: '10rem',
  },
  qrCodeMaskButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3.5rem 0',
  },
  qrCodeContainer: ({ status, isPublic }) => ({
    marginBottom: '3.5rem',
    opacity: status === 'usable' || !isPublic ? 1 : 0.5,
  }),
  clock: {
    textAlign: 'center',
    fontSize: '1.8rem',
    color: theme.colors.alert,
    margin: '2rem 0',
  },
  buttonActivate: {},
  transferSection: {
    textAlign: 'center',
    padding: '2rem',
    position: 'relative',
    '&::after': {
      content: '""',
      height: '0.1rem',
      backgroundColor: theme.colors.bgMediumDark,
      position: 'absolute',
      left: '2rem',
      right: '2rem',
      top: '-2rem',
    },
  },
  transferSectionTitle: {
    fontSize: '2.2rem',
  },
  transferSectionSubtitle: {
    fontSize: '1.8rem',
  },
  transferSectionDescription: {
    fontSize: '1.4rem',
    lineHeight: '1.28em',
    margin: '3.5rem 0 3.5rem',
  },
  transferSectionVisual: {
    marginTop: '3.5rem',
  },
  transferSectionVisualIcon: {
    margin: 0,
  },
  transferSectionButtons: {
    margin: '3.5rem 0 3.5rem',
  },
  transferSectionLink: {
    textTransform: 'none',
    textDecoration: 'underline',
  },
  transferSectionLinkResend: {
    marginTop: '3rem',
  },
}))

export default useStyles
