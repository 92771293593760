import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  radioButton: {
    position: 'relative',
    overflow: 'hidden',
  },
  radioButtonDisabled: {
    opacity: 0.5,
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    textAlign: 'left',
  },
  radioInput: {
    visibility: 'hidden',
    position: 'absolute',
    left: '-99999em',
    top: '-99999em',
    '&:checked + $radio::after': {
      content: '""',
      display: 'block',
      width: '58%',
      height: '58%',
      borderRadius: '50%',
      backgroundColor: theme.colors.secondary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  radio: {
    position: 'relative',
    display: 'block',
    width: '1.6rem',
    height: '1.6rem',
    border: `0.1rem solid ${theme.colors.bgMediumDark}`,
    borderRadius: '50%',
    marginRight: '0.4rem',
    flexShrink: 0,
  },
  text: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: 1.14,
  },
}))

export default useStyles
