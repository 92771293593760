/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { FaFacebookF } from 'react-icons/fa'
import { useTheme } from 'react-jss'
import { useLink } from '../../hook'
import useStyles from './button-style'
import combineClassNames from '../../helpers/combineClassNames'
import CartIcon from '../../assets/icons/icon_cart_white.svg'

const ButtonView = (props) => {
  const {
    border,
    dark,
    mini,
    inline,
    text,
    type,
    navigate: navTo,
    onClick,
    alert,
    className,
    classNameDisabled,
    hasIconleft,
    addFacebook,
    disabled,
    iconImage,
    iconInline,
    iconClassName,
    children,
    ...others
  } = props
  const { navigate } = useLink()
  const styles = useStyles({
    dark,
    alert,
    border,
    addFacebook,
    disabled,
  })
  const theme = useTheme()

  const handleNav = () => {
    navigate(navTo)
  }
  const Icon = iconImage
  return (
    <button
      onClick={navTo ? handleNav : onClick}
      className={
        combineClassNames([
          styles.button,
          inline && styles.inline,
          mini && styles.mini,
          disabled && classNameDisabled,
          className,
        ])
        }
      type={type}
      disabled={disabled}
      {...others}
    >
      {addFacebook && (
        <FaFacebookF
          className={combineClassNames([styles.icon, inline && styles.inlineIcon, iconClassName])}
          color={theme.background}
          size={22}
        />
      )}
      {text}
      {
        iconImage ? (
          iconInline ? (
            <Icon
              className={
                combineClassNames([styles.icon, inline && styles.inlineIcon, iconClassName])
              }
            />
          )
            : (
              <img
                className={
                  combineClassNames([styles.icon, inline && styles.inlineIcon, iconClassName])
                }
                src={iconImage}
                alt=""
              />
            )

        ) : ''
      }
      {children}
    </button>
  )
}

ButtonView.propTypes = {
  alert: PropTypes.bool,
  addFacebook: PropTypes.bool,
  border: PropTypes.bool,
  dark: PropTypes.bool,
  mini: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hasIconleft: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.any,
  navigate: PropTypes.string,
}

ButtonView.defaultProps = {
  dark: false,
  mini: false,
  alert: false,
  className: ' ',
  type: 'button',
  text: undefined,
  navigate: '',
  border: false,
  hasIconleft: false,
  addFacebook: false,
  disabled: false,
}

export default ButtonView
