/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect';
import useStyles from './input-search-style'
import SearchIconInline from '../../assets/icons/icon_search.inline.svg'
import Button from '../button'

const InputSearchView = ({ onSearch, isOpenSearch }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [query, setQuery] = useState('')
  const inputEl = useRef()
  const onClickSearch = () => {
    onSearch(query)
  }
  const onPressSearch = (event) => {
    if (event.key === 'Enter') {
      inputEl.current.blur()
      onSearch(query)
    }
  }
  useEffect(() => {
    if (inputEl.current && !isMobile) {
      inputEl.current.focus()
    }
  }, [isOpenSearch])

  return (
    <div className={styles.containerFluid}>
      <div className={styles.container}>
        <input
          ref={inputEl}
          className={styles.input}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={onPressSearch}
          type="search"
          placeholder={t('ui.search')}
        />
        <Button
          inline
          className={styles.searchButton}
          onClick={onClickSearch}
          iconInline
          iconImage={SearchIconInline}
          iconClassName={styles.searchIcon}
          aria-label="Search"
        />
      </div>
    </div>
  )
}

export default InputSearchView
