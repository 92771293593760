import _ from 'lodash'
import getStoreInfoFromDescription from './get-store-info-from-description'

/**
 * getAddressLinesFromStore
 *
 * @param store
 */
export default function getAddressLinesFromStore(store) {
  // Handle Address from Description
  const fallbackAddress = _.concat(
    _.get(store, 'address.formattedAddressLines', []),
    _.get(store, 'address.country', ''),
  )
  const descriptionHtml = (_.get(store, 'description') || '').trim()
  const descriptionAddressLines = _.map(getStoreInfoFromDescription('address', descriptionHtml), 'content')
  const address = _.isEmpty(descriptionAddressLines)
    ? fallbackAddress : descriptionAddressLines
  return address
}
