/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Link from '../../link'
import useStyles from './content-group-style'
import combineClassNames from '../../../helpers/combineClassNames'

function TemplateBanner(props) {
  const {
    url,
    attachment,
    configOptions,
    // onClickTrackEvent, template, configOptions, id,
  } = props
  // const onClick = () => {
  //   onClickTrackEvent(template)
  // }
  // const label = _.get(configOptions, 'label', id)
  const breakpoints = useBreakpoint()
  const { row, col, banner } = useStyles()
  const variableNameImage = breakpoints.sm ? 'ecomMedium' : 'ecomLarge'
  const renderImage = <img src={_.get(attachment, `versions.${variableNameImage}`)} alt="" loading={_.get(configOptions, 'loading', 'lazy')} />
  return (
    <div className={combineClassNames([row, _.get(configOptions, 'htmlClass')])}>
      {/* <p>Banner Template in theme</p> */}
      <div className={combineClassNames([col, banner])}>
        {/* CP-20210429: temporary removal as this sometimes affects the click-through destination */}
        {/* {_.isEmpty(url) || url === '/' ? renderImage : <Link onClick={() => onClick(label)} gatsbyLink to={url}>{renderImage}</Link>} */}
        {_.isEmpty(url) || url === '/' ? renderImage : <Link gatsbyLink to={url}>{renderImage}</Link>}
      </div>
    </div>
  )
}

export default TemplateBanner
