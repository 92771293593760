/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { useAuth } from 'react-omnitech-api'
import { getUrlFromLocation } from '../../helpers'
import { useLink, useThemeConfig } from '../../hook'

const withAuthenticableNavigationBlock = (WrappedComponent) => (props) => {
  const { auth } = useAuth()
  const location = useLocation()
  const { navigate } = useLink()
  const { getConfig } = useThemeConfig()

  /// ////// config.ui ////////
  // "authenticableNavigationBlock": {
  //   "pathsToEnableQuickRegisterMode": [
  //     "^\/account\/my-coupons\/?\?transf..."
  //   ]
  // },
  /// ////// with content group ///////
  // "authenticableNavigationBlock": {
  //   "pathsToEnableQuickRegisterMode": [
  //     {
  //       "contentGroup": {
  //         "code": "ecom_coupon_token_transfer_in_quick_register_disclaimer",
  //         "template": "text"
  //       },
  //       "path": "^\/account\/my-coupons\/?\?tranf..."
  //     }
  //   ]
  // },
  /// ////// Any named capture group from `path` RegExp will pass to login screen ///////
  // "path": "...nsfer=[A-Za-z0-9]+\&couponId=(?<couponId>[A-Za-z0-9]+)\&couponT..."
  /// /////////////////////
  const pathsToEnableQuickRegisterMode = getConfig('config.ui.authenticableNavigationBlock.pathsToEnableQuickRegisterMode', [])

  const findQuickRegisterModeConfig = (url) => {
    const result = _.find(
      _.castArray(pathsToEnableQuickRegisterMode),
      (item) => {
        const path = _.get(item, 'path', item)
        const regex = new RegExp(path)
        return regex.test(url)
      },
    )
    if (_.has(result, 'path') || _.isEmpty(result)) {
      return result
    }
    return {
      path: result,
    }
  }

  const getDataFromPath = (path, url) => {
    if (_.isEmpty(path)) return {}
    const regex = new RegExp(path)
    return _.get(regex.exec(url), 'groups', {})
  }
  /**
   * when the page is loaded
   * without user logged in, redirect to login page
   */
  useEffect(() => {
    const redirectUrl = getUrlFromLocation(location)
    const quickRegisterModeConfig = findQuickRegisterModeConfig(redirectUrl)
    const dataFromPath = getDataFromPath(
      _.get(quickRegisterModeConfig, 'path'),
      redirectUrl,
    )
    if (!auth.userId) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl,
            quickRegisterMode: !_.isEmpty(quickRegisterModeConfig),
            ..._.pick(quickRegisterModeConfig, ['contentGroup']),
            data: dataFromPath,
          },
          replace: true,
        },
      )
    }
  }, [])

  return auth.userId && <WrappedComponent {...props} />
}

export default withAuthenticableNavigationBlock
