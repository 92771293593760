import React from 'react'
import _ from 'lodash'
import URI from 'urijs'
import { navigate } from 'gatsby'
import { useOrderMethod } from 'react-omnitech-api'
// import useI18n from '../use-i18n'
import { isUrl } from '../../helpers'
import LinkContext from './link-context'

/**
 * LinkProvider
 * Contain most logic handing navigate
 */
export default function LinkProvider({ value: { locale }, children }) {
  // const { getLocalePath } = useI18n()
  const { orderMethod } = useOrderMethod()

  function getCommerceChannelFromPath(path = '/') {
    const uri = new URI(path)
    const qsObj = uri.search(true)
    return _.get(qsObj, 'cc')
  }

  function getPath(to, { commerceChannel, locale: newLocale } = {}) {
    let url = `/${newLocale || locale}${to}`
    if (isUrl(to)) {
      // when it is in development mode, will replace the origin of
      url = _.startsWith(to, process.env.GATSBY_WEBSITE_URL) ? removeUrlOrigin(to) : to
    }
    const uri = new URI(url)
    const qsObj = uri.search(true)
    return uri
      .search({
        ...qsObj,
        cc: commerceChannel || _.get(orderMethod, 'commerceChannel'),
      })
      .toString()
    // return url
  }

  function removeUrlOrigin(originUrl) {
    const url = URI(originUrl)
    return `${url.path()}${url.search()}`
  }

  function handleNavigate(to, { commerceChannel, locale: newLocale, ...options } = {}) {
    const url = getPath(to, { commerceChannel, locale: newLocale })
    navigate(url, options)
  }

  function handleRecordNotFound(page) {
    const ccFromPath = getCommerceChannelFromPath(page)
    if (
      !_.isEmpty(ccFromPath)
      && ccFromPath !== _.get(orderMethod, 'commerceChannel')
    ) return
    navigate(`/${locale}/404/`, { replace: true, state: { origPage: page } })
  }

  const state = {
    getPath,
    navigate: handleNavigate,
    recordNotFound: handleRecordNotFound,
  };

  return (
    <LinkContext.Provider value={state}>
      {children}
    </LinkContext.Provider>
  )
}
