/* eslint-disable max-len */
const config = {
  // favicon alow to change the default icon in browser tab
  favicon: '',
  // logo image preferably in format PNG,
  // p.s.: it can be in JPG but the bg of the image must be same color of the headerBackgroundColor
  logoImageDesktop: '',
  logoImageMobile: '',
  logoMinHeightDesktop: 100,
  logoMinHeightMobile: 46,
  headerLogoMinHeightDesktop: 100,
  headerLogoMinHeightMobile: 46,
  // ratio Images to control ratio of the pictures
  // ratio PLP : PLP images
  // ratio PDP : PDP big images
  // ratio ratioThumbnail : Mini card, chekout, orders account and PDP thumbnails
  // ratio ratioCartImg : Shopping cart page images
  // ratio ratioCouponDetailImg : Coupon Detail image
  // ratioMiniCarousel : Use for Product groups such as 'YOU MIGHT ALSO LIKE', content groups home products
  ratioPlp: '6:7',
  ratioPdp: '6:7',
  ratioThumbnail: '6:7',
  ratioCartImg: '1:1',
  ratioCouponDetailImg: '3:4',
  ratioMiniCarousel: '8:6',
  // backgroundImage to shop in body, no show in checkout proccess
  backgroundUrl: '',
  // allow to change color of the header, by default is control by primary as text color, background as backgroundColor in colors options
  headerBackgroundColor: '',
  headerColorText: '',
  // allow to change color of the top footer, by default is control by primary as text color, background as backgroundColor in colors options
  footerUpBackgroundColor: '',
  footerUpColorText: '',
  // allow to change color of the bottom footer, by default is control by primary as text color, background as backgroundColor in colors options
  footerDownBackgroundColor: '',
  footerDownColorText: '',
  ui: {
    images: {
      zoom: {
        size: 2,
        disableImageZoomDesktop: false,
        disableImageZoomMobile: false,
      },
    },
    buttons: {
      addToCart: {
        active: {
          border: '',
          backgroundColor: '',
        },
        disabled: {
          border: '',
          backgroundColor: '',
        },
      },
    },
  },
}

export default config
