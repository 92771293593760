import _ from 'lodash'

export default function getTopLevelCategoryCode({
  topLevelCategory = null,
  storeCode,
}) {
  // if empty then return null, i.e. no suffix or top level
  if (_.isEmpty(topLevelCategory)) return null

  if (_.isArray(topLevelCategory)) {
    return _.get(_.find(topLevelCategory, { store: storeCode }), 'code')
  }

  return _.replace(topLevelCategory, '{{storeCode}}', storeCode)
}
