import _ from 'lodash'

/**
 *
 * @param {array} addons an array of addons
 */
export default function groupAddonsWithQuantity(addons = []) {
  const uniqAddons = _.uniqWith(addons, (a, b) => (
    _.isEqual(_.get(a, 'addonId', 'a'), _.get(b, 'addonId', 'b'))
      && _.isEqual(_.get(a, 'productAddonId', 'a'), _.get(b, 'productAddonId', 'b'))
      && _.isEqual(_.get(a, 'skuId', 'a'), _.get(b, 'skuId', 'b'))
      && _.isEqual(_.get(a, 'parentIdentifierUuid', 'a'), _.get(b, 'parentIdentifierUuid', 'b'))
  ))
  const addonsWithQuantity = _.map(uniqAddons, (addon) => {
    const items = _.filter(addons, _.pick(addon, [
      'addonId',
      'productAddonId',
      'skuId',
      'parentIdentifierUuid',
    ]))
    const itemCount = _.size(items)
    return {
      ...addon,
      quantity: itemCount,
      groupedIdentifierUuid: _.map(items, 'identifierUuid'), // Keep `identifierUuid`s for finding parents later
    }
  })
  return addonsWithQuantity
}
