import _ from 'lodash'
import URI from 'urijs'
import React, { useState, useEffect, useMemo } from 'react'
import {
  useCommerceChannels,
} from 'react-omnitech-api'
import useOrderMethod from '../use-order-method'
import env from '../../config/env'
import CommerceChannelContext from './commerce-channel-context'

/**
 * CommerceChannelProvider
 * Contain most logic handing navigate
 */
export default function CommerceChannelProvider({ value: { location }, children }) {
  const {
    availableOrderMethod,
  } = useOrderMethod()
  const { fetchCommerceChannel } = useCommerceChannels()

  const [commerceChannels, setCommerceChannels] = useState({})
  const [cacheChecksum, setCacheChecksum] = useState(null)

  const commerceChannelCacheChecksum = _.get((JSON.parse(window.localStorage.getItem('cacheChecksum'))), 'commerceChannelsValue')

  const initReady = useMemo(() => !_.isNil(cacheChecksum), [cacheChecksum])

  const cc = useMemo(() => (
    _.get(URI.parseQuery(_.get(location, 'search', '')), 'cc')
  ), [location])

  const orderMethodCodes = useMemo(() => (
    _.uniq(
      _.compact([
        _.get(env, 'config.api.commerceChannel'),
        cc,
        ..._.map(
          availableOrderMethod,
          'commerceChannel',
        ),
      ]),
    )
  ), [availableOrderMethod, cc])

  const orderMethodCodesDependent = useMemo(() => (
    _.join(
      (orderMethodCodes || []).sort(),
      '|',
    )
  ), [orderMethodCodes])

  const fetchCommerceChannels = () => (
    Promise.all(
      _.map(orderMethodCodes, (code) => (
        fetchCommerceChannel({ code })
      )),
    ).then((values) => {
      setCacheChecksum(_.get(values, '0.cacheChecksum', null))
      setCommerceChannels(
        _.omitBy(
          _.zipObject(
            orderMethodCodes,
            _.map(values, 'commerceChannel'),
          ),
          _.isNil,
        ),
      )
    })
  )

  useEffect(() => {
    fetchCommerceChannels()
  }, [orderMethodCodesDependent, commerceChannelCacheChecksum])

  const state = {
    commerceChannels,
  };

  return (
    <CommerceChannelContext.Provider value={state}>
      {initReady && children}
    </CommerceChannelContext.Provider>
  )
}
