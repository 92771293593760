/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'
import MaskTop from '../../../../assets/images/icon-coupon-mask-top.svg'
import MaskBotton from '../../../../assets/images/icon_coupon_mask_bottom.svg'

const useStyles = createUseStyles((theme) => ({
  container: {
    textAlign: 'center',
    width: '100%',
    margin: ' 0 auto',
    backgroundColor: theme.colors.bgLight,
  },
  tabContainer: {
    height: 'calc(100vh - 60px)',
  },
  couponsTabs: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    height: 40,
    backgroundColor: theme.colors.background,
  },
  button: {
    backgroundColor: theme.colors.secondary,
    border: 'none',
  },
  couponsTab: {
    position: 'relative',
    fontSize: '1.6rem',
    color: theme.colors.disabled,
    cursor: 'pointer',
    margin: 0,
    flex: '1 1 50%',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    '&.react-tabs__tab--selected': {
      color: theme.colors.primary,
      fontWeight: 600,
      '&:after': {
        content: '""',
        position: 'absolute',
        height: 3,
        width: '100%',
        backgroundColor: theme.colors.secondary,
        left: 0,
        bottom: 0,
      },
    },
  },
  loading: {
    display: 'flex',
    height: '100%',
  },
  pointsCoupons: {
    borderTop: `1px solid ${theme.colors.bgLight}`,
    width: '100%',
    backgroundColor: theme.colors.background,
    height: '3.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      margin: 0,
      fontSize: '1.4rem',
    },
    '& span': {
      fontWeight: 600,
    },
  },
  couponsPanel: {
    // marginTop: '6rem',
    overflow: 'auto',
    '&.react-tabs__tab-panel--selected': {
      height: 'calc(100vh - 108px)',
      paddingBottom: 100,

    },
  },
  noContent: {
    marginTop: '10rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    tabContainer: {
      maxWidth: '57rem',
      height: 'calc(100vh - 68px)',
    },
    couponsTabs: {
    },
  },
}))

export default useStyles
