/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import List from '../../list'

const ListTemplate = (props) => {
  const {
    contentGroup: {
      title,
      contentGroupLines,
      code,
    },
    configOptions,
  } = props

  const viewProps = {
    ...props,
    title,
    code: _.isString(code) ? code : _.get(props, 'contentGroup.code'),
    listItems: contentGroupLines,
    containerStyle: _.get(configOptions, 'htmlClass'),
  }
  return (

    <List {...viewProps} />

  )
}

export default ListTemplate
