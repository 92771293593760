import localeConfig from '../i18n/locale-config'
import translations from '../i18n/translations'

export default {
  config: {
    api: {
      host: process.env.GATSBY_OMNITECH_API_HOST,
      locale: process.env.GATSBY_OMNITECH_API_LOCALE,
      apiChannel: process.env.GATSBY_OMNITECH_API_API_CHANNEL,
      commerceChannel: process.env.GATSBY_OMNITECH_API_COMMERCE_CHANNEL,
      systemSettingsUrl: process.env.GATSBY_OMNITECH_API_SYSTEM_SETTINGS_URL,
      systemSettingsChecksum: process.env.GATSBY_OMNITECH_API_SYSTEM_SETTINGS_CHECKSUM,
    },
    locale: {
      availableLocales: process.env.GATSBY_AVAILABLE_LOCALES.split(' '),
      defaultLocale: process.env.GATSBY_DEFAULT_LOCALE,
      config: localeConfig,
    },
    service: {
      googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    },
    uri: {
      useCanonicalUrl: /undefined|null|true/.test(process.env.GATSBY_USE_CANONICAL_URL),
    },
  },
  translations,
}
