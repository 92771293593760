import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({

  container: {
    letterSpacing: 'normal',
    padding: '1.5rem 0',
    '& h6': {
      fontWeight: 400,
      textTransform: 'uppercase',
      marginBottom: '1.8rem',
    },
  },
  boxLogos: {
    marginTop: 10,
    '& i': {
      position: 'relative',
      display: 'inline-block',
      height: '2.4rem',
      width: '2.4rem',
      marginRight: '1.8rem',
      cursor: 'pointer',
    },
    '& img': {
      position: 'absolute',
      margin: 10,
      transform: 'scale(1) translate(-50%, -50%)',
      width: '100%',
      left: '50%',
      top: '50%',
    },
  },
  link: {
    position: 'relative',
  },
  popup: {
    position: 'absolute',
    opacity: 0,
    top: 5,
    width: 45,
    height: 26,
    backgroundColor: theme.colors.bgLight,
    zIndex: 1,
    textAlign: 'center',
    '& span': {
      fontSize: 10,
    },
  },
  show: {
    opacity: 1,
    top: -12,
    transition: 'all 600ms',
  },
}))

export default useStyles
