/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './checkbox-group-style'
import InputCustomerRemarks from '../input-customer-remarks'

const Checkbox = (props) => {
  const { indeterminate, showHeader, ...inputProps } = props
  const inputRef = useRef(null)
  const styles = useStyles(props)
  useEffect(() => {
    inputRef.current.indeterminate = indeterminate
  }, [indeterminate])
  return (
    <div className={styles.checkbox}>
      <input type="checkbox" {...inputProps} className={styles.checkboxInput} ref={inputRef} />
      <i className={styles.checkboxIndicator} />
    </div>
  )
}
const Header = ({
  id,
  title,
  disabled,
  displayCheckbox,
  showHeader,
  ...others
}) => {
  const styles = useStyles({ disabled, showHeader })
  const checkboxProps = {
    ...others,
  }
  return (
    <label htmlFor={id} className={styles.item}>
      {displayCheckbox && <Checkbox {...checkboxProps} id={id} />}
      <span className={styles.text}>{title}</span>
    </label>
  )
}

const Item = (props) => {
  const {
    label, content, disabled, showHeader,
  } = props
  const styles = useStyles({ disabled, showHeader })
  const id = _.uniqueId(`checkbox-${_.get(props, 'id', '')}-`)
  return (
    <label htmlFor={id} className={styles.item}>
      <Checkbox {...props} id={id} />
      <div className={styles.body}>
        <p className={styles.text}>{label}</p>
        {content}
      </div>
    </label>
  )
}

const CheckboxGroupView = ({
  groupId,
  className,
  // itemClassName,
  // checkboxClassName,
  // textClassName,
  // separatorClassName,
  options,
  name,
  disabled,
  max = Infinity,
  title,
  value = [],
  onChange,
  onUpdate,
  addonCustomerRemarks,
}) => {
  const showHeader = useMemo(() => !_.isEmpty(title), [title])
  const styles = useStyles({ showHeader })

  const onItemChange = (data) => {
    const { id, customerRemark } = data
    let result = []
    if (_.some(options, { id })) {
      result = _.includes(value, id) ? _.without(value, id) : [...value, id]
    } else {
      // Header checkbox press
      result = _.size(value) === _.size(options) ? [] : _.map(options, 'id')
    }
    if (_.isFunction(onChange)) onChange({ value: result, customerRemark })
  }

  const onItemRemarkChange = (data) => {
    const { customerRemark } = data
    if (_.isFunction(onUpdate)) onUpdate({ value, customerRemark })
  }

  const onItemRemarkEndEditing = (data) => {
    const { customerRemark } = data
    if (_.isFunction(onChange)) onChange({ value, customerRemark })
  }

  const renderHeader = () => {
    const checked = value.length === options.length
    const indeterminate = !_.isEmpty(value) && !checked
    const headerProps = {
      id: _.uniqueId(name),
      title,
      onChange: onItemChange,
      disabled,
      checked,
      indeterminate,
      displayCheckbox: options.length <= max && !_.some(options, 'disabled'),
    }
    return <Header {...headerProps} />
  }

  return (
    <div className={combineClassNames([styles.group, className])}>
      {showHeader && renderHeader()}
      {_.map(options, (item, index) => {
        const itemId = _.get(item, 'id')
        const checked = _.includes(value, itemId)
        let showAddonRemarksField = false
        let customerRemarkProps = {
          onEndEditing: (val) => onItemRemarkEndEditing({ id: itemId, customerRemark: val }),
          onChange: (val) => onItemRemarkChange({ id: itemId, customerRemark: val }),
          value: item.customerRemark,
        }
        if (_.findIndex(addonCustomerRemarks, { code: item.skuCode }) !== -1) {
          showAddonRemarksField = true
          customerRemarkProps = {
            ...customerRemarkProps,
            ..._.omit(_.find(addonCustomerRemarks, { code: item.skuCode }), ['code']),
          }
        }
        const itemProps = {
          disabled:
            disabled || _.get(item, 'disabled', false) || (_.size(value) >= max && !checked),
          showHeader,
          name,
          label: _.get(item, 'title'),
          content: _.get(item, 'content'),
          checked,
          onChange: () => onItemChange({ id: itemId }),
        }
        return (
          <React.Fragment key={`Checkbox_${groupId}_${_.uniqueId(itemId)}`}>
            {index !== 0 && !showHeader ? <hr className={styles.separator} /> : <></>}
            <Item {...itemProps} id={`Checkbox_${groupId}_${itemId}`} />
            {checked && showAddonRemarksField && (
              <InputCustomerRemarks {...customerRemarkProps} />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default CheckboxGroupView
