import { createContext } from 'react'

/**
 * CommerceChannelContext
 * Create CommerceChannel context for custom hook
 */
const CommerceChannelContext = createContext({
  CommerceChannels: {},
})

export default CommerceChannelContext
