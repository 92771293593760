import _ from 'lodash'
import React from 'react'
import { useTheme } from 'react-jss'
import useStyles from './coupon-public-show-style'

import CouponMarketplaceCouponInfo from '../mini-cart-coupons/components/coupon-marketplace-coupon-info'
import Loading from '../loading'

const CouponPublicShowView = (props) => {
  const {
    coupon,
    loading,
  } = props
  // prepare hook
  const styles = useStyles()

  if (loading) {
    return <Loading />
  }

  return (
    <div className={styles.content}>
      <CouponMarketplaceCouponInfo
        coupon={coupon}
        hideValidPeriodDates
      />
      {/* Coupon Terms */}
      {/* <div
        className={styles.termsStyle}
        dangerouslySetInnerHTML={{ __html: _.get(coupon, 'terms', '') }}
      /> */}
    </div>
  )
}

export default CouponPublicShowView
