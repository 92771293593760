import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    'body.modal-open': {
      overflow: 'hidden',
      height: '100vh',
      width: '100%',
      position: 'fixed',
    },
  },
  modal: {
    position: 'absolute',
    top: '10vh',
    padding: '2rem',
    backgroundColor: theme.colors.background,
    outline: 'none',
  },
  modalCenter: {
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
  modalDialog: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    padding: [['4rem', '2rem']],
    width: '90%',
    maxWidth: '77rem',
    maxHeight: '95%',
  },
  modalFullPage: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transform: 'none',
    textAlign: 'center',
  },
  modalSlide: {
    overflowY: 'auto',
    position: 'fixed',
    transform: 'translateX(100%)',
    transitionDuration: '.55s',
    transitionTimingFunction: 'ease-out',
    transitionDelay: '.2s',
    '&.ReactModal__Content--after-open': {
      transform: 'none',
    },
    '&.ReactModal__Content--before-close': {
      transform: 'translateX(100%)',
    },
  },
  modalInnerScroll: {
    overflowY: 'auto',
  },
  overlay: {
    position: 'fixed',
    zIndex: 1002,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflowY: 'auto',
    backgroundColor: Color(theme.colors.overlay).alpha(0.7).string(),
    opacity: 0,
    transition: 'opacity 0.3s',
    '&.ReactModal__Overlay--after-open': {
      opacity: 1,
    },
  },
  cartOverlay: {
    right: '-100vw',
    '&.ReactModal__Overlay--after-open': {
      right: 0,
      opacity: 1,
      transition: 'all 0.8s',
      '&.ReactModal__Overlay--before-close': {
        right: '-100vw',
        opacity: 0,

      },
    },
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 400,
    lineHeight: '2.6rem',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  titleCenter: {
    marginTop: '0.4rem',
    marginBottom: '0.4rem',
    fontSize: '1.8rem',
    fontWeight: 500,
  },
}))

export default useStyles
