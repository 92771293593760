/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrencies } from 'react-omnitech-api'
import CustomLabels from '../custom-labels'
import FavouriteButton from '../favourite-button'
import Link from '../link'
import Checkbox from '../checkbox'
import Image from './components/image'
import PriceList from '../price-list'
import SwatchList from './components/swatch-list'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './product-card-style'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductCardView = (props) => {
  const {
    activeCustomLabels,
    colorName,
    colorOptionId,
    colorOptionVariantType = {},
    compareData,
    enableComparisonEcom,
    favourite,
    firstSkuOfSelectedColorOption,
    hasMoreThan,
    hideFavourites,
    imageUrl,
    inventoryStoreCode,
    maxNoOfCustomLabel,
    memberPrice,
    onAddToCompare,
    onClick,
    onFavouriteChange,
    onSwatchItemClick,
    onSwatchMoreClick,
    originalPrice,
    product,
    productDetailUrl,
    priceSuffix,
    priceLoading,
    sellPrice,
    showSwatches,
    sku,
    stockLevel,
    title,
  } = props

  // prepare hook
  const { t } = useTranslation()
  const { currencies } = useCurrencies()
  const currency = _.find(currencies, { isBaseCurrency: true })

  // states
  const [noImage, setNoImage] = useState(_.isEmpty(imageUrl))

  const styles = useStyles()

  useEffect(() => {
    if (_.isEmpty(imageUrl) !== noImage) {
      setNoImage(_.isEmpty(imageUrl))
    }
  }, [imageUrl])
  return (
    <div className={styles.productCard}>
      <figure className={styles.figure}>
        { !hideFavourites && (
          <div className={styles.favouriteButton}>
            <FavouriteButton
              colorOptionID={colorOptionId}
              favourite={favourite}
              onChange={onFavouriteChange}
              icon
            />
          </div>
        )}
        <Link
          to={productDetailUrl}
          onClick={onClick}
          className={styles.link}
          gatsbyLink
        >
          <Image src={imageUrl} alt={title} carouselImage={product} />
        </Link>
      </figure>
      <Link
        to={productDetailUrl}
        onClick={onClick}
        className={styles.link}
        gatsbyLink
      >
        <div className={styles.content}>
          <div className={styles.customLabelsContainer}>
            <CustomLabels
              className={styles.customLabels}
              stockLevel={stockLevel}
              customLabels={_.take(activeCustomLabels, maxNoOfCustomLabel)}
              frontendPosition="1"
            />
          </div>

          {/* TODO: replace it with MultilineTitle, using `|` as line break */}
          <p
            className={combineClassNames([
              styles.contentText,
            ])}
          >
            {title}
          </p>
          {/* TODO: in `p` level, show sku.product.defaultColorOption.name */}
          {/* TODO: in `pc` level, show sku.colorOption.name */}
          {!colorOptionVariantType.hideVariant && (
            <p
              className={combineClassNames([
                styles.contentText,
                styles.colorSelect,
              ])}
            >
              {colorName}
            </p>
          )}
          {
            priceLoading
              ? (
                <></>
              )
              : (
                <PriceList
                  className={styles.price}
                  currency={currency}
                  // Show price for member if rank price provided by sku.meta
                  items={[
                    {
                      ...(!_.isNil(memberPrice) && { label: t('screens.product.price.nonMember') }),
                      sellPrice,
                      originalPrice,
                      suffix: priceSuffix,
                    },
                    ...!_.isNil(memberPrice)
                      ? [{
                        label: t('screens.product.price.member'),
                        sellPrice: memberPrice,
                        suffix: priceSuffix,
                      }] : [],
                  ]}
                  align="center"
                />
              )
          }
        </div>
      </Link>
      {
        showSwatches && (
          <SwatchList
            sku={sku}
            inventoryStoreCode={inventoryStoreCode}
            onItemClick={onSwatchItemClick}
            onMoreClick={onSwatchMoreClick}
            selectedColorOptionId={colorOptionId}
          />
        )
      }
      {
        compareData && enableComparisonEcom
          && (
          <Checkbox
            wrapperStyle={styles.checkbox}
            frontStyle={styles.front}
            checked={_.find(compareData, (obj) => obj.id === _.get(firstSkuOfSelectedColorOption, 'id', sku.id))}
            onChange={() => onAddToCompare(firstSkuOfSelectedColorOption || sku)}
            disabled={hasMoreThan && !_.find(compareData, (obj) => obj.id === sku.id)}
          >
            <p className={styles.textCheckbox}>{t('ui.productCard.compare')}</p>
          </Checkbox>
          )
      }
    </div>
  )
}

export default ProductCardView
