/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import _ from 'lodash'
import { useMemo } from 'react'
import {
  useOrderMethod,
} from 'react-omnitech-api'
import { useThemeConfig } from '../use-theme-config'
import env from '../../config/env'

// PUBLIC METHODS
function resolveConfig(value, defaultValue) {
  if (_.isString(value)) {
    return {
      ...defaultValue,
      [value]: true,
    }
  }
  if (_.isArray(value)) {
    return {
      ...defaultValue,
      ..._.reduce(value, (result, key) => {
        result = {
          ...result,
          [key]: true,
        }
        return result
      }, {}),
    }
  }
  if (_.isPlainObject(value)) {
    return {
      ...defaultValue,
      ...value,
    }
  }
  return defaultValue
}

function resolveSelectStoreApproach(value) {
  const defaultValue = {
    user: false, // Store select by user manually
    deliveryAddress: false, // Store select by delivery address lat long, e.g. zeek
    openTableUrl: false, // fnb, Open table by OT url
    checkout: false, // General retail approach
    // It will force delivery_type to shipped_form_warehouse,
    //   if user select warehouse as delivery store
    warehouse: false,
    none: false, // No pickup store required
  }

  return resolveConfig(value, defaultValue)
}

function resolveUiPosition(value) {
  const defaultValue = {
    'panel|dropdown': true, // Use dropdown to select order method if uiPosition is not set
    'panel|button': false,
  }

  return resolveConfig(value, defaultValue)
}

function resolveDisplay(value) {
  const defaultValue = {
    storeName: true,
    dineInTableNumber: false,
    dineInTableSubNumber: false,
  }

  return resolveConfig(value, defaultValue)
}

function useOrderMethodHook() {
  const apiOrderMethod = useOrderMethod()
  const {
    getConfig,
  } = useThemeConfig()

  const resolveOrderMethod = (om) => {
    const {
      deliveryTypeForWarehouse = 'shipped_form_warehouse',
      selectStoreBy,
      selectStoreApproach,
      uiPosition,
      display,
    } = om
    return {
      ...om,
      selectStoreApproach: resolveSelectStoreApproach(selectStoreBy || selectStoreApproach),
      uiPosition: resolveUiPosition(uiPosition),
      display: resolveDisplay(display),
      deliveryTypeForWarehouse,
    }
  }

  const availableOrderMethod = useMemo(() => (
    _.map(
      getConfig('config.availableOrderMethod', []),
      resolveOrderMethod,
    )
  ), [getConfig])

  const selectableOrderMethod = useMemo(() => (
    _.filter(
      availableOrderMethod,
      ({ uiPosition }) => (
        _.get(uiPosition, 'panel|dropdown', false)
      ),
    )
  ), [availableOrderMethod])

  const resolvedOrderMethod = useMemo(() => {
    const {
      orderMethod = {},
      ...rest
    } = apiOrderMethod
    const selectedOrderMethodCode = _.get(orderMethod, 'code')
    const fallbackOrderMethod = {
      ...resolveOrderMethod(orderMethod),
      commerceChannel: _.get(env, 'config.api.commerceChannel'),
    }
    const selectedOrderMethod = _.find(
      availableOrderMethod,
      { code: selectedOrderMethodCode },
    ) || fallbackOrderMethod
    return {
      orderMethod: selectedOrderMethod,
      ...rest,
    }
  }, [apiOrderMethod])

  return {
    availableOrderMethod,
    selectableOrderMethod,
    ...resolvedOrderMethod,
  };
}

export default useOrderMethodHook
