/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import _ from 'lodash'
import URI from 'urijs'
import {
  cancelRequest,
  useSystemSettings,
} from 'react-omnitech-api'
import camelCaseKeys from 'camelcase-keys'
import axios, { CancelToken } from 'axios'
import { useTranslation } from 'react-i18next'
import GoogleMapsContext from './google-maps-context'
// import CountryService from './country-service'

export default function GoogleMapsProvider({ apiKey, children }) {
  const { t, i18n } = useTranslation()
  const { getSystemSetting } = useSystemSettings()
  const preferredCountryCodes = getSystemSetting('country.preferred_country_codes')
  const googleGeocodeApi = 'https://maps.googleapis.com/maps/api/geocode/json'
  // const googlePlacesTextSearchApi = 'https://maps.googleapis.com/maps/api/place/textsearch/json'
  const googlePlacesFindplacefromtextApi = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json'
  // const apiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY

  const getLanguageCodeForGoogle = (locale) => {
    const languageCodesWithRegion = [
      'zh-CN',
      'zh-HK',
      'zh-TW',
      'en-AU',
      'en-GB',
      'fr-CA',
      'pt-BR',
      'pt-PT',
    ]
    return _.includes(languageCodesWithRegion, locale)
      ? locale
      : _.first(_.split(locale, '-'))
  }

  const languageCode = getLanguageCodeForGoogle(i18n.language)

  // PUBLIC METHODS

  function getGoogleMapURL(params) {
    // return `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&language=${languageCode}&libraries=geometry,places`
    const queries = {
      key: apiKey,
      v: '3.exp',
      language: languageCode,
      libraries: 'geometry,places',
      ...params,
    }
    const uri = new URI('https://maps.googleapis.com/maps/api/js')
    uri.search(queries)
    return uri.toString()
  }

  function search({ params, cancelKey = 'googleMapsServiceSearch', useCamelCase = true } = {}) {
    return _makeRequest({
      params,
      cancelKey,
      useCamelCase,
      url: googleGeocodeApi,
    })
  }

  function getPlaceSuggestions({
    text,
    cancelKey = 'googleMapsGetPlaceSuggestions',
    useCamelCase = true,
    ...params
  } = {}) {
    return _makeRequest({
      params: {
        // query: text,
        input: text,
        inputtype: 'textquery',
        fields: 'formatted_address,geometry',
        ...params,
      },
      cancelKey,
      useCamelCase,
      // url: googlePlacesTextSearchApi,
      url: googlePlacesFindplacefromtextApi,
    })
  }

  function cleanup() {
    cancelRequest.cancelAll([
      'googleMapsServiceSearch',
      'googleMapsGetPlaceSuggestions',
    ])
  }

  const getPreferedAddressFromSearch = ({ results, countryCode, defaultDeliveryCountry }) => {
    const preferedPremise = _.find(results, ({ types }) => (
      !_.isEmpty(_.intersection(types, [
        'subpremise',
        'premise',
      ]))
    ))
    const preferedAddress = (_.find(
      _.get(preferedPremise, 'addressComponents', []),
      ({ types }) => _.includes(types, 'route'),
    ) && preferedPremise
    ) || _.find(results, ({ types }) => (
      !_.isEmpty(_.intersection(types, [
        'street_address',
        'store',
        'point_of_interest',
        'route',
        'neighborhood',
      ]))
    ))
    const districtFallback = _.find(results, ({ types }) => _.includes(types, 'administrative_area_level_2'))
    const stateFallback = _.find(results, ({ types }) => _.includes(types, 'administrative_area_level_1'))

    const getAddressValueByType = (_address, type) => _.get(_.find(
      _.get(_address, 'addressComponents', []),
      ({ types }) => _.includes(types, type),
    ), 'longName', '')

    const streetLine1Value = getAddressValueByType(preferedPremise, 'premise')
    const streetLine2Route = getAddressValueByType(preferedAddress, 'route')
    const streetLine2StreetNumber = getAddressValueByType(preferedAddress, 'street_number')
    const streetLine2Value = _.isEmpty(streetLine2StreetNumber)
      ? t('ui.addressForm.map.autoComplete.streetLine2', {
        route: streetLine2Route,
      })
      : t('ui.addressForm.map.autoComplete.streetLine2WithStreetNumber', {
        route: streetLine2Route,
        streetNumber: streetLine2StreetNumber,
      })
    const districtValue = getAddressValueByType(preferedAddress, 'neighborhood')
                          || getAddressValueByType(districtFallback, 'administrative_area_level_2')
    const districtKey = mapValueToSelectableFieldKey({
      selectableField: 'districts',
      googleMapsValue: districtValue,
      countryCode,
    })
    const stateValue = getAddressValueByType(preferedAddress, 'administrative_area_level_1')
                        || getAddressValueByType(stateFallback, 'administrative_area_level_1')
    const stateKey = mapValueToSelectableFieldKey({
      selectableField: 'states',
      googleMapsValue: stateValue,
      countryCode,
    })
    const preferedAddressFields = _.compact([
      {
        name: 'streetLine1',
        value: streetLine1Value !== streetLine2Value ? streetLine1Value : '',
      },
      {
        name: 'streetLine2',
        value: streetLine2Value !== 'Unnamed Road' ? streetLine2Value : '',
      },
      {
        name: 'district',
        value: _.includes(_.get(defaultDeliveryCountry, 'selectableFields', []), 'district')
          ? districtKey
          : districtValue,
      },
      {
        name: 'state',
        value: _.includes(_.get(defaultDeliveryCountry, 'selectableFields', []), 'state')
          ? stateKey
          : stateValue,
      },
    ])
    return { preferedAddress, preferedAddressFields }
  }

  const mapValueToSelectableFieldKey = ({
    countryCode,
    selectableField,
    googleMapsValue,
  }) => {
    const defaultCountryCode = _.first(_.split(preferredCountryCodes, ' ')) || 'HK'
    // use system settings in future
    const possibleGoogleMapsValue = {
      HK: {
        states: {
          香港島: ['Hong Kong Island', 'Hong Kong', '香港', '香港島'],
          九龍: ['Kowloon', '九龍'],
          新界: ['New Territories', '新界'],
        },
      },
    }
    const stateKeys = _.get(possibleGoogleMapsValue, `${countryCode || defaultCountryCode}.${selectableField}`, {})
    return _.reduce(stateKeys, (result, value, key) => {
      if (_.includes(value, googleMapsValue)) {
        result = key
      }
      return result
    }, googleMapsValue)
  }

  const getUserCurrentPosition = (options = {}) => new Promise((resolve, reject) => {
    const geoOptions = {
      timeout: 20 * 1000, // 20 seconds
      ...options,
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject, geoOptions)
    } else {
      reject(new Error({ code: 4 }))
    }
  })

  // PRIVATE METHODS

  function _camelCaseKeys(json, format = true) {
    if (format) return camelCaseKeys(json, { deep: true })
    return json
  }

  function _makeRequest({
    // body = {},
    cancelKey = null,
    params = {},
    method = 'get',
    // path,
    useCamelCase = true,
    url,
    language = languageCode,
  } = {}) {
    const source = CancelToken.source();

    if (!_.isEmpty(cancelKey)) {
      cancelRequest.add(
        cancelKey,
        () => source.cancel(`${cancelKey}: Operation cancelled by user`),
      );
    }

    return axios({
      method,
      url,
      cancelToken: source.token,
      params: {
        ...params,
        language,
        key: apiKey,
      },
    })
      .then(({ data }) => _camelCaseKeys(data, useCamelCase));
  }

  const state = {
    cleanup,
    getGoogleMapURL,
    getLanguageCodeForGoogle,
    getPlaceSuggestions,
    getPreferedAddressFromSearch,
    getUserCurrentPosition,
    search,
  }

  // const state = {
  //   getPath,
  //   navigate: handleNavigate,
  //   recordNotFound: handleRecordNotFound,
  // };

  return (
    <GoogleMapsContext.Provider value={state}>
      {children}
    </GoogleMapsContext.Provider>
  )
}
