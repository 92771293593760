import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({

  termsStyle: {
    padding: '3.1rem 2rem 2rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '3.1rem 1.6rem 2rem',
    },
    '& h4': {
      fontSize: '1.3rem',
      margin: 0,
      fontWeight: 600,
      marginBottom: '1.5rem',
    },
    '& p': {
      fontSize: '1.2rem',
      lineHeight: '1.2em',
      color: Color(theme.colors.text).alpha(0.8).string(),
    },
  },
}))

export default useStyles
