/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import moment from 'moment'
import {
  useOrderMethod,
  useSystemSettings,
  useStores,
} from 'react-omnitech-api'

export default function useCurrentOrderingPeriod() {
  const { getSystemSetting } = useSystemSettings()
  const { store } = useOrderMethod()
  const { stores } = useStores()
  const vendorKey = getSystemSetting('vendor_specific_system_setting_key')

  let orderingPeriods = getSystemSetting(`vendor_specific_settings.${vendorKey}.ordering_periods`)
  // use store order_periods if available
  const storeCode = _.get(store, 'code')
  const storeOrderingPeriods = _.get(_.find(stores, { code: storeCode }), 'orderingPeriods', [])
  if (!_.isEmpty(storeOrderingPeriods)) orderingPeriods = storeOrderingPeriods

  const currentSlotCode = _.reduce(orderingPeriods, (result, orderingPeriod) => {
    const { slotCode, fromTime, toTime } = orderingPeriod
    const timeFormat = 'HH:mm'
    const from = moment(fromTime, timeFormat)
    const to = moment(toTime, timeFormat)
    if (moment().isBetween(from, to)) {
      result = slotCode
    }
    return result
  }, '')
  return currentSlotCode
}
