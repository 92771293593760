/* eslint-disable no-param-reassign */
import _ from 'lodash'
import URI from 'urijs'
import React, { useState, useEffect, useMemo } from 'react'
import 'reset-css'
import 'normalize.css'
import './layout.css'
import {
  useSystemSettings,
} from 'react-omnitech-api'
import {
  useCouponMarketplace,
  useI18n,
  useLink,
  useLocation,
  useOrderMethod,
  useOrderMethodModal,
} from '../../hook'
import LayoutView from './layout-view'

function LayoutController(props) {
  const [showOrderMethodBlocker, setShowOrderMethodBlocker] = useState(false)
  const { navigate } = useLink()
  const { location } = useLocation()
  const {
    orderMethod,
    setDeliveryAddress,
    setStore,
    selectableOrderMethod,
  } = useOrderMethod()
  const { getSystemSetting } = useSystemSettings()
  const { onCouponMarketplaceOpen } = useCouponMarketplace()
  const { availableLocales, currentLocale } = useI18n()
  const { open: openOrderMethodModal, close: closeOrderMethodModal, isOpen } = useOrderMethodModal()
  const userRegistrationFieldsConfig = getSystemSetting('account.user_registration_fields')

  const orderMethodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const commerceChannel = useMemo(() => _.get(orderMethod, 'commerceChannel'), [orderMethod])
  const commerceChannelFromUrl = useMemo(() => {
    const qs = new URI(_.get(location, 'href')).search(true)
    return _.get(qs, 'cc')
  }, [location])
  const isCommerceChannelNotMatch = useMemo(() => (
    !_.isEmpty(commerceChannelFromUrl) && !isOpen && commerceChannel !== commerceChannelFromUrl
  ), [commerceChannel, commerceChannelFromUrl])
  // const isCommerceChannelFromUrlValid = useMemo(() => {
  //   _.includes(_.map(selectableOrderMethod, 'commerceChannel'), commerceChannelFromUrl)
  // }, [commerceChannelFromUrl, selectableOrderMethod])

  const onLanguageChange = (locale) => {
    const path = _.get(location, 'pathname', '/').replace(/^\/[a-zA-Z-]+/, '')
    const search = _.get(location, 'search', '')
    if (!_.isEmpty(locale) && locale !== _.get(currentLocale, 'locale')) {
      navigate(
        `${path}${search}`,
        {
          locale,
          state: _.omit(
            _.get(location, 'state', {}),
            ['key'],
          ),
        },
      )
    }
  }
  // internal state
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const handelOpenSearch = () => {
    setIsOpenSearch(!isOpenSearch)
  }

  useEffect(() => {
    if (_.isEmpty(selectableOrderMethod)) return
    const pathsToCheck = [
      '',
      'account/favourites',
      'products',
      'products-all',
      'products-comparison',
      'product',
      'public-coupons',
      'cart',
    ]
    // FL: Make sure location.page is not an empty string
    const page = _.isEmpty(_.get(location, 'page')) ? '/' : _.get(location, 'page')
    const needToCheck = _.reduce(pathsToCheck, (result, pathToCheck) => {
      const regExp = new RegExp(`^/${pathToCheck}(/|$)`)
      if (result) return result
      // eslint-disable-next-line no-param-reassign
      result = regExp.test(page)
      return result
    }, false)
    if (isCommerceChannelNotMatch) {
      setShowOrderMethodBlocker(true)
      openOrderMethodModal({
        mode: 'commerceChannelNotMatch',
      })
    } else if (_.isEmpty(orderMethodCode)) {
      // Show/Hide order method dialog
      setDeliveryAddress({})
      setStore({})
      // FL: hide blocker if navigate to page that no need to block
      setShowOrderMethodBlocker(needToCheck)
      if (needToCheck) {
        openOrderMethodModal()
      } else {
        closeOrderMethodModal()
      }
    } else {
      setShowOrderMethodBlocker(false)
    }
  }, [
    selectableOrderMethod,
    orderMethodCode,
    isCommerceChannelNotMatch,
    _.get(location, 'page', ''),
  ])

  const viewProps = {
    ...props,
    onLanguageChange,
    availableLocales,
    currentLocale,
    isOpenSearch,
    handelOpenSearch,
    showOrderMethodBlocker,
    onCouponMarketplaceOpen,
    userRegistrationFieldsConfig,
    siteName: getSystemSetting('frontend.site_name'),
    siteUrl: getSystemSetting('frontend.site_url'),
  }
  return (
    <LayoutView {...viewProps} />
  )
}

export default LayoutController
