import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    margin: '0 auto',
    overflowX: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  menu: {
    width: 'fit-content',
    position: 'relative',
    margin: '0 auto',
    padding: 0,
    display: 'flex',
    listStyleType: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'none',
    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.primary,
      paddingBottom: '1rem',
      '&:hover': {
        color: theme.colors.hover,
        borderBottom: `2px solid ${theme.colors.hover}`,
      },
      '& p': {
        textTransform: 'lowercase',
        fontSize: 14,
        color: theme.colors.primary,
        '&:first-letter': {
          textTransform: 'capitalize !important',
        },
        '&:hover': {
          color: theme.colors.secondary,
        },
      },
    },
  },
  menuItem: {
    padding: [[0, 10]],
    height: '3.5rem',
    fontSize: '1.4rem',
    margin: 0,
    whiteSpace: 'nowrap',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[0, 20]],
    },
  },
  menuItemLarge: {
    padding: [[0, 60]],
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[0, 80]],
    },
  },
  active: {
    color: `${theme.colors.hover} !important`,
    borderBottom: `2px solid ${theme.colors.hover}`,
  },
}))

export default useStyles
