import _ from 'lodash'
import React from 'react'
import Link from '../link'
import combineClassNames from '../../helpers/combineClassNames'

import useStyles from './breadcrumb-style'

const BreadCrumbView = ({ links = [], classN }) => {
  const styles = useStyles()
  return (
    <ul className={combineClassNames([styles.breadcrumb, classN])}>
      {
        _.map(links, (link, index) => (
          <li className={styles.item} key={`breadcrumb-link-${index}`}>
            {link.url
              ? <Link to={link.url} gatsbyLink><p>{link.text}</p></Link>
              : <p>{link.text}</p>}
          </li>
        ))
      }
    </ul>
  )
}

export default BreadCrumbView
