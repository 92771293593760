import _ from 'lodash'
import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useTranslation } from 'react-i18next'
import { useThemeConfig } from '../../hook'
import Link from '../link';
import ProfileCard from '../profile-card';

// import ProfileCard from '../profile-card'
import useStyles from './account-menu-style';

const AccountMenuView = ({ handleOpenMemberCode }) => {
  const { t } = useTranslation()
  const { getConfig } = useThemeConfig()
  const {
    accountMenu, container, boxMenu, scrollStyle,
  } = useStyles()
  const defaultMenuConfig = [
    {
      id: 'account',
      path: '/account/profile/',
    },
    {
      id: 'shipping',
      path: '/account/addresses/',
    },
    {
      id: 'orders',
      path: '/account/orders/',
    },
    {
      id: 'rewards',
      path: '/account/my-coupons/',
    },
    {
      id: 'favourites',
      path: '/account/favourites/',
    },
  ]
  const menuConfig = getConfig('config.ui.accountMenu', defaultMenuConfig)
  return (
    <div className={accountMenu}>
      <div className={container}>
        <ProfileCard handleOpenMemberCode={handleOpenMemberCode} />
        <div className={boxMenu}>
          <ScrollContainer
            vertical={false}
            className={scrollStyle}
          >
            {
              _.map(menuConfig, ({ id, path }) => (
                <li key={`account_menu_${id}`}>
                  <Link
                    gatsbyLink
                    activeClassName="active"
                    to={path || `/account/${id}/`}
                  >
                    {t(`screens.account.menu.${id}`)}
                  </Link>
                </li>
              ))
            }
            <li>
              {'     '}
            </li>
          </ScrollContainer>

        </div>
      </div>
    </div>

  )
}

export default AccountMenuView
