/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react'
import _ from 'lodash'
import Link from '../../../link'
import useStyles from './submenu-style'

const SubmenuView = ({
  isOpen, data, t, onSubMenuClose,
}) => {
  // local variable
  const items = _.get(data, 'children', [])
  const itemWithChildren = _.filter(items, (item) => !_.isEmpty(item.children))
  const itemWithoutChildren = _.filter(items, (item) => _.isEmpty(item.children))

  // style
  const styles = useStyles({ isOpen })
  const linkProps = {
    gatsbyLink: true,
    onClick: () => onSubMenuClose(),
  }

  return (
    <div
      className={styles.containerFluid}
    >
      <div
        className={styles.container}
      >
        {
          !_.isEmpty(itemWithoutChildren) && (
            <div className={styles.boxCategory}>
              <h4>{t('ui.navbar.submenu.shopBy')}</h4>
              <ul className={itemWithoutChildren.length > 5 ? styles.twoColumns : ''}>
                {
                  itemWithoutChildren.map((item) => (
                    <li key={item.key}>
                      <Link to={item.url} {...linkProps}>
                        {item.text}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
        {
          itemWithChildren.map((item) => (
            <div key={item.key} className={styles.boxCategory}>
              <Link
                to={item.url}
                {...linkProps}
              >
                <h4>{item.text}</h4>
              </Link>
              <ul className={item.children.length > 5 ? styles.twoColumns : ''}>
                { item.children.length > 0
                  ? item.children.map(
                    (child) => (
                      <li key={child.key}>
                        <Link
                          to={child.url}
                          {...linkProps}
                        >
                          {child.text}
                        </Link>
                      </li>
                    ),
                  )
                  : (
                    <li>
                      <Link
                        to={item.url}
                        {...linkProps}
                      >
                        {t('ui.header.menu.all')}
                      </Link>
                    </li>
                  )}
              </ul>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default SubmenuView
