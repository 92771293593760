/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import _ from 'lodash'
import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { useSystemSettings } from 'react-omnitech-api'
import { useCouponMarketplace, useModal, useShare } from '../../hook'
import useStyles from './mini-cart-coupons-style'
import Loading from '../loading'
import {
  CouponMarketplaceCouponDetail,
  CouponMarketplaceCouponTokenDetail,
  CouponMarketplacePopup,
  CouponMarketplaceTabs,
  CouponMarketplaceModal,
} from './components/index'

export default function MiniCartCouponView({
  cartCouponTokens,
  onApplyCouponWithCouponMarketplace,
  isOutsideCheckout,
}) {
  const {
    canSelfUse,
    coupon,
    coupons,
    couponToken,
    couponTokens,
    couponUsage,
    hasMoreCoupons,
    hasMoreCouponTokens,
    isCouponMarketplaceOpen,
    isCouponsReady,
    isCouponRedeemed,
    isCouponTokensReady,
    isCouponDetailOpen,
    isCouponTokenDetailOpen,
    isDetailReady,
    isLoading,
    isModalOpen,
    isPopupOpen,
    secretQrCodeValue,
    showQrCodeMask,
    showQrCode,
    showSecretQrCode,
    modalContent,
    popupMessage,
    transferLoading,
    userCurrentPoints,
    onFetchCouponsNextPage,
    onFetchCouponTokensNextPage,
    onApplyCoupon,
    onApplyCouponError,
    onApplyCouponSuccess,
    onCouponDetailClose,
    onCouponDetailOpen,
    onCouponMarketplaceClose,
    onPopupClose,
    onRedeemCoupon,
    onRequireCouponSecret,
    onTransferIn,
    onTransferOut,
    onTransferUndo,
  } = useCouponMarketplace()
  const modal = useModal()
  const { share } = useShare()
  const { t } = useTranslation()
  const { getSystemSetting } = useSystemSettings()

  const isTransferable = _.get(coupon, 'isTransferable', false)
  const appUrl = getSystemSetting('frontend.app_url')

  /**
   * handleError
   * show error message via alert
   */
  function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  const handleApplyCoupon = async (couponTokenToken) => {
    try {
      onApplyCoupon()
      await onApplyCouponWithCouponMarketplace(couponTokenToken)
      onApplyCouponSuccess()
    } catch (error) {
      onApplyCouponError(error)
    }
  }

  const handleRequireCouponSecret = () => {
    const key = isTransferable ? 'requireTransferableCouponSecret' : 'requireCouponSecret'
    modal.open({
      title: t(`ui.minicartCoupons.modalDialog.${key}.title`),
      message: t(`ui.minicartCoupons.modalDialog.${key}.text`),
      buttons: [
        {
          text: t(`ui.minicartCoupons.modalDialog.${key}.buttons.confirm`),
          onClick: () => {
            // close modal
            modal.close()
            onRequireCouponSecret()
          },
        },
        {
          text: t(`ui.minicartCoupons.modalDialog.${key}.buttons.dismiss`),
          onClick: () => modal.close(),
        },
      ],
    })
  }

  const handleTransfer = (type) => {
    switch (type) {
      case 'in':
        onTransferIn()
          .then(({ id }) => {
            onCouponDetailClose()
            onCouponDetailOpen({
              id,
              type: 'couponToken',
            })
          })
        break;
      case 'out':
        onTransferOut()
          .then(({ transferToken }) => {
            handleShare({ transferToken })
          })
          .catch((error) => {
            handleError(error)
          })
        break;
      case 'undo':
        onTransferUndo()
        break;

      default:
        break;
    }
  }

  const handleResendShareMessage = () => {
    handleShare()
  }

  const handleShare = ({
    transferToken: tfrTkn,
  } = {}) => {
    const transferToken = tfrTkn || _.get(couponToken, 'transferToken')
    if (_.isEmpty(transferToken)) return
    share({
      modalDialogMessage: t('ui.minicartCoupons.modalDialog.transferOut.text'),
      modalDialogTitle: t('ui.minicartCoupons.modalDialog.transferOut.title'),
      message: t('ui.minicartCoupons.share.transferOut.text'),
      title: t('ui.minicartCoupons.share.transferOut.title'),
      url: `${appUrl}/user/coupons/tokens/${_.get(couponToken, 'token')}?transfer=${transferToken}&couponId=${_.get(couponToken, 'coupon.id')}`,
      imageUrl: _.get(coupon, 'defaultImage.versions.webSmall') || _.get(coupon, 'defaultImage.versions.galleryLarge'),
    })
  }

  useEffect(() => {
    document.body.classList[isCouponMarketplaceOpen ? 'add' : 'remove']('modal-open')
    // if (!isCouponMarketplaceOpen) {
    //   document.body.scrollTop = currenctScrollTop // for safari
    //   document.documentElement.scrollTop = currenctScrollTop // for IE/Firefox/...others
    // }
  }, [isCouponMarketplaceOpen])

  useEffect(() =>
    // remove modal-open class from body when componentDidMount
    () => {
      document.body.classList.remove('modal-open')
      document.getElementsByTagName('body')[0].setAttribute('style', '')
    },
  [])

  // Styles
  const styles = useStyles({ isCouponMarketplaceOpen })

  return (
    <>
      <CSSTransition
        in={isCouponMarketplaceOpen}
        timeout={300}
        unmountOnExit
        classNames="mini-cart"
      >
        <div className={styles.miniCard}>
          <CouponMarketplaceTabs
            coupons={coupons}
            couponTokens={couponTokens}
            hasMoreCoupons={hasMoreCoupons}
            hasMoreCouponTokens={hasMoreCouponTokens}
            isCouponsReady={isCouponsReady}
            isCouponTokensReady={isCouponTokensReady}
            userCurrentPoints={userCurrentPoints}
            onFetchCouponsNextPage={onFetchCouponsNextPage}
            onFetchCouponTokensNextPage={onFetchCouponTokensNextPage}
          />
          <CouponMarketplaceCouponDetail
            coupon={coupon}
            isCouponRedeemed={isCouponRedeemed}
            isDetailOpen={isCouponDetailOpen}
            isDetailReady={isDetailReady}
            isLoading={isLoading}
            userCurrentPoints={userCurrentPoints}
            onCouponDetailClose={onCouponDetailClose}
            onRedeemCoupon={onRedeemCoupon}
            onTransferIn={_.bind(handleTransfer, null, 'in')}
          />
          <CouponMarketplaceCouponTokenDetail
            canSelfUse={canSelfUse}
            showSecretQrCode={showSecretQrCode}
            cartCouponTokens={cartCouponTokens}
            couponToken={couponToken}
            couponUsage={couponUsage}
            secretQrCodeValue={secretQrCodeValue}
            isDetailOpen={isCouponTokenDetailOpen}
            isDetailReady={isDetailReady}
            isLoading={isLoading}
            isOutsideCheckout={isOutsideCheckout}
            showQrCodeMask={showQrCodeMask}
            showQrCode={showQrCode}
            userCurrentPoints={userCurrentPoints}
            transferLoading={transferLoading}
            onApplyCoupon={handleApplyCoupon}
            onCouponDetailClose={onCouponDetailClose}
            onRequireCouponSecret={handleRequireCouponSecret}
            onShare={handleResendShareMessage}
            onTransferIn={_.bind(handleTransfer, null, 'in')}
            onTransferOut={_.bind(handleTransfer, null, 'out')}
            onTransferUndo={_.bind(handleTransfer, null, 'undo')}
          />
          <CouponMarketplaceModal
            isModalOpen={isModalOpen}
            onCouponMarketplaceClose={onCouponMarketplaceClose}
            {...modalContent}
          />
          <CouponMarketplacePopup
            isOpen={isPopupOpen}
            message={popupMessage}
            onClose={onPopupClose}
          />
        </div>
      </CSSTransition>
      <div className={styles.overlap} onClick={onCouponMarketplaceClose} />
      {
        isLoading && <Loading />
      }
    </>
  )
}
