import _ from 'lodash'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    paddingTop: '3rem',
    backgroundColor: '#fff',
    maxWidth: theme.config.backgroundUrl ? 1420 : '100%',
    margin: [[0, 'auto']],
  },
  containerLines: {
    borderBottom: theme.config.footerUpColorText
      ? `1px solid ${theme.config.footerUpColorText}`
      : `1px solid ${theme.colors.disabled}`,
    borderTop: theme.config.footerUpColorText
      ? `1px solid ${theme.config.footerUpColorText}`
      : `1px solid ${theme.colors.disabled}`,
    padding: '3rem 1.5rem',
    backgroundColor: theme.config.footerUpBackgroundColor
      ? theme.config.footerUpBackgroundColor
      : theme.colors.background,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1170,
    margin: [[0, 'auto']],
    minHeight: 132,
    '& h1': {
      margin: 0,
      maxWidth: 152,
    },
  },
  contentLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1.5rem',
    '& section': {
      paddingBottom: 0,
      flex: '1 1 50%',
      marginBottom: 10,
    },
    '& h5': {
      marginBottom: 6,
      textTransform: 'uppercase',
      fontWeight: 400,
      color: theme.config.footerUpColorText
        ? theme.config.footerUpColorText
        : theme.colors.primary,
    },
    '& li a': {
      marginBottom: 0,
      fontWeight: 300,
      color: theme.config.footerUpColorText
        ? theme.config.footerUpColorText
        : theme.colors.disabled,
    },
  },
  containerLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 35,
    paddingBottom: 35,
    backgroundColor: theme.config.footerDownBackgroundColor
      ? theme.config.footerDownBackgroundColor
      : theme.colors.background,
    '& span': {
      fontSize: 14,
      color: theme.config.footerDownColorText
        ? theme.config.footerDownColorText
        : theme.colors.primary,
    },
  },
  containerLinksFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    paddingBottom: 90,
    backgroundColor: theme.config.footerDownBackgroundColor
      ? theme.config.footerDownBackgroundColor
      : theme.colors.background,
  },
  boxLogo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginRight: '1.5rem',
      marginLeft: '1.5rem',
      width: '37rem',
    },
  },
  logo: {
    width: '100%',
    height: (props) => (`${props.logoMinHeightMobile}px`),
    maxWidth: '17.5rem',
    margin: 0,
    textAlign: 'center',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      width: '100%',
      height: (props) => (`${props.logoMinHeightDesktop}px`),
      maxWidth: '37.5rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: '80%',
      height: (props) => (`${props.logoMinHeightDesktop}px`),
      maxWidth: '37.5rem',
    },
  },
  logoImage: {
    display: 'block',
    width: '100%',
    minHeight: '3.4rem',
    height: '100%',
    margin: 0,
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundImage: (props) => (`url(${props.siteLogo})`),
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      maxWidth: '100%',
      minHeight: '6.6rem',
    },
  },
  socialLink: {
    display: 'block',
    flex: '1 1 50%',
  },
  witgetLink: {
    flex: '1 1 100%',
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: 'auto',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      '& > div': {
        width: '100%',
      },
    },
  },
  socialLinkLogo: {
    display: 'none',
  },
  bottomBar: {
    backgroundColor: _.get(theme, 'config.ui.footer.bottomBar.backgroundColor', '#000000'),
    color: _.get(theme, 'config.ui.footer.bottomBar.textColor', '#ffffff'),
    textAlign: 'center',
    padding: '0 1rem',
  },
  bottomBarContent: {
    color: 'currentColor',
    lineHeight: '1.2em',
    padding: '0.4em 0',
  },
  appVersion: {
    fontSize: '1rem',
    padding: '1rem',
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    containerFluid: {
      width: '100%',
      paddingTop: '3rem',
    },
    container: {
      flexDirection: 'row',
    },
    socialLink: {
      display: 'none',
    },
    containerLines: {
      padding: 30,
    },
    contentLinks: ({ hasWidgetFacebook }) => ({
      flex: '1 1 55%',
      '& section': {
        paddingBottom: 30,
        flex: hasWidgetFacebook
          ? '1 1 33.33333333%'
          : '1 1 25%',
        marginBottom: 10,
        padding: '0 10px',
        '& > div': {
          margin: 0,
          padding: 0,
        },
      },
    }),
    witgetLink: {
      flex: '1 1 45%',
      justifyContent: 'flex-end',
      textAlign: 'left',

    },
    containerLogo: {
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      minHeight: '25rem',
    },
    socialLinkLogo: {
      display: 'block',
      minHeight: '25rem',
      width: '15.5rem',
      paddingTop: '6.5rem',
      margin: [[0, 0, 0, '2rem']],
      '& > div': {
        padding: 0,
      },
      '& h5': {
        color: theme.config.footerUpColorText
          ? theme.config.footerUpColorText
          : theme.colors.primary,
      },
      '&:empty': {
        display: 'none',
      },
    },
  },
}))

export default useStyles
