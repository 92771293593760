/**
 * Create modal context for custom hook
 */
import { createContext } from 'react'

const OrderMethodModalContext = createContext({
  close: () => {},
  isOpen: false,
  mode: 'default',
  onContinue: () => {},
  onRequestClose: () => {},
  open: () => {},
})

export default OrderMethodModalContext
