/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  forwardRef,
} from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  // StandaloneSearchBox,
} from 'react-google-maps';
import { useTranslation } from 'react-i18next'
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox'
// import AsyncSelect from 'react-select/async'
import {
  useGoogleMaps,
} from '../../hook'
import {
  isNullOrUndefined,
} from '../../helpers'
import LoadingSpinner from '../loading-spinner'
import useStyles from './address-form-style'
import MarkerIcon from '../../assets/icons/icon_map_marker.inline.svg'

function parseBounds(bounds) {
  const { google } = window
  if (!google) return
  if (!bounds) return
  return new google.maps.LatLngBounds(
    new google.maps.LatLng(_.get(bounds, 'southwest.lat'), _.get(bounds, 'southwest.lng')),
    new google.maps.LatLng(_.get(bounds, 'northeast.lat'), _.get(bounds, 'northeast.lng')),
  )
}

const GoogleMapWithMapRef = withScriptjs(withGoogleMap((props) => {
  const {
    mapRef,
    ...googleMapProps
  } = props
  return (
    <GoogleMap
      ref={mapRef}
      {...googleMapProps}
    />
  )
}))
const Map = forwardRef(
  (props, ref) => <GoogleMapWithMapRef {...props} mapRef={ref} />,
)
const SearchInputWithRef = withScriptjs((props) => {
  const {
    inputRef,
    ...inputProps
  } = props
  return (
    <StandaloneSearchBox
      ref={inputRef}
      {...inputProps}
    />
  )
})
const SearchInput = forwardRef(
  (props, ref) => <SearchInputWithRef {...props} inputRef={ref} />,
)
const SearchBox = (props) => {
  const {
    countryBounds,
    googleMapURL,
    onPlaceSelect,
  } = props
  const inputRef = useRef()
  const styles = useStyles()
  const bounds = parseBounds(countryBounds)
  const { t } = useTranslation()
  return (
    <SearchInput
      ref={inputRef}
      bounds={bounds}
      googleMapURL={googleMapURL}
      loadingElement={<div className={styles.mapLoading}><LoadingSpinner /></div>}
      containerElement={<div className={styles.mapElementContainer} />}
      onPlacesChanged={() => {
        const places = inputRef.current.getPlaces()
        if (_.isFunction(onPlaceSelect)) onPlaceSelect(_.first(places))
      }}
    >
      <input
        type="text"
        placeholder={t('ui.addressForm.map.search.placeholder')}
        className={styles.mapSearchBox}
      />
    </SearchInput>
  )
}

// Example only, should be override in theme project
function AddressFormGoogleMap(props, ref) {
  const {
    defaultCenter,
    defaultDeliveryCountry,
    defaultOptions,
    onCenterChanged,
    onClick,
    onDrag,
    onDragEnd,
    onDragStart,
    onDblClick,
    onPlaceSelect,
    onUserCenterChanged,
    onZoomChanged,
    ...mapProps
  } = props

  const [userPosition, setUserPosition] = useState()
  const [countryBounds, setCountryBounds] = useState()
  const {
    cleanup,
    getGoogleMapURL,
    getUserCurrentPosition,
    search,
  } = useGoogleMaps()
  const googleMapURL = getGoogleMapURL({ region: _.get(defaultDeliveryCountry, 'alpha2') })
  const mapRef = useRef(ref)
  const styles = useStyles()

  const mapDefaultCenter = useMemo(() => {
    const { lat, lng } = defaultCenter
    if (isNullOrUndefined(lat) || isNullOrUndefined(lng)) {
      return {
        lat: _.get(defaultDeliveryCountry, 'lat', 22.396428),
        lng: _.get(defaultDeliveryCountry, 'long', 114.109497),
      }
    }
    return defaultCenter
  }, [defaultCenter, defaultDeliveryCountry])

  useEffect(() => {
    cleanup()
    const { lat, lng } = userPosition || mapDefaultCenter
    // get country bounds
    search({
      params: {
        latlng: `${lat},${lng}`,
      },
      cancelKey: 'mapSearch',
    })
      .then(({ results, status }) => {
        if (!_.isEmpty(results) && status === 'OK') {
          const bounds = _.get(
            _.find(
              results,
              ({ types = [] }) => (
                !_.isEmpty(
                  _.intersection(
                    types,
                    _.isEmpty(userPosition) && isNullOrUndefined(defaultCenter.lat)
                      ? ['country']
                      : ['establishment', 'point_of_interest', 'premise', 'route', 'neighborhood'],
                  ),
                )
              ),
            ),
            'geometry.viewport',
          )
          setCountryBounds(bounds)
          if (!mapRef.current || _.isEmpty(bounds)) return
          mapRef.current.fitBounds(parseBounds(bounds))
        }
      })
  }, [userPosition, mapRef.current])

  useEffect(() => {
    const { lat, lng } = defaultCenter
    if (isNullOrUndefined(lat) || isNullOrUndefined(lng)) {
      (async () => {
        try {
          const pos = await getUserCurrentPosition()
          const userCenter = {
            lat: _.get(pos, 'coords.latitude'),
            lng: _.get(pos, 'coords.longitude'),
          }
          setUserPosition(userCenter)
          onUserCenterChanged(userCenter)
        } catch (error) {
          // error.code can be:
          //   0: unknown error
          //   1: permission denied
          //   2: position unavailable (error response from location provider)
          //   3: timed out
          //   4: browser not supported
          console.log('///// error code ....', error.code)
        }
      })()
    }
  }, [])

  const getPosition = () => {
    if (
      _.get(mapRef, 'current')
      && _.isFunction(mapRef.current.getCenter)
      && _.isFunction(mapRef.current.getZoom)
    ) {
      const { lat, lng } = mapRef.current.getCenter()
      return {
        lat: lat(),
        lng: lng(),
        zoom: mapRef.current.getZoom(),
      }
    }
  }
  const handleReturnCenterEvent = (event) => {
    if (_.isFunction(props[event])) {
      props[event](getPosition())
    }
  }
  const mapEvents = {
    onCenterChanged: () => handleReturnCenterEvent('onCenterChanged'),
    onClick: () => handleReturnCenterEvent('onClick'),
    onDrag: () => handleReturnCenterEvent('onDrag'),
    onDragEnd: () => handleReturnCenterEvent('onDragEnd'),
    onDragStart: () => handleReturnCenterEvent('onDragStart'),
    onDblClick: () => handleReturnCenterEvent('onDblClick'),
    onZoomChanged: () => handleReturnCenterEvent('onZoomChanged'),
  }

  const handlePlaceSelect = (place) => {
    if (_.isFunction(onPlaceSelect)) onPlaceSelect(place)
    const geometryViewport = _.get(place, 'geometry.viewport', {})
    const {
      ne,
      sw,
    } = {
      ne: geometryViewport.getNorthEast(),
      sw: geometryViewport.getSouthWest(),
    }
    // use Viewport as bounds
    const viewport = {
      southwest: sw.toJSON(),
      northeast: ne.toJSON(),
    }
    const bounds = parseBounds(viewport)
    if (!bounds || !place || !mapRef) return
    mapRef.current.fitBounds(bounds)
  }

  return (
    <div>
      <SearchBox
        defaultDeliveryCountryCode={_.get(defaultDeliveryCountry, 'alpha2')}
        googleMapURL={googleMapURL}
        countryBounds={countryBounds}
        onPlaceSelect={handlePlaceSelect}
      />
      <div className={styles.mapContainer}>
        <Map
          ref={mapRef}
          googleMapURL={googleMapURL}
          loadingElement={<div className={styles.mapLoading}><LoadingSpinner /></div>}
          containerElement={<div className={styles.mapElementContainer} />}
          mapElement={<div style={{ height: '100%' }} />}
          zoom={12}
          defaultCenter={mapDefaultCenter}
          defaultOptions={{
            disableDefaultUI: true,
            keyboardShortcuts: false,
            gestureHandling: 'greedy',
            styles: [
              {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'road.highway',
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'transit',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ],
            ...defaultOptions,
          }}
          {...mapEvents}
          {...mapProps}
        />
        <div className={styles.mapMarker}>
          <MarkerIcon className={styles.mapMarkerIcon} />
        </div>
      </div>
    </div>
  )
}
export default forwardRef(AddressFormGoogleMap)
