import {
  createUseStyles,
} from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    ':root': {
      '--swiper-theme-color': theme.colors.secondary,
      '--swiper-navigation-color': theme.colors.primary,
      '--swiper-pagination-bottom': '0.5rem',
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        '--swiper-pagination-bottom': '3rem',
      },
    },
  },
  carousel: (props) => ({
    margin: props.marginCarousel,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      margin: props.marginCarouselDesktop,
    },
    '&.heroCarousel': {
      margin: 0,
      maxWidth: '120rem',
      [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
        margin: '1.5rem',
      },
    },
  }),
  paginationBullet: {
    '.swiper-pagination-horizontal.swiper-pagination-bullets &': {
      margin: '0 1rem',
    },
  },
  boxImg: (props) => ({
    padding: props.paddingImg,
    overflow: 'hidden',
    // eslint-disable-next-line quotes
    [`@media (min-width: 768px)`]: {
      padding: props.paddingImgDesktop,
    },
  }),
  image: {
    margin: 0,
    display: 'block',
  },
  productItem: {
    maxWidth: '20rem',
  },
}))

export default useStyles
