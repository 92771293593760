/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next'
import Link from '../link'
import { useMenu } from '../../hook/use-menu'
import combineClassNames from '../../helpers/combineClassNames'
import useStyle from './navbar-style'
import SubmenuView from './components'

const NavBarView = (props) => {
  const {
    containerStyle,
    menuStyle,
    menuItemStyle,
    menuItemActiveStyle,
  } = props
  const { t } = useTranslation()
  const [dataMenu, setDataMenu] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const { menuData = [] } = useMenu()
  const numberOfDepartaments = menuData.length
  const {
    container, containerFluid, menu, menuItem, menuItemLarge, active,
  } = useStyle({ numberOfDepartaments })
  let setTiming
  const onSubMenuOpen = (data = {}) => {
    const { children } = data

    setTiming = setTimeout(() => {
      if (_.isEmpty(children)) {
        setIsOpen(false)
        setDataMenu([])
      } else {
        setDataMenu(data)
        setIsOpen(true)
      }
    }, 350)
  }

  const onSubMenuClose = useCallback(() => {
    setIsOpen(false)
    setDataMenu([])
  }, [dataMenu])

  return (
    <nav role="presentation" className={combineClassNames([container, containerStyle])} onMouseLeave={() => onSubMenuClose()}>
      <ul className={combineClassNames([menu, menuStyle])}>
        {
          menuData && menuData.map((item) => (
            <li
              role="presentation"
              className={combineClassNames([
                menuItem,
                menuItemStyle,
                numberOfDepartaments <= 5 && menuItemLarge,
                _.get(item, 'className'),
                item.active && _.get(item, 'classNameActive'),
                item.active && menuItemActiveStyle,
              ])}
              key={item.key}
              onMouseEnter={() => {
                onSubMenuOpen(item)
              }}
              onMouseLeave={() => {
                clearTimeout(setTiming)
              }}
            >
              {
                _.isEmpty(item.url)
                  ? <a className={item.active ? active : ''}>{item.text}</a>
                  : (
                    <Link
                      gatsbyLink={/^\/(?!\/)/.test(item.url)}
                      to={item.url}
                      className={item.active ? active : ''}
                      onClick={() => onSubMenuClose()}
                    >
                      {item.text}
                    </Link>
                  )
              }
            </li>
          ))
        }
      </ul>
      <CSSTransition
        in={isOpen}
        timeout={150}
        unmountOnExit
        classNames="sub-menu"
      >
        <SubmenuView
          data={dataMenu}
          t={t}
          onSubMenuOpen={onSubMenuOpen}
          onSubMenuClose={onSubMenuClose}
        />
      </CSSTransition>
    </nav>
  )
}

export default NavBarView
