import _ from 'lodash'
import React from 'react'
import Link from '../../../link'
import useStyles from './header-style'

const CustomHeader = ({
  node, onClick, style,
}) => {
  const url = _.get(node, 'url')
  const { linkStyle } = useStyles()

  return (
    <Link
      gatsbyLink={/^\/(?!\/)/.test(url)}
      to={url}
      onClick={onClick}
      className={linkStyle}
    >
      <div style={style.base}>
        <div style={{
          ...style.title,
          color: '#282828',
          fontSize: '14px',
        }}
        >
          {node.name}
        </div>

      </div>
    </Link>

  )
}

export default CustomHeader
