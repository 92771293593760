import moment from 'moment/min/moment-with-locales'
import React, { useEffect, useRef, useState } from 'react'
import combineClassNames from '../../helpers/combineClassNames'

import useStyle from './clock-style'

const ClockView = (props) => {
  // prepare hook
  const styles = useStyle()

  const {
    active = false,
    format = 'L LTS',
    className,
  } = props

  const [currentTime, setCurrentTime] = useState(moment().format(format))
  const timer = useRef(null)

  const getCurrentTime = () => moment().format(format)

  useEffect(() => {
    if (active) {
      timer.current = setInterval(() => {
        setCurrentTime(() => getCurrentTime())
      }, 1000)
    } else {
      clearInterval(timer.current)
    }
    return () => {
      clearInterval(timer.current)
    }
  }, [active])

  useEffect(() => (
    () => {
      clearInterval(timer.current)
    }
  ), [])

  return (
    <time
      className={combineClassNames([styles.clock, className])}
      dateTime={moment().format()}
    >
      {currentTime}
    </time>
  )
}

export default ClockView
