
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import {
  cancelRequest,
  useCoupons,
} from 'react-omnitech-api'
import CouponPublicShowView from './coupon-public-show-view'

const CouponPublicShow = (props) => {
  const {
    id,
  } = props
  // prepare hook
  const {
    fetchCouponPublicShow,
  } = useCoupons()

  const [loading, setLoading] = useState(false)
  const [coupon, setCoupon] = useState()

  /**
   * fetchCouponPublicShowApi
   * get coupon data for guest user
   */
  const fetchCouponPublicShowApi = useCallback(async () => {
    setLoading(true)
    try {
      // api call option
      const option = {
        id,
      }
      // call api
      const { coupon: data } = await fetchCouponPublicShow(option)
      setCoupon(data)
    } catch (error) {
      // const generalError = _.get(error, 'generalError', {})
      // if (generalError.code === 404) {
      //   recordNotFound()
      //   return
      // }
      // alert.show(generalError.message)
    } finally {
      setLoading(false)
    }
  }, [fetchCouponPublicShow, id])

  /**
   * load article content group when page loaded
   */
  useEffect(() => {
    fetchCouponPublicShowApi()

    return function fetchCouponPublicShowApiCleanUp() {
      cancelRequest.cancel([
        'fetchCouponPublicShow',
      ])
    }
  }, [fetchCouponPublicShowApi])

  const viewProps = {
    coupon,
    loading,
  }

  return (
    <CouponPublicShowView {...viewProps} />
  )
}

export default CouponPublicShow
