import _ from 'lodash'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import slice from 'lodash/fp/slice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSystemSettings } from 'react-omnitech-api'
import parseStockLevel from '../../helpers/parse-stock-level'
import CustomLabelsView from './custom-labels-view'

function CustomLabelsController(props) {
  const { t } = useTranslation()
  const {
    className,
    customLabels = [],
    frontendPosition = '1',
    stockLevel,
  } = props
  const { getSystemSetting } = useSystemSettings()
  const maxNoOfCustomLabels = _.min([getSystemSetting('custom_label.max_no_of_custom_label', 99), customLabels.length])
  const isSoldOut = parseStockLevel(stockLevel) <= 0
  const soldOutLabelOverrideAllExistingLabels = getSystemSetting('custom_label.sold_out_label_override_all_existing_labels', false)
  const soldOutLabelProps = {
    code: 'soldOut',
    name: t('ui.customLabels.soldOut'),
  }
  const filteredCustomLabels = flow(
    filter({ frontendPosition }),
    slice(0, maxNoOfCustomLabels),
  )(customLabels)

  const viewProps = {
    customLabels: _.compact([
      isSoldOut && soldOutLabelProps,
      ...(
        soldOutLabelOverrideAllExistingLabels && isSoldOut
          ? []
          : filteredCustomLabels
      ),
    ]),
    frontendPosition,
    className,
  }

  return !_.isEmpty(viewProps.customLabels) ? <CustomLabelsView {...viewProps} /> : null
}

export default CustomLabelsController
