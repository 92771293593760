/**
 * getLoginFormInitialValues
 * base on login approach to generate a initial value for registration form
 *
 * Parameters for the `email` approach:
 * `email`    Email used for later session login.
 * `password` Password used for later session login.
 *
 * Parameters for the `phone_password_v2` approach:
 * `phone`    Phone number used for later session login.
 * `password` Password used for later session login.
 * `token`    The SMS token obtained from calling the `sms_tokens` endpoint
 *            using the same device udid.
 *
 * Parameters for the `phone_and_sms_token` approach:
 * `phone`    Phone number used for later session login.
 * `token`    The SMS token obtained from calling the `sms_tokens` endpoint
 *            using the same device udid.
 *
 * @param approach
 */
export default function getLoginFormInitialValues({
  approach,
  defaultCountryCallingCode,
}) {
  let initialValues = {}
  switch (approach) {
    case 'phone_password_v2':
      initialValues = {
        countryCallingCode: defaultCountryCallingCode,
        phone: '',
        password: '',
        token: '',
      }
      break
    // does not use `phone_udid_v2` in ecom, only use in consumer app
    // case 'phone_udid_v2':
    //   break
    case 'phone_and_sms_token':
      initialValues = {
        countryCallingCode: defaultCountryCallingCode,
        phone: '',
        token: '',
      }
      break
    case 'email_and_email_token':
      initialValues = {
        email: '',
        token: '',
      }
      break
    case 'email':
    default:
      initialValues = {
        email: '',
        password: '',
      }
      break
  }
  return initialValues
}
