/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import createOmnitechApp from 'react-omnitech-api'
import ReactGA from 'react-ga'
import { Application } from './src/ui'
import { trackEvent } from './src/helpers'
import env from './src/config/env'

// create omnitech app instance, different feature will be available via react context
const OmnitechApp = createOmnitechApp()

/**
 * wrapPageElement
 *
 * wrapping OmnitechApp in each page to use Omnitech feature
 *
 * @param {*} { element, props }
 * @param {*} pluginConfig
 */
export const wrapPageElement = ({ element, props }, pluginConfig) => {
  const config = {
    ...env.config,
    ...pluginConfig.config,
  }
  return (
    <OmnitechApp
      config={config}
      currentLocale={props.pageContext.locale}
    >
      <Application
        config={config}
        {...props}
      >
        {element}
      </Application>
    </OmnitechApp>
  )
}

export const onRouteUpdate = ({ location }) => {
  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    if (pagePath) {
      trackEvent('routeUpdate', {}, { pagePath })
      ReactGA.pageview(pagePath)
    }
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  setTimeout(sendPageView, 100)
}

// eslint-disable-next-line import/prefer-default-export
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // const currentPosition = getSavedScrollPosition(location, location.key)
  getSavedScrollPosition(location, location.key)
  if (location.pathname === '/en-HK/products/') {
    return false
  }
  return true
}
