import _ from 'lodash'

/**
 * validateCartMax
 * validate cart before checkout
 *
 * @param {*} cart
 */
export default function validateCartMax(cart) {
  const { cartMaxNumOfSku = Infinity, cartMaxNumOfQty = Infinity, cartLineProperties = [] } = cart
  const mainProperties = _.filter(cartLineProperties, 'isMainProperty')
  const numOfSkus = _.size(_.uniq(_.map(mainProperties, 'skuId')))
  const totalQuantity = _.sumBy(mainProperties, 'quantity')

  const result = []

  // within max skus per cart
  if (numOfSkus > cartMaxNumOfSku) result.push('cartMaxNumOfSku')

  // within max quantity per cart
  if (totalQuantity > cartMaxNumOfQty) result.push('cartMaxNumOfQty')

  // finally, the cart is valid
  return _.isEmpty(result) ? true : result
}
