/* eslint-disable no-param-reassign */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'react-omnitech-api'
import HorizontalProductList from '../horizontal-product-list'
import ProductCard from '../product-card'
import Button from '../button'
import useCustomerRankSettings from '../../hook/use-customer-rank-settings'
import useStyles from './recently-viewed-products-style'

const RecentlyViewedProductsView = (props) => {
  const {
    ListHeaderComponent,
    onAfterInit,
    onBreakpoint,
    onClear,
    onClickTrackEvent,
    onNextLoad,
    onPageChange,
    pagination,
    skus,
    siblings,
    siblingsLoading,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  const {
    loyaltyInformation,
  } = useUser()
  const { default: defaultRank } = useCustomerRankSettings()
  const currentRank = _.get(loyaltyInformation, 'currentCustomerRank.code', defaultRank)

  const renderProduct = ({ item: sku, index }) => {
    const siblingsProductCodes = _.get(sku, 'product.meta.siblingsProductCodes', [])
    const thisSiblings = _.filter(siblings, (sibling) => (
      _.includes(siblingsProductCodes, _.get(sibling, 'product.code', '////'))
    ))
    const thisSiblingsLoading = !_.isEmpty(siblingsProductCodes)
      && _.isEmpty(thisSiblings)
      && siblingsLoading
    const haveSiblings = !_.isEmpty(thisSiblings)
    const priceSuffix = haveSiblings && t('screens.product.price.up')
    const lowestSellPriceSibling = _.reduce(thisSiblings, (result, sibling) => {
      if (
        _.toNumber(_.get(result, 'sellPrice', 0))
        > _.toNumber(_.get(sibling, 'sellPrice', 0))
      ) {
        result = sibling
      }
      return result
    }, _.first(thisSiblings))
    const displaySellPrice = haveSiblings
      ? _.get(lowestSellPriceSibling, 'sellPrice')
      : _.get(sku, 'colorOption.sellPrice', null)
    const displayOriginalPrice = haveSiblings
      ? _.get(lowestSellPriceSibling, 'originalPrice')
      : _.get(sku, 'colorOption.originalPrice', null)
    const displayMemberPrice = haveSiblings
      ? _.get(lowestSellPriceSibling, `meta.memberPrice.${currentRank}`)
      : _.get(sku, `meta.memberPrice.${currentRank}`)
    const stockLevel = _.get(
      sku,
      'product.stockLevel',
      _.get(
        sku,
        'colorOption.stockLevel',
        _.get(
          sku,
          'stockLevel',
          null,
        ),
      ),
    )
    return (
      <ProductCard
        colorOptionVariantType={_.get(sku, 'product.colorOptionVariantType', {})}
        imageUrl={_.get(sku, 'colorOption.images.0.versions.webLarge') || _.get(sku, 'colorOption.defaultImage.versions.webLarge')}
        title={_.get(sku, 'product.title')}
        colorName={_.get(sku, 'colorOption.name')}
        sellPrice={displaySellPrice}
        originalPrice={displayOriginalPrice}
        memberPrice={displayMemberPrice}
        priceSuffix={priceSuffix}
        priceLoading={thisSiblingsLoading}
        activeCustomLabels={_.get(sku, 'colorOption.activeCustomLabels')}
        colorOptionId={_.get(sku, 'colorOption.id')}
        favourite={_.get(sku, 'colorOption.favourite')}
        productId={_.get(sku, 'product.id')}
        url={_.get(sku, 'product.canonicalHref')}
        stockLevel={stockLevel}
        sku={sku}
        distinct="p"
        onClickTrackEvent={onClickTrackEvent}
        index={index + 1}
      />
    )
  }
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerComponent}>
          {ListHeaderComponent}
        </div>
        <div className={styles.headerControls}>
          <Button
            inline
            text={t('ui.recentlyViewedProducts.buttons.clear')}
            className={styles.clearButton}
            onClick={onClear}
          />
        </div>
      </div>
      <HorizontalProductList
        data={skus}
        renderItem={renderProduct}
        pagination={pagination}
        onAfterInit={onAfterInit}
        onBreakpoint={onBreakpoint}
        onEndReached={onNextLoad}
        onSlideChange={onPageChange}
      />
    </>
  )
}

export default RecentlyViewedProductsView
