/* eslint-disable react-hooks/exhaustive-deps */
/**
 * ModalProvider
 */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ModalContext from './modal-context'

export default function ModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState({})

  const open = (obj) => {
    setContent(() => (
      _.pick(obj, ['title', 'message', 'contentComponent', 'buttons', 'infoButton'])
    ))
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isOpen) {
      setContent({})
    }
  }, [isOpen])

  const state = {
    isOpen,
    open,
    close,
    ...content,
  }

  return (
    <ModalContext.Provider value={state}>
      {children}
    </ModalContext.Provider>
  )
}
