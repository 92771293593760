/* eslint-disable no-param-reassign */
import _ from 'lodash'

/*
 ** Turn style value from theme to style with media query support
 */
export default function getStyleFromTheme({
  theme,
  key,
  defaultValue,
  getValueFn = _.identity,
  styleProperty,
}) {
  if (_.isEmpty(theme) || _.isEmpty(key) || _.isEmpty(styleProperty)) return {}
  const value = _.get(theme, key, defaultValue)
  const mq = _.map(_.get(theme, 'mediaQueries', {}), (width, mqKey) => ({ width, mqKey }))
  const mqKeys = _.keys(_.get(theme, 'mediaQueries', {}))
  let style
  if (
    _.isObject(value)
    && !_.isEmpty(
      _.intersection(
        mqKeys,
        _.keys(value),
      ),
    )
  ) {
    const extendedStyles = _.reduce(
      _.sortBy(
        _.filter(mq, ({ mqKey }) => _.includes(_.keys(value), mqKey)),
        'width',
      ),
      (result, { mqKey, width }) => {
        if (_.isEmpty(result)) {
          result = [{
            [styleProperty]: getValueFn(_.get(value, mqKey), mqKey),
          }]
        } else {
          result = [
            ...result,
            {
              [`@media (min-width: ${width}px)`]: {
                [styleProperty]: getValueFn(_.get(value, mqKey), mqKey),
              },
            },
          ]
        }
        return result
      },
      [],
    )
    // Use an array of style objects with extend feature of jss-plugin-extend
    //  to make sure the order of media queries are preserved
    // ref: https://cssinjs.org/jss-plugin-extend?v=v10.10.0
    style = {
      extend: extendedStyles,
    }
  } else {
    style = {
      [styleProperty]: getValueFn(value),
    }
  }
  return style
}
