/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import URI from 'urijs'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useAuth,
  useDeliveryAddresses,
  useI18n,
  useStores,
  useSystemSettings,
} from 'react-omnitech-api'
import {
  useAlert,
  useCart,
  useLocation,
  useLink,
  useOrderMethod,
  usePriceTemplate,
} from '../../hook'
import OrderMethodModalView from './order-method-modal-view'

export default function OrderMethodModalController({
  isOpen = false,
  onRequestClose,
  onContinue,
  mode,
}) {
  const { getSystemSetting } = useSystemSettings()
  const { auth } = useAuth()
  const { navigate } = useLink()
  const { location } = useLocation()
  const alert = useAlert()
  const { t } = useTranslation()
  const { fetchStores, stores, storesReady } = useStores()
  const { code: CART_PRICE_TEMPLATE_KEY } = usePriceTemplate()
  const { currentLanguage } = useI18n()
  const {
    cartId,
    createCart,
    fetchCart,
    getParams,
    initCart,
    updateCart,
  } = useCart()
  const {
    setOrderMethod,
    setStore,
    setDeliveryAddress,
    orderMethod,
    deliveryAddress,
    selectableOrderMethod,
    store,
  } = useOrderMethod()
  const {
    deleteDeliveryAddresses,
    updateDeliveryAddresses,
    fetchDeliveryAddresses,
  } = useDeliveryAddresses()

  const origOrderMethodCode = useRef(_.get(orderMethod, 'code'))

  const isLoggedIn = useMemo(() => !_.isEmpty(_.toString(_.get(auth, 'userId', ''))), [auth])
  const themeConfig = getSystemSetting('theme.ecom.config', {})
  const orderMethodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const commerceChannel = useMemo(() => _.get(orderMethod, 'commerceChannel'), [orderMethod])
  const commerceChannelFromUrl = useMemo(() => {
    const qs = new URI(_.get(location, 'href')).search(true)
    return _.get(qs, 'cc')
  }, [location])
  const isCommerceChannelNotMatch = useMemo(() => (
    !_.isEmpty(commerceChannelFromUrl)
    && commerceChannel !== commerceChannelFromUrl
  ), [commerceChannel, commerceChannelFromUrl])

  const orderMethodOptions = useMemo(() => {
    const orderMethods = mode === 'commerceChannelNotMatch'
      ? _.filter(selectableOrderMethod, ['commerceChannel', commerceChannelFromUrl])
      : selectableOrderMethod
    return _.map(orderMethods, ({ code }) => ({
      label: t('ui.orderMethodModal.options', { context: code }),
      value: code,
    }))
  }, [
    selectableOrderMethod,
    isCommerceChannelNotMatch,
    commerceChannelFromUrl,
    mode,
    currentLanguage,
  ])

  const defaultOrderMethod = useMemo(() => (
    _.size(selectableOrderMethod) === 1
      ? _.first(selectableOrderMethod)
      : _.find(selectableOrderMethod, ['defaultMethod', true])
  ), [selectableOrderMethod])
  const defaultOrderMethodOption = useMemo(() => (
    defaultOrderMethod && {
      label: t('ui.orderMethodModal.options', {
        context: _.get(defaultOrderMethod, 'code'),
      }),
      value: _.get(defaultOrderMethod, 'code'),
    }
  ), [defaultOrderMethod])

  const origOrderMethodItem = useMemo(() => (
    _.find(orderMethodOptions, { value: origOrderMethodCode.current })
  ), [orderMethodOptions, origOrderMethodCode.current, isOpen])
  const initOrderMethodItem = useMemo(() => (
    origOrderMethodItem
    || defaultOrderMethodOption
    || _.first(orderMethodOptions)
  ), [orderMethodOptions, origOrderMethodItem, defaultOrderMethodOption])

  // const [cart, setCart] = useState()
  // const [stores, setStores] = useState([])
  const [storesLoading, setStoresLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(initOrderMethodItem)
  const [selectedStoreItem, setSelectedStoreItem] = useState()
  const [updatedDeliveryAddress, setUpdatedDevliveryAddress] = useState()
  // const [showAddressSelector, setShowAddressSelector] = useState(false)
  const [isAddressManagerOpen, setIsAddressManagerOpen] = useState(false)
  const [isChangeStoreDialogOpen, setIsChangeStoreDialogOpen] = useState(false)
  const [isChangeStoreConfirmed, setIsChangeStoreConfirmed] = useState(false)
  const [isNoStoreDialogOpen, setIsNoStoreDialogOpen] = useState(false)
  const [deliveryAddresses, setDeliveryAddresses] = useState([])
  const [deliveryAddressesLoading, setDeliveryAddressesLoading] = useState(false)
  const [
    updateOrderMethodByDefaultOrderMethod,
    setUpdateOrderMethodByDefaultOrderMethod,
  ] = useState(false)
  const [updateOrderMethodInProgress, setUpdateOrderMethodInProgress] = useState(false)
  const [updateCartShipmentInProgress, setUpdateCartShipmentInProgress] = useState(false)
  const [changeOrderMethodFromMenu, setChangeOrderMethodFromMenu] = useState(false)
  const [needUserConfirmToClearCartItems, setNeedUserConfirmToClearCartItems] = useState(false)

  const defaultOrderMethodWithNoSelectStoreApproach = useMemo(
    () => _.get(defaultOrderMethod, 'selectStoreApproach.none', false) && defaultOrderMethod,
    [defaultOrderMethod],
  )

  const isBlocker = useMemo(() => {
    if (_.isEmpty(orderMethod)) return true
    const { selectStoreApproach } = orderMethod
    return (
      (selectStoreApproach.deliveryAddress && _.isEmpty(deliveryAddress))
      || _.isEmpty(store)
    )
  }, [orderMethod, store, deliveryAddress])

  const isOrderMethodDialogOpen = useMemo(
    () => (
      isOpen
      && !isChangeStoreDialogOpen
      && !isNoStoreDialogOpen
      && (
        _.isEmpty(defaultOrderMethodWithNoSelectStoreApproach)
        || !_.isEmpty(orderMethodCode)
        || isCommerceChannelNotMatch
      )
      && !updateOrderMethodByDefaultOrderMethod
    ),
    [
      isOpen,
      isCommerceChannelNotMatch,
      isChangeStoreDialogOpen,
      isNoStoreDialogOpen,
      defaultOrderMethodWithNoSelectStoreApproach,
      orderMethodCode,
    ],
  )

  const selectedOrderMethod = useMemo(() => (
    _.find(selectableOrderMethod, { code: _.get(selectedItem, 'value') })
  ), [selectedItem, selectableOrderMethod])

  const storeOptions = useMemo(() => {
    const includeWarehouse = _.get(selectedOrderMethod, 'selectStoreApproach.warehouse')
    // filter here
    const storeListFilter = _.get(selectedOrderMethod, 'storeListFilter') || _.get(selectedOrderMethod, 'enableStores')
    const filteredStores = _.filter(stores, (st) => {
      // Skip filter if storeListFilter is not set
      if (_.isNil(storeListFilter)) return st

      const storeCode = _.get(st, 'code')
      // handle storeListFilter in string format
      if (_.isString(storeListFilter)) return _.isEqual(storeListFilter, storeCode)
      // handle storeListFilter in array format
      if (_.isArray(storeListFilter)) return _.includes(storeListFilter, storeCode)
      // handle storeListFilter in object format
      if (_.isObject(storeListFilter)) {
        const storeListCodes = _.transform(storeListFilter, (result, val, key) => {
          if (val) result.push(key)
        }, [])
        return _.includes(
          storeListCodes,
          // object keys in theme config will be transfromed to camel case
          _.camelCase(storeCode),
        )
      }
      return st
    })
    return (
      _.compact([
        includeWarehouse && {
          label: t('ui.orderMethodModal.storeOptions', { context: 'warehouse' }),
          value: 'warehouse',
        },
        ..._.map(filteredStores, ({ id, name }) => ({
          label: name,
          value: id,
        })),
      ])
    )
  }, [storesReady, stores, selectedOrderMethod])

  const selectedStore = useMemo(() => {
    const storeId = _.get(selectedStoreItem, 'value')
    if (storeId === 'warehouse') {
      return { id: 'warehouse' }
    }
    return _.find(stores, { id: storeId })
  }, [storesReady, stores, selectedStoreItem])

  const confirmButtonDisabled = useMemo(() => {
    if (_.isEmpty(selectedOrderMethod)) return true
    const { selectStoreApproach } = selectedOrderMethod
    return (
      (selectStoreApproach.deliveryAddress && _.isEmpty(deliveryAddress))
      || (!selectStoreApproach.none && _.isEmpty(selectedStore))
    )
  }, [selectedOrderMethod, selectedStore, deliveryAddress])

  const dirty = useMemo(() => (
    (!_.isEqual(_.get(selectedStore, 'code'), _.get(store, 'code')) // store updated
    || !_.isEmpty(updatedDeliveryAddress) // delivery address updated
    || !_.isEqual(origOrderMethodItem, selectedItem)) // order method updated
    && isOpen // reset to false if modal closed
  ), [
    selectedStore,
    store,
    updatedDeliveryAddress,
    origOrderMethodItem,
    selectedItem,
    isOpen,
  ])

  // const clearCartItemsActions = useMemo(() => {
  //   if (!needUserConfirmToClearCartItems) return []
  //   const cartLines = _.filter(_.get(cart, 'cartLineProperties', []), 'isMainProperty')
  //   return _.map(cartLines, ({
  //     sku,
  //     groupUuid,
  //     identifierUuid,
  //     parentIdentifierUuid,
  //   }) => ({
  //     actionType: 'update_cart_line_property',
  //     skuId: _.get(sku, 'id'),
  //     quantity: 0,
  //     quantityMode: 'fixed',
  //     productAddonId: null,
  //     groupUuid,
  //     identifierUuid,
  //     parentIdentifierUuid,
  //   }))
  // }, [cart, needUserConfirmToClearCartItems])

  const showAddressSelector = useMemo(() => (
    _.get(selectedOrderMethod, 'selectStoreApproach.deliveryAddress')
  ), [selectedOrderMethod])

  const showStoreSelector = useMemo(() => (
    _.get(selectedOrderMethod, 'selectStoreApproach.user')
  ), [selectedOrderMethod])

  const onChange = (value) => {
    setSelectedItem(value)
  }
  const onStoreChange = (value) => {
    setSelectedStoreItem(value)
  }
  const onConfirm = () => {
    setUpdateOrderMethodInProgress(true)
  }

  const updateOrderMethod = useCallback(async ({ cart: cartOverride } = {}) => {
    const {
      selectStoreApproach,
      deliveryType,
      deliveryTypeForWarehouse,
      commerceChannel: updatedCommerceChannel,
    } = selectedOrderMethod || {}
    const {
      id: selectedStoreId,
    } = selectedStore || {}
    const selectedDeliveryType = (selectedStoreId === 'warehouse' && deliveryTypeForWarehouse) || deliveryType
    const {
      id,
      availableDeliveryTypes = [],
      cartShipments = [],
    } = cartOverride || initCart || {}
    const firstCartShipment = _.first(cartShipments) || {}
    const {
      id: shipmentId,
    } = firstCartShipment
    const {
      id: deliveryAddressId,
    } = deliveryAddress || {}

    const availableDeliveryTypesCode = _.map(availableDeliveryTypes, 'code')
    const isSelectedDeliveryTypeAvailable = _.includes(
      availableDeliveryTypesCode,
      selectedDeliveryType,
    )

    let cartShipmentId = shipmentId
    if (_.isEmpty(cartShipmentId)) {
      try {
        // FL: fetch cart and retry if cart doesn't have shipment Id
        const { cart: data } = await fetchCart({
          params: {
            includes: ['cart_shipment_ids'],
          },
          commerceChannel: updatedCommerceChannel,
        })
        cartShipmentId = _.get(data, 'cartShipmentIds.0')
      } catch (errors) {
        if (/^(404|20302)$/.test(_.get(errors, 'generalError.code'))) {
          try {
            const newCreatedCart = await createCart({
              commerceChannel: updatedCommerceChannel,
            })
            const { cart: newCart } = await fetchCartApi({
              id: _.get(newCreatedCart, 'cart.id'),
              commerceChannel: updatedCommerceChannel,
            })
            return updateOrderMethod({ cart: newCart })
          } catch (error) {
            handleError(errors)
          }
        }
        handleError(errors)
      }
    }

    let updateDeliveryTypeActions = []
    const commonAction = {
      actionType: 'update_cart_shipment',
      id: cartShipmentId,
      ...(isSelectedDeliveryTypeAvailable && { deliveryType: selectedDeliveryType }),
    }
    let selectedStoreCode = _.get(selectedStore, 'code')
    try {
      if (selectStoreApproach.deliveryAddress) {
        const { stores: pickupStores } = await fetchStores({
          ..._.pick(deliveryAddress, ['lat', 'long']),
          includes: [
            'meta',
            'stores.address',
            'stores.banner_images',
            'today_open_time_slots',
          ],
          pageSize: 1,
          zeek: 1,
          cancelKey: 'fetchStoresOrderMethodModal',
        })
        if (_.isEmpty(pickupStores)) {
          setIsNoStoreDialogOpen(true)
        } else {
          const pickupStore = _.first(pickupStores)
          selectedStoreCode = _.get(pickupStore, 'code')
          // update store in localstorage (ensure only save store.id and store.code)
          setStore(_.pick(pickupStore, ['id', 'code']))
          updateDeliveryTypeActions = [{
            ...commonAction,
            ...(_.isEmpty(deliveryAddressId) ? {} : {
              // courierServiceId, // no courier service in uat
              deliveryAddressId,
              pickupStoreId: _.get(pickupStore, 'id'),
            }),
          }]
        }
      } else {
        updateDeliveryTypeActions = [{
          ...commonAction,
          ...(
            // reset store when select Store approach is none in selected order method
            _.includes(['none', 'warehouse'], selectedStoreId)
            || _.isNil(selectedStoreId)
              ? {
                pickupStoreId: null,
              }
              : {
                pickupStoreId: selectedStoreId,
              }
          ),
        }]
        // update store in localstorage (ensure only save store.id and store.code)
        setStore(_.isEmpty(selectedStore) ? {} : _.pick(selectedStore, ['id', 'code']))
      }
    } catch (error) {
      handleError(error)
    }

    // Update cart shipment
    try {
      if (_.isEmpty(id)) {
        throw new Error({ generalError: { code: 404 } })
      }
      await updateCart({
        // schemaType: 'takeAway',
        cartId: id,
        payload: {
          data: {
            actions: [
              // ...clearCartItemsActions,
              ...updateDeliveryTypeActions,
            ],
          },
          batchUpdateMode: 2,
        },
        params: getParams({
          includeGroups: ['basic'],
          ...(
            !_.isEmpty(selectedStoreCode) ? {
              inventoryStoreCodeEq: selectedStoreCode,
              priceStoreCodeEq: selectedStoreCode,
            } : {}
          ),
        }),
        commerceChannel: updatedCommerceChannel,
      })
      return true
    } catch (errors) {
      if (/^(404|20302)$/.test(_.get(errors, 'generalError.code'))) {
        try {
          const newCreatedCart = await createCart({
            commerceChannel: updatedCommerceChannel,
          })
          const { cart: newCart } = await fetchCartApi({
            id: _.get(newCreatedCart, 'cart.id'),
            commerceChannel: updatedCommerceChannel,
          })
          return updateOrderMethod({ cart: newCart })
        } catch (error) {
          handleError(errors)
        }
      }
      handleError(errors)
    }
  }, [
    initCart,
    cartId,
    createCart,
    deliveryAddress,
    fetchCartApi,
    fetchStores,
    handleError,
    selectedOrderMethod,
    selectedStore,
    setOrderMethod,
    setStore,
    updateCart,
  ])

  const handleUpdateOrderMethodSuccess = () => {
    setNeedUserConfirmToClearCartItems(false)
    changeUrlCommerceChannel(_.get(selectedOrderMethod, 'commerceChannel'))
    origOrderMethodCode.current = _.get(selectedOrderMethod, 'code')
    onRequestClose()
    if (_.isFunction(onContinue)) {
      onContinue()
    // } else {
    //   navigate('/products/', { replace: true })
    }
  }

  const changeUrlCommerceChannel = (cc) => {
    const currentPage = _.join([_.get(location, 'page', '/'), _.get(location, 'search')], '')
    navigate(currentPage, { replace: true, commerceChannel: cc })
  }

  useEffect(() => {
    if (!_.isEmpty(storeOptions)) {
      const initSelectedStoreItem = _.find(storeOptions, { value: _.get(store, 'id') })
      setSelectedStoreItem(initSelectedStoreItem)
    }
  }, [storeOptions])

  useEffect(() => {
    if (updateOrderMethodInProgress) {
      // Clear cart if cart is not empty
      if (needUserConfirmToClearCartItems && !isChangeStoreConfirmed) {
        setIsChangeStoreDialogOpen(true)
      } else {
        setIsChangeStoreConfirmed(true)
        handleUpdateOrderMethod()
        // setOrderMethod(selectedOrderMethod)
      }
    }
  }, [updateOrderMethodInProgress, needUserConfirmToClearCartItems, isChangeStoreConfirmed])

  useEffect(() => {
    setNeedUserConfirmToClearCartItems(
      _.size(_.get(initCart, 'cartLineProperties', [])) > 0
      && dirty
      && !isChangeStoreConfirmed,
    )
  }, [dirty, initCart])

  useEffect(() => {
    // if (!changeOrderMethodFromMenu || _.get(orderMethod, 'type') === 'dineInMenu') return
    if (!changeOrderMethodFromMenu) return
    proccessUpdateOrderMethod()
  }, [changeOrderMethodFromMenu, orderMethod, proccessUpdateOrderMethod])

  useEffect(() => {
    const selectStoreApproach = _.get(selectedOrderMethod, 'selectStoreApproach', {})
    const enabledSelectStoreApproach = _.keys(_.pickBy(selectStoreApproach, _.identity))
    if (
      _.every(enabledSelectStoreApproach, (val) => val === 'none')
      || _.isEmpty(enabledSelectStoreApproach)
    ) {
      setSelectedStoreItem({})
    }
  }, [selectedOrderMethod])

  useEffect(() => {
    if (!updateCartShipmentInProgress) return
    // FL: Make sure order method updated in localstorage
    if (_.get(selectedOrderMethod, 'code') !== orderMethodCode) return
    proccessUpdateOrderMethod()
  }, [
    updateCartShipmentInProgress,
    orderMethodCode,
    selectedOrderMethod,
    proccessUpdateOrderMethod,
  ])

  useEffect(() => {
    // Auto select if only one option available
    if (_.size(orderMethodOptions) > 1 || isCommerceChannelNotMatch) return
    setSelectedItem(_.first(orderMethodOptions))
  }, [orderMethodOptions, isCommerceChannelNotMatch])

  useEffect(() => {
    // Auto set orderMethod & update cart without open dialog if default is exist
    if (
      _.isEmpty(orderMethodCode)
      && !isCommerceChannelNotMatch
      && !_.isEmpty(_.get(initCart, 'cartShipments'))
      && !_.isEmpty(selectedOrderMethod)
      && !_.isEmpty(defaultOrderMethodWithNoSelectStoreApproach)
    ) {
      setOrderMethod(defaultOrderMethodWithNoSelectStoreApproach)
      setUpdateOrderMethodByDefaultOrderMethod(true)
    }
  }, [
    initCart,
    isCommerceChannelNotMatch,
    defaultOrderMethodWithNoSelectStoreApproach,
    orderMethodCode,
    selectedOrderMethod,
  ])

  useEffect(() => {
    if (updateOrderMethodByDefaultOrderMethod) {
      setUpdateCartShipmentInProgress(true)
    }
  }, [updateOrderMethodByDefaultOrderMethod])

  useEffect(() => {
    // Select order method after login
    if (_.has(location, 'state.preSelectedOrderMethod')) {
      setSelectedItem(_.get(location, 'state.preSelectedOrderMethod'))
    }
  }, [_.get(location, 'state.preSelectedOrderMethod')])

  useEffect(() => {
    if (isOpen) {
      setIsChangeStoreConfirmed(false)
    }
  }, [isOpen])

  useEffect(() => (
    function cleanUp() {
      cancelRequest.cancelAll(_.compact([
        'fetchStoresOrderMethodModal',
        'fetchCart',
        'fetchDeliveryAddresses',
      ]))
    }
  ), [])

  /**
   * handleError
   * show error message via alert
   */
  function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  /**
   * fetchCartApi
   * get cart from API
   */
  const fetchCartApi = useCallback(async ({ id, commerceChannel: cc } = {}) => {
    // api call option
    const options = _.omitBy({
      cartId: id || cartId,
      params: getParams({
        includeGroups: ['basic'],
      }),
      commerceChannel: cc,
    }, _.isNil)
    // call api
    const { cart: data } = await fetchCart(options)
    // setCart(data)
    return { cart: data }
  }, [CART_PRICE_TEMPLATE_KEY, fetchCart, cartId, handleError])
  // /**
  //  * fetchStoresApi
  //  * get stores data from API
  //  */
  // const fetchStoresApi = useCallback(async () => {
  //   try {
  //     // call api
  //     const includes = [
  //       'meta',
  //       'today_open_time_slots',
  //     ]
  //     const { stores: data } = await fetchStores({ includes })
  //     setStores(data)
  //   } catch (error) {
  //     handleError(error)
  //   // } finally {
  //   //   setStoresReady(true)
  //   }
  // }, [fetchStores])

  const onAddressSelectorClick = () => {
    if (!isLoggedIn) {
      const {
        page,
        search,
      } = location
      const redirectUrl = `${page}${search}`
      onRequestClose()
      navigate(
        '/login/',
        {
          state: {
            redirectUrl,
            // throw back selected order method when come back from login
            callbackState: { preSelectedOrderMethod: selectedItem, isBlocker },
          },
          replace: true,
        },
      )
    } else {
      setIsAddressManagerOpen(true)
    }
  }

  const onAddressManagerRequestClose = () => {
    setIsAddressManagerOpen(false)
  }

  const apiFetchDeliveryAddresses = async () => {
    const options = {
      params: {
        includes: [
          'user_address_info',
        ].join(','),
        // page,
      },
    }
    const { deliveryAddresses: data } = await fetchDeliveryAddresses(options)
    return data
  }

  const onFetchDeliveryAddresses = async () => {
    try {
      setDeliveryAddressesLoading(true)
      // TODO: set shipment delivery address id
      // when success, show info
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
      return data
    } catch (error) {
      handleError(error)
    } finally {
      setDeliveryAddressesLoading(false)
    }
  }

  const onSelectDeliveryAddress = (address) => {
    setDeliveryAddress(address)
    setUpdatedDevliveryAddress(address)
    onAddressManagerRequestClose()
  }

  const onUpdateDeliveryAddress = async (address) => {
    try {
      // handleSetLoading(true)
      // when success, show info
      await updateDeliveryAddresses({ address })
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
    } catch (error) {
      handleError(error)
    } finally {
      // handleSetLoading(false)
    }
  }

  /**
   * handleDeleteAddress
   * call api to delete a address
   * @param {*} address
   */
  async function handleDeleteAddress(address) {
    try {
      setStoresLoading(true)
      await deleteDeliveryAddresses({
        addressId: _.get(address, 'id'),
      })
      if (_.get(deliveryAddress, 'id', '--') === _.get(address, 'id', '-')) {
        setDeliveryAddress({})
      }
    } catch (error) {
      handleError(error)
    } finally {
      setStoresLoading(false)
    }
  }

  const handleUpdateOrderMethod = () => {
    // FL: skip update cart if changing order method to dineInMenu
    if (
      _.get(selectedOrderMethod, 'code') === 'dineInMenu'
      || _.get(selectableOrderMethod, 'commerceType' === 'dineIn')
    ) {
      // update store in localstorage (ensure only save store.id and store.code)
      setStore(_.pick(selectedStore, ['id', 'code']))
      changeUrlCommerceChannel(_.get(selectedOrderMethod, 'commerceChannel'))
      setOrderMethod(selectedOrderMethod)
      setUpdateOrderMethodInProgress(false)
      _.delay(handleUpdateOrderMethodSuccess, 100)
      return
    }
    setOrderMethod(selectedOrderMethod)
    setUpdateCartShipmentInProgress(true)
  }

  const proccessUpdateOrderMethod = useCallback(async () => {
    let success = true
    if (dirty && _.get(selectedOrderMethod, 'commerceType') !== 'dineIn') {
      success = await updateOrderMethod()
    }
    if (success) {
      handleUpdateOrderMethodSuccess()
    }
    // reset
    setUpdateOrderMethodInProgress(false)
    setUpdateCartShipmentInProgress(false)
    setChangeOrderMethodFromMenu(false)
  }, [
    dirty,
    handleUpdateOrderMethodSuccess,
    selectedOrderMethod,
    updateOrderMethod,
  ])

  const onChangeStoreConfirm = async () => {
    setIsChangeStoreDialogOpen(false)
    setIsChangeStoreConfirmed(true)
    handleUpdateOrderMethod()
    // setOrderMethod(selectedOrderMethod)
  }
  const onChangeStoreCancel = async () => {
    setIsChangeStoreDialogOpen(false)
    setIsChangeStoreConfirmed(false)
    setUpdateOrderMethodInProgress(false)
    setUpdateCartShipmentInProgress(false)
    // setOrderMethod(selectedOrderMethod)
  }
  const onChangeStoreDialogRequestClose = () => {
    setNeedUserConfirmToClearCartItems(false)
    setIsChangeStoreDialogOpen(false)
  }
  const onNoStoreDialogRequestClose = () => {
    setIsNoStoreDialogOpen(false)
    setUpdateOrderMethodInProgress(false)
  }

  const viewProps = {
    confirmButtonDisabled,
    deliveryAddress,
    deliveryAddresses,
    deliveryAddressesLoading,
    handleDeleteAddress,
    isAddressManagerOpen,
    isBlocker,
    isChangeStoreDialogOpen,
    isCommerceChannelNotMatch,
    isLoggedIn,
    isNoStoreDialogOpen,
    isOrderMethodDialogOpen,
    onAddressManagerRequestClose,
    onAddressSelectorClick,
    onChange,
    onChangeStoreCancel,
    onChangeStoreConfirm,
    onChangeStoreDialogRequestClose,
    onConfirm,
    onFetchDeliveryAddresses,
    onNoStoreDialogRequestClose,
    onRequestClose,
    onSelectDeliveryAddress,
    onStoreChange,
    onUpdateDeliveryAddress,
    orderMethod,
    orderMethodOptions,
    selectedItem,
    selectedStoreItem,
    showAddressSelector,
    showStoreSelector,
    storeOptions,
    storesLoading,
    themeConfig,
    updateOrderMethodInProgress,
  }

  return (
    <OrderMethodModalView {...viewProps} />
  )
}
