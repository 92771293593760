import _ from 'lodash'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'react-jss'
import {
  Autoplay,
  A11y,
  Navigation,
  Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Link from '../link'
import combineClassNames from '../../helpers/combineClassNames'
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import useStyles from './carousel-style'

const CarouselView = ({
  images,
  slidesToShowDesktop,
  slidesToShowMobile,
  paddingImg,
  paddingImgDesktop,
  marginCarousel,
  marginCarouselDesktop,
  carouselSettings,
  imageBoxClassName,
  hasArrows,
  className,
  autoplay,
  darkDots,
  // onClick: onClickTrackEvent,
  speed = 1000,
  autoplaySpeed = 4000,
}, ref = null) => {
  const styles = useStyles({
    paddingImg,
    paddingImgDesktop,
    marginCarousel,
    marginCarouselDesktop,
    darkDots,
  })
  const theme = useTheme()
  const mediaQueries = _.get(theme, 'mediaQueries', {})
  // const variableNameImage = breakpoints.sm ? 'ecomMedium' : 'ecomLarge'

  const settings = {
    modules: [Autoplay, A11y, Navigation, Pagination],
    pagination: {
      bulletClass: combineClassNames(['swiper-pagination-bullet', styles.paginationBullet]),
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3,
    },
    navigation: hasArrows === true,
    loop: true,
    speed,
    autoplay: autoplay && {
      delay: autoplaySpeed,
      disableOnInteraction: false,
    },
    slidesPerView: slidesToShowMobile,
    slidesPerGroup: slidesToShowMobile,
    breakpoints: {
      [mediaQueries.tablet]: {
        slidesPerView: slidesToShowDesktop,
        slidesPerGroup: slidesToShowDesktop,
      },
    },
    className: combineClassNames([styles.carousel, className]),
    ...carouselSettings,
  }

  return (
    <Swiper
      ref={ref}
      {...settings}
    >
      {
        images.map((item, index) => {
          const {
            id,
            attachment,
            url,
            // configOptions,
          } = item
          const versions = {
            ..._.get(attachment, 'versions', {}),
            ..._.get(item, 'versions', {}),
          }
          const renderImage = (
            <img
              src={_.get(versions, 'webMedium') || _.get(versions, 'ecomMedium')}
              srcSet={`${_.get(versions, 'webLarge') || _.get(versions, 'ecomLarge')} ${mediaQueries.tablet}w`}
              alt=""
              className={styles.image}
            />
          )
          return (
            <SwiperSlide
              className={combineClassNames([styles.boxImg, imageBoxClassName])}
              key={`carousel-image-${id || index}`}
            >
              {
                _.isEmpty(url)
                  ? renderImage
                  : (
                    <Link
                      // CP-20210429: temporary removal as this sometimes
                      //   affects the click-through destination
                      // gatsbyLink
                      // onClick={() => onClickTrackEvent(label)}
                      to={url}
                    >
                      {renderImage}
                    </Link>
                  )
              }
            </SwiperSlide>
          )
        })
      }
    </Swiper>
  )
}
CarouselView.propTypes = {
  images: PropTypes.array.isRequired,
  slidesToShowDesktop: PropTypes.number,
  slidesToShowMobile: PropTypes.number,
  paddingImg: PropTypes.string,
  paddingImgDesktop: PropTypes.string,
  marginCarousel: PropTypes.string,
  marginCarouselDesktop: PropTypes.string,
  postionDots: PropTypes.number,
  product: PropTypes.bool,
  hasArrows: PropTypes.bool,
  className: PropTypes.string,
}

CarouselView.defaultProps = {
  slidesToShowDesktop: 1,
  slidesToShowMobile: 1,
  paddingImg: '0',
  paddingImgDesktop: '0',
  marginCarousel: '0',
  marginCarouselDesktop: '0',
  postionDots: 60,
  product: false,
  hasArrows: false,
  className: '',
}

export default forwardRef(CarouselView)
