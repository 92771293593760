/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import {
  useSystemSettings,
} from 'react-omnitech-api'

export default function useAccount() {
  const { getSystemSetting } = useSystemSettings()

  // supported auth approaches:
  //  phone_password_v2
  //  phone_and_sms_token
  //  email
  //  email_and_email_token
  const supportedLoginApproaches = getSystemSetting('account.login.ecom.supported_codes')
  const supportedRegisterApproaches = getSystemSetting('account.register.ecom.supported_codes')

  const approachMapping = {
    phone_password_v2: {
      inputType: 'phone',
      verificationType: 'password',
    },
    phone_and_sms_token: {
      inputType: 'phone',
      verificationType: 'smsToken',
    },
    email: {
      inputType: 'email',
      verificationType: 'password',
    },
    email_and_email_token: {
      inputType: 'email',
      verificationType: 'emailToken',
    },
  }

  const getSupportedLoginApproaches = () => (
    _.map(supportedLoginApproaches, (code) => ({
      code,
      ..._.get(approachMapping, code, {}),
    }))
  )
  const getSupportedRegisterApproaches = () => (
    _.map(supportedRegisterApproaches, (code) => ({
      code,
      ..._.get(approachMapping, code, {}),
    }))
  )

  /**
   * getRegistrationApproach
   * determine registration approach base on form value
   */
  function getRegistrationApproach(values) {
    const registerApproaches = getSupportedRegisterApproaches()
    let approach
    if (!_.isEmpty(_.get(values, 'token'))) {
      approach = _.get(
        _.find(
          registerApproaches,
          { verificationType: 'smsToken' },
        ),
        'code',
      )
    }
    if (_.isEmpty(approach) && !_.isEmpty(_.get(values, 'emailToken'))) {
      approach = _.get(
        _.find(
          registerApproaches,
          { verificationType: 'emailToken' },
        ),
        'code',
      )
    }
    if (_.isEmpty(approach) && !_.isEmpty(_.get(values, 'password'))) {
      const approachForEmail = !_.isEmpty(_.get(values, 'email'))
        && _.find(
          registerApproaches,
          {
            verificationType: 'password',
            inputType: 'email',
          },
        )
      const approachForPhone = !_.isEmpty(_.get(values, 'phone'))
        && _.find(
          registerApproaches,
          {
            verificationType: 'password',
            inputType: 'phone',
          },
        )
      approach = _.get(
        (approachForEmail || approachForPhone),
        'code',
      )
    }
    return approach
  }

  return {
    getSupportedLoginApproaches,
    getSupportedRegisterApproaches,
    getRegistrationApproach,
  }
}
