import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  checkbox: {
    position: 'absolute',
  },
  checkboxLabel: {
    display: 'block',
    paddingLeft: '3.2rem !important',
  },
  dropdown: {
    width: '100%',
    position: 'relative',
    minHeight: '3.8rem',
  },
  dropdownDisabled: {
    opacity: 0.5,
  },
  label: {
    display: 'block',
    fontSize: '1.6rem',
    textAlign: 'left',
  },
  errorStyle: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '.8rem 0',
    marginBottom: 0,
  },
  select: {
  },
  labelRequired: {
    '&:after': {
      content: '"*"',
      color: theme.colors.alert,
      marginLeft: '0.3rem',
    },
  },
  loding: {
    fontSize: 12,
    color: theme.colors.text,
    padding: '0 15px',
    textTransform: 'none',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    dropdown: (props) => ({
      width: props.widthDropdown,
      position: 'relative',
    }),
  },
}))

export default useStyles
