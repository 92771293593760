/* eslint-disable react-hooks/exhaustive-deps */
/**
 * OrderMethodModalProvider
 */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import OrderMethodModalContext from './order-method-modal-context'

export default function OrderMethodModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [props, setProps] = useState({})

  const open = (obj = {}) => {
    setProps(() => _.pick(obj, ['onContinue', 'mode']))
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isOpen && !_.isEmpty(props)) {
      setProps({})
    }
  }, [isOpen, props])

  const state = {
    isOpen,
    onRequestClose: close,
    open,
    close,
    ...props,
  }

  return (
    <OrderMethodModalContext.Provider value={state}>
      {children}
    </OrderMethodModalContext.Provider>
  )
}
