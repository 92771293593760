/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'

function TokenButtonView(props) {
  const {
    disabled,
    countDown,
    countDownInProgress,
    type,
    ...rest
  } = props
  const { t } = useTranslation()

  const labelTranslationKey = type === 'phone' ? 'ui.smsTokenButton.label' : 'ui.emailTokenButton.label'

  return (
    <Button
      disabled={countDownInProgress || disabled}
      text={t(labelTranslationKey, {
        count: countDown,
        context: countDown <= 0 ? '0' : '1', // FL: added context to i18n key since i18next's Plural function doesn't work.
      })}
      {...rest}
    />
  )
}

export default TokenButtonView
