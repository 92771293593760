import * as Yup from 'yup'
import i18next from 'i18next'

/**
 * getLoginFormInitialValues
 * base on login approach to generate a initial value for registration form
 *
 * Parameters for the `email` approach:
 * `email`    Email used for later session login.
 * `password` Password used for later session login.
 *
 * Parameters for the `phone_password_v2` approach:
 * `phone`    Phone number used for later session login.
 * `password` Password used for later session login.
 * `token`    The SMS token obtained from calling the `sms_tokens` endpoint
 *            using the same device udid.
 *
 * Parameters for the `phone_and_sms_token` approach:
 * `phone`    Phone number used for later session login.
 * `token`    The SMS token obtained from calling the `sms_tokens` endpoint
 *            using the same device udid.
 *
 * @param approach
 */

export default function transformLoginFormValidationSchema({ approach }) {
  const yupShapeObj = {}

  switch (approach) {
    case 'phone_password_v2':
      yupShapeObj.phone = Yup.string().required(i18next.t('screens.login.errors.phone', { context: 'required' }))
      yupShapeObj.password = Yup.string().required(i18next.t('screens.login.errors.password', { context: 'required' }))
      // yupShapeObj.token = Yup.string().required(
      // i18next.t('slogin.errors.token', { context: 'required' }))
      break
    // does not use `phone_udid_v2` in ecom, only use in consumer app
    // case 'phone_udid_v2':
    //   break
    case 'phone_and_sms_token':
      yupShapeObj.phone = Yup.string().required(i18next.t('screens.login.errors.phone', { context: 'required' }))
      yupShapeObj.token = Yup.string().required(i18next.t('screens.login.errors.token', { context: 'required' }))
      break
    case 'email_and_email_token':
      yupShapeObj.email = Yup.string().required(i18next.t('screens.login.errors.email', { context: 'required' }))
      yupShapeObj.token = Yup.string().required(i18next.t('screens.login.errors.token', { context: 'required' }))
      break
    case 'email':
    default:
      yupShapeObj.email = Yup.string().required(i18next.t('screens.login.errors.email', { context: 'required' }))
      yupShapeObj.password = Yup.string().required(i18next.t('screens.login.errors.password', { context: 'required' }))
      break
  }

  return Yup.object().shape(yupShapeObj)
}
