/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useCoupons,
  useCouponTokens,
  useCouponUsages,
  useUser,
  useSystemSettings,
} from 'react-omnitech-api'
import { useAlert } from '../use-alert'
import CouponMarketplaceContext from './coupon-marketplace-context'

/**
 * CouponMarketplaceProvider
 * Contain most logic handing coupon marketplace logic
 */
export default function CouponMarketplaceProvider({
  children,
}) {
  // prepare hook
  const alert = useAlert()
  const { t } = useTranslation()
  const {
    fetchCoupons,
    fetchCoupon,
  } = useCoupons()
  const {
    createCouponToken,
    createCouponTokenByTokenTransfer,
    createCouponTokenTransfer,
    fetchCouponToken,
    fetchCouponTokens,
  } = useCouponTokens()
  const {
    createCouponUsage,
    fetchCouponUsage,
  } = useCouponUsages()
  const { getSystemSetting } = useSystemSettings()
  const {
    fetchUser,
    user,
    loyaltyInformation,
  } = useUser()

  const defaultModalContent = {
    coupon: {},
    title: '',
    message: '',
    textCancelButton: '',
    textConfirmButton: '',
  }

  // internal state
  const [hasMoreCoupons, setHasMoreCoupons] = useState(false)
  const [hasMoreCouponTokens, setHasMoreCouponTokens] = useState(false)
  const [isCouponMarketplaceOpen, setIsCouponMarketplaceOpen] = useState(false)
  const [isCouponRedeemed, setIsCouponRedeemed] = useState(false)
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [isDetailReady, setIsDetailReady] = useState(false)
  const [isCouponsReady, setIsCouponsReady] = useState(false)
  const [isCouponTokensReady, setIsCouponTokensReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [couponTokens, setCouponTokens] = useState([])
  const [coupon, setCoupon] = useState({})
  const [couponToken, setCouponToken] = useState({})
  const [couponUsage, setCouponUsage] = useState({})
  const [transferInParams, setTransferInParams] = useState({})
  const [detailType, setDetailType] = useState('coupon')
  const [modalContent, setModalContent] = useState(defaultModalContent)
  const [fetchNextCoupons, setFetchNextCoupons] = useState(null)
  const [fetchNextCouponTokens, setFetchNextCouponTokens] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState()
  const [popupMessage, setPopupMessage] = useState('')
  const [secretQrCodeValue, setSecretQrCodeValue] = useState(null)
  const [transferLoading, setTransferLoading] = useState(false)
  const [showQrCodeMask, setShowQrCodeMask] = useState(false);

  // local variables
  const isCouponDetailOpen = isDetailOpen && _.includes(['coupon', 'couponTransferIn'], detailType)
  const isCouponTokenDetailOpen = isDetailOpen && _.includes(['couponToken', 'couponUsage'], detailType)

  // useMemo
  const userCurrentPoints = useMemo(() => (
    _.get(loyaltyInformation, 'totalLoyaltyPoints', 0)
  ), [loyaltyInformation])
  const couponRequireSecret = useMemo(() => (
    _.get(couponToken, 'coupon.requireSecret', false)
  ), [couponToken])
  const canSelfUse = useMemo(() => (
    _.includes(_.get(couponToken, 'coupon.couponUsageApiChannels', []), 'ecom')
  ), [couponToken])
  const isTransferable = useMemo(() => (
    !_.isEmpty(
      _.intersection(
        _.get(couponToken, 'canActions', []),
        ['transfer_out', 'transfer_in', 'transfer_undo'],
      ),
    )
  ), [couponToken])
  const couponTokenStatus = useMemo(() => _.get(couponToken, 'status'), [couponToken])
  const couponTokenTransferState = useMemo(() => _.get(couponToken, 'transferState'), [couponToken])
  const createCouponTokenTransferParams = useMemo(() => ({
    id: _.get(couponToken, 'id'),
    includes: [
      'can_actions',
      'coupons.cart_discount',
      // 'coupons.images',
      'coupon',
    ].join(','),
  }), [couponToken])
  // Possible token display mode from api:
  // 'no_show',
  // 'token', # Default token display mode
  // 'token_after_usage', # Display token after coupon uasge.
  //   E.g.Steakking diplay token to Shopify after consumer app usage
  // 'token_after_transfer_completed', # Display token after transfer completed.
  //   E.g.Taipan mooncake coupon display token for our mPoS after transfer complete.
  // 'secret_after_usage' # Display secret after token usage.
  //   E.g.Taipan welcome that display token secret to Taipan BOSS after consumer app usage
  const tokenDisplayMode = useMemo(() => (
    _.get(couponToken, 'coupon.tokenDisplayMode', 'token')
  ), [couponToken])
  const showQrCode = useMemo(() => !(
    !canSelfUse
    && couponRequireSecret
  ), [canSelfUse, couponRequireSecret])
  const showSecretQrCode = useMemo(() => !(
    canSelfUse
    && !_.isEmpty(secretQrCodeValue)
  ), [canSelfUse, secretQrCodeValue])
  // function
  function handleCouponMarketplaceOpen() {
    setIsCouponMarketplaceOpen(true)
  }

  function handleCouponMarketplaceClose() {
    setCoupon({})
    setCoupons([])
    setCouponToken({})
    setCouponUsage({})
    setCouponTokens([])
    setIsCouponRedeemed(false)
    setFetchNextCoupons(null)
    setFetchNextCouponTokens(null)
    setHasMoreCoupons(false)
    setHasMoreCouponTokens(false)
    setDetailType('')
    setIsDetailOpen(false)
    setIsDetailReady(false)
    setIsModalOpen(false)
    setIsCouponMarketplaceOpen(false)
    setIsCouponsReady(false)
    setIsCouponTokensReady(false)
    setSecretQrCodeValue(null)
    handlePopupClose()
  }

  function handleCouponDetailClose() {
    setCoupon({})
    setCouponToken({})
    setCouponUsage({})
    setIsCouponRedeemed(false)
    setIsDetailOpen(false)
    setIsDetailReady(false)
    setSecretQrCodeValue(null)
    handlePopupClose()
  }

  async function handleCouponDetailOpen({
    id,
    token,
    transferToken,
    type = 'coupon',
  }) {
    setIsDetailOpen(true)
    setDetailType(type)
    try {
      if (type === 'coupon') {
        const { coupon: data } = await fetchCoupon({
          id,
        })
        setCoupon(data)
      } else if (type === 'couponTransferIn') {
        const { coupon: data } = await fetchCoupon({
          id,
        })
        setCoupon(data)
        if (
          !_.isEmpty(token)
          && !_.isEmpty(transferToken)
        ) {
          setTransferInParams({
            token,
            transferToken,
            actionType: 'transfer_in',
          })
        }
      } else if (type === 'couponUsage') {
        const { couponUsage: data } = await fetchCouponUsage({
          id,
          includes: [
            'coupon_token',
            'coupon_tokens.coupon',
            'coupons.cart_discount',
          ].join(','),
        })
        setCouponUsage(_.omit(data, ['couponToken']))
        setSecretQrCodeValue(_.get(data, 'couponTokenSecret'))
        setCouponToken(() => _.get(data, 'couponToken'))
      } else {
        const { couponToken: data } = await fetchCouponToken({
          id,
          includes: [
            'can_actions',
            'coupon',
            'coupons.cart_discount',
          ].join(','),
        })

        setCouponToken(data)
      }
      setIsDetailReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }

  async function handleFetchCoupons(fetchNextPage) {
    try {
      const options = {
        sortBy: 'end_at',
        pageSize: 5,
      }
      const { coupons: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchCoupons(options)

      // update state
      setCoupons((prevState) => prevState.concat(data))
      setHasMoreCoupons(_.isFunction(next))
      setFetchNextCoupons(() => next)
      if (_.isEmpty(fetchNextPage)) {
        setIsCouponsReady(true)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }

  function handleFetchCouponsNextPage() {
    if (!_.isFunction(fetchNextCoupons)) return

    handleFetchCoupons(fetchNextCoupons)
  }

  async function handleFetchCouponTokens(fetchNextPage) {
    try {
      // api.v2.coupon_tokens.index.my_coupons.ecom.query
      const apiQuery = getSystemSetting('api.v2.coupon_tokens.index.my_coupons.ecom.query', {})
      const options = {
        sortBy: 'expires_at',
        includes: [
          'can_actions',
          'coupon',
        ].join(','),
        ...apiQuery,
      }
      const { couponTokens: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchCouponTokens(options)

      // update state
      setCouponTokens((prevState) => prevState.concat(data))
      setHasMoreCouponTokens(_.isFunction(next))
      setFetchNextCouponTokens(() => next)
      // after fetching first page, need to update ready
      if (_.isEmpty(fetchNextPage)) {
        setIsCouponTokensReady(true)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }

  function handleFetchCouponTokensNextPage() {
    if (!_.isFunction(fetchNextCouponTokens)) return

    handleFetchCouponTokens(fetchNextCouponTokens)
  }

  function handleApplyCoupon() {
    setIsLoading(true)
  }

  function handleApplyCouponError(error) {
    const generalError = _.get(error, 'generalError', {})
    const batchActionError = _.get(error, 'batchActionErrors[0]') || {}
    // if batch action is not provided, use the general error message
    const errorMessage = _.isEmpty(batchActionError.message)
      ? generalError.message
      : handleApplyCouponErrorMessage(batchActionError)
    setIsLoading(false)
    handleModalOpen({
      title: t('ui.minicartCoupons.sorry'),
      message: errorMessage,
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.dismiss'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  function handleApplyCouponErrorMessage(error = {}) {
    switch (error.code) {
      case 20409: {
        const applyLImitPerOrder = _.get(couponToken, 'coupon.applyLimitPerOrder', '')
        return applyLImitPerOrder === ''
          ? error.message
          : t(`omnitechError.${error.code}`, { count: applyLImitPerOrder })
      }
      default:
        return error.message
    }
  }

  function handleApplyCouponSuccess() {
    setIsLoading(false)
    handleModalOpen({
      title: t('ui.minicartCoupons.couponApplied'),
      message: t('ui.minicartCoupons.couponHasBeenApplied'),
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.dismiss'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  function handleRedeemCoupon(couponData) {
    handleModalOpen({
      title: t('ui.minicartCoupons.modal.redeemCoupon'),
      message: t(
        'ui.minicartCoupons.modal.areYouSureRedeemCoupon',
        { points: couponData.redeemableRequiredLoyaltyPoints },
      ),
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.confirm'),
          onClick: () => {
            handleRedeemCouponConfirm(couponData)
          },
        },
        {
          type: 'cancel',
          text: t('ui.minicartCoupons.modal.buttons.cancel'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  async function handleRedeemCouponConfirm(couponData) {
    try {
      setIsLoading(true)
      setIsModalOpen(false)

      await createCouponToken({
        couponID: couponData.id,
        data: {
          coupon_token: {
            userId: user.id,
          },
        },
      })

      handleRedeemCouponSuccess()
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      // alert.show(generalError.message)
      handleRedeemCouponError(generalError.message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleRedeemCouponSuccess() {
    handlePopupOpen(t('ui.minicartCoupons.redeemSuccess'))
    try {
      // refresh user asynchronous, to show latest loyalty point
      fetchUser()

      // if redeemableTimesPerUser > 1
      //    - refresh coupon to check redeemableAvailableForUserRedeem
      //    - if redeemableAvailableForUserRedeem is false, show redeemed
      // else
      //    - show redeemed directly
      setIsCouponRedeemed(true)
      if (coupon.redeemableTimesPerUser > 1) {
        const { coupon: data } = await fetchCoupon({
          id: coupon.id,
        })
        if (data.redeemableAvailableForUserRedeem) {
          setIsCouponRedeemed(false)
        }
        setCoupon(data)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    } finally {
      setIsPopupOpen(true)
      handleFetchCouponTokens()
      setCouponTokens([])
      setIsCouponTokensReady(false)
    }
  }

  function handleRedeemCouponError(errorMessage) {
    handleModalOpen({
      title: t('ui.minicartCoupons.sorry'),
      message: errorMessage,
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.dismiss'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  function handleModalClose() {
    setIsModalOpen(false)
  }

  function handleModalOpen({
    title = '',
    message = '',
    buttons = [],
    ...others
  }) {
    setIsModalOpen(true)
    setModalContent({
      title,
      message,
      buttons,
      ...others,
    })
  }

  function handlePopupOpen(message) {
    setIsPopupOpen(true)
    setPopupMessage(message)
  }

  function handlePopupClose() {
    setIsPopupOpen(false)
    setPopupMessage('')
  }

  async function handleTransferIn() {
    try {
      setTransferLoading(true)
      const data = await createCouponTokenByTokenTransfer({
        token: _.get(transferInParams, 'token'),
        data: {
          actionType: _.get(transferInParams, 'actionType'),
          couponToken: {
            transferToken: _.get(transferInParams, 'transferToken'),
          },
        },
        includes: [
          'can_actions',
          'coupons.cart_discount',
          // 'coupons.images',
          'coupon',
        ],
      })
      setCouponToken(_.get(data, 'couponToken'))
      await handleFetchCouponTokens() // refresh coupon token list
      return _.get(data, 'couponToken')
    } catch (error) {
      if (_.get(error, 'generalError.code') === 404) {
        // alert.show('404')
        // alert.show(errorMessage)
        handleModalOpen({
          title: t('ui.minicartCoupons.modalDialog.transferInNotFound.title'),
          message: t('ui.minicartCoupons.modalDialog.transferInNotFound.text'),
          buttons: [
            {
              type: 'confirm',
              text: t('ui.minicartCoupons.modalDialog.transferInNotFound.buttons.dismiss'),
              onClick: () => {
                handleModalClose()
                handleCouponMarketplaceClose()
              },
            },
          ],
        })
      }
    } finally {
      setTransferLoading(false)
    }
  }

  async function handleTransferOut() {
    setTransferLoading(true)
    return createCouponTokenTransfer({
      data: {
        actionType: 'transfer_out',
        couponToken: {},
      },
      ...createCouponTokenTransferParams,
    })
      .then((data) => {
        setCouponToken(_.get(data, 'couponToken'))
        return _.get(data, 'couponToken')
      })
      .finally(() => {
        setTransferLoading(false)
      })
  }

  async function handleTransferUndo() {
    try {
      setTransferLoading(true)
      const data = await createCouponTokenTransfer({
        data: {
          actionType: 'transfer_undo',
          couponToken: {},
        },
        ...createCouponTokenTransferParams,
      })
      setCouponToken(_.get(data, 'couponToken'))
    } catch (error) {
      console.log('//// error ....', error)
    } finally {
      setTransferLoading(false)
    }
  }

  async function handleRequireCouponSecret() {
    try {
      if (isTransferable && couponTokenTransferState === 'transferable') {
        // Lock the coupon before create coupon usages if it's a transferable coupon
        const data = await createCouponTokenTransfer({
          data: {
            actionType: 'complete_transfer',
            couponToken: {},
          },
          ...createCouponTokenTransferParams,
        })
        setCouponToken(_.get(data, 'couponToken'))
      }
      if (canSelfUse) {
        const { couponUsage: data } = await createCouponUsage({ couponTokenQrCode: _.get(couponToken, 'qrCodeValue') })
        setSecretQrCodeValue(_.get(data, 'couponTokenSecret'))
      } else {
        setShowQrCodeMask(false)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }

  /**
   * when coupon marketplace is opened, fetch coupon and coupon tokens
   */
  useEffect(() => {
    if (isCouponMarketplaceOpen) {
      handleFetchCoupons()
      handleFetchCouponTokens()
    }

    return function cleanUp() {
      cancelRequest.cancelAll([
        'fetchCoupons',
      ])
    }
  }, [isCouponMarketplaceOpen])

  /**
   * Open for coupon token detail only page
   */
  useEffect(() => {
    if (isDetailOpen) {
      setIsCouponMarketplaceOpen(true)
    }
  }, [isDetailOpen])

  useEffect(() => {
    setShowQrCodeMask(
      // canSelfUse
      _.includes(['token_after_usage', 'token_after_transfer_completed', 'secret_after_usage'], tokenDisplayMode)
      && _.isEmpty(secretQrCodeValue)
      && !(isTransferable && couponTokenTransferState === 'completed')
      && couponTokenStatus !== 'used',
    )
  }, [
    // canSelfUse,
    couponRequireSecret,
    couponTokenStatus,
    secretQrCodeValue,
    tokenDisplayMode,
  ])

  const state = {
    hasMoreCoupons,
    hasMoreCouponTokens,
    isCouponMarketplaceOpen,
    isCouponsReady,
    isCouponRedeemed,
    isCouponTokensReady,
    isCouponDetailOpen,
    isCouponTokenDetailOpen,
    isDetailOpen,
    isDetailReady,
    isLoading,
    isModalOpen,
    isPopupOpen,
    showQrCodeMask,
    showQrCode,
    showSecretQrCode,
    secretQrCodeValue,
    canSelfUse,
    coupon,
    coupons,
    couponToken,
    couponTokens,
    couponUsage,
    detailType,
    modalContent,
    popupMessage,
    userCurrentPoints,
    transferLoading,
    onCouponMarketplaceOpen: handleCouponMarketplaceOpen,
    onCouponMarketplaceClose: handleCouponMarketplaceClose,
    onCouponDetailClose: handleCouponDetailClose,
    onCouponDetailOpen: handleCouponDetailOpen,
    onApplyCoupon: handleApplyCoupon,
    onApplyCouponError: handleApplyCouponError,
    onApplyCouponSuccess: handleApplyCouponSuccess,
    onPopupOpen: handlePopupOpen,
    onPopupClose: handlePopupClose,
    onRedeemCoupon: handleRedeemCoupon,
    onRedeemCouponConfirm: handleRedeemCouponConfirm,
    onRedeemCouponSuccess: handleRedeemCouponSuccess,
    onRedeemCouponError: handleRedeemCouponError,
    onModalClose: handleModalClose,
    onModalOpen: handleModalOpen,
    onFetchCoupons: handleFetchCoupons,
    onFetchCouponTokens: handleFetchCouponTokens,
    onFetchCouponsNextPage: handleFetchCouponsNextPage,
    onFetchCouponTokensNextPage: handleFetchCouponTokensNextPage,
    onRequireCouponSecret: handleRequireCouponSecret,
    onTransferIn: handleTransferIn,
    onTransferOut: handleTransferOut,
    onTransferUndo: handleTransferUndo,
  }

  return (
    <CouponMarketplaceContext.Provider value={state}>
      {children}
    </CouponMarketplaceContext.Provider>
  )
}
